@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body{
    font-size: 14px;
}
body h1,h2,h3,h4,h5,h6,p,ul,li,a{
    margin: 0;
    padding: 0;
    font-family: 'Lato', sans-serif;
    font-family: 'Poppins', sans-serif;
}
body a{
    text-decoration: none;
}
body ul li{
    list-style: none;
}
body button{
    border: none;
    outline: none;
    background: transparent;
}
body button:focus{
    box-shadow: none;
}
body .form-control:focus,body .form-select:focus{
    box-shadow: none;
}
body .form-control.is-invalid{
    margin-bottom: 0;
}


ul.pagination {
    display: flex;
    padding: 0;
    margin: 0;
    justify-content: center;
}
ul.pagination li a {
    font-size: 14px;
  position: relative;
  padding: 0.375rem 0.75rem;
  display: block;
  color: #0d6efd;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

ul.pagination li.active .undefined {
    background-color: #439fa8;
    color: white;
    border-radius: 0px;
    border-color: transparent;
}

ul.pagination li:first-child a{
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}
ul.pagination li:last-child a{
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
ul.pagination li a:hover:not(.active) 
{
    background-color: #ddd;
    color: #0d6efd;
}

::-webkit-scrollbar {
    width: 2px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #449fa8; 
    border-radius: 10px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #449fa8; 
    border-radius: 10px;
  }
