.job_page2{
    background: #fff;
    padding: 100px 0 25px;
    min-height: 100%;

    .arrow_icon{
        position: relative;
        a{
            position: absolute;
            color: #000;
            left: 0;
            top: 0;
        }
    }
    .mainBox{
        padding: 25px;
        border-radius: 15px;
        position: relative;
        border: 1px solid #d6edf4;

        .date_title{
            text-align: right;
            p{
                font-size: 14px;
                color: #000;
                margin: 0;
            }
        }
        h4{
            color: #000000;
            font-weight: 600;
            font-size: 20px;
            margin: 0;
        }

        .starDate{
            p{
                display: flex;
                align-items: center;
                font-size: 16px;
                svg{
                    margin-right: 5px;
                }
                span{
                    display: inline-block;
                    font-weight: 600;
                    margin-right: 5px;
                }
            }
            .star{
                margin: 0 0 10px;
                svg{
                    fill: #ffd40c;
                    margin: 0;
                }
            }
        }

        .desc{
            display: flex;
            .flexBox{
                p{
                    font-size: 15px;
                    span{
                        display: inline-block;
                        font-weight: 600;
                        color: #000;
                    }
                }
                &.spaceRight{
                    margin-left: 70px;
                }
            }
        }

        p{
            font-size: 15px;
            color: #020202;
            font-weight: 400;
            margin: 10px 0;
        }

        .lBox{
            padding-right: 35px;
            h5{
                color: #000000;
                font-weight: 600;
                font-size: 17px;
                margin: 10px 0;
            }
            .adderess{
                display: flex;
                align-items: center;
                color: #4a4a4a;
                font-weight: 400;
                img{
                    width: 24px;
                    margin-right: 5px;
                }
            }
            
        }

        .imgBtn{
            text-align: left;
            margin-top: 50px;
            a{
                display: inline-block;
                text-align: center;
                padding: 10px 10px;
                border-radius: 10px;
                background: #000;
                border: 1px solid #000000;
                max-width: 200px;
                width: 100%;
                color: #fff;
                font-weight: 500;
                font-size: 15px;
                &.cancel_btn{
                    background: #fff;
                    color: #000;
                    margin-right: 10px;
                }
            }
        }

        .rBox{
            .imgBox{
                border-radius: 6px;
                border: 1px solid #668189;
                overflow: hidden;
            }
        }
    }
}

@media (max-width: 991px){
    .job_page2 {
        .mainBox {
            .rBox {
                .imgBtn{
                    margin-top: 25px;
                    a{
                        max-width: none;
                        width: 100%;
                        &.detailBtn{
                            margin-left: 0;
                            margin-top: 10px;
                        }
                    }
                }
            }
            .desc{
                .flexBox{
                    &.spaceRight{
                        margin-left: 20px;
                    }
                }
                .adderess{
                    img{
                        margin-right: 10px;
                    }
                }
            }
        }
    }
}

@media (max-width: 576px){
    .job_page2{
        .mainBox{
            padding: 15px 10px;
            .lBox {
                padding-right: 0;
                .starDate{
                    p{
                        margin: 0;
                    }
                }
                .adderess{
                    img{
                        margin-right: 10px;
                    }
                }
            }
            .rBox{
                .imgBox{
                    margin-bottom: 15px;
                }
            }
            .imgBtn{
                margin-top: 15px;
                text-align: center;
                a{
                    max-width: 132px;
                    padding: 7px 10px;
                }
            }
            h4{
                text-align: center;
            }
            .date_title{
                margin-top: 5px;
                text-align: center;
            }
            .starDate {
                .star{
                    margin: 0;
                }
            }
        }
        .arrow_icon {
            a{
                top: 15px;
                left: 8px;
            }
        }
    }
    .cancelDetails {
        .modal-dialog {
            .modal-content{
                .modal-body{
                    padding: .5rem 0;
                }
            }
        }
    }
}

@media (max-width: 375px){
    .job_page2{
        .mainBox{
            .desc {
                .flexBox {
                    p {
                        margin: 5px 0;
                        font-size: 12px;
                    }
                    &.spaceRight{
                        margin-left: 10px;
                    }
                }
            }
        }
    }  
}

.cancelDetails{
    .modal-dialog{
        max-width: 400px;
        .modal-content{
            padding: 25px 15px;
            border-radius: 15px;
            .modal-header{
                padding: 0 !important;
                position: relative;
                justify-content: center;
                padding: 0 0 15px;
                border-bottom: none;
                background: #fff !important;
                .btn-close{
                    position: absolute;
                    top: -8px;
                    right: 5px;
                    color: #000;
                    font-weight: 900;
                    padding: 0;
                    background: none;
                    line-height: 0;
                    &:focus{
                        box-shadow: none;
                    }
                }
                .modal-title{
                    font-size: 16px;
                    font-weight: 600;
                    color: #020202;
                }
            }
            .modal-body{
                .form-group{
                    .form-select{
                        border-radius: 10px;
                        padding: 10px 15px;
                        color: #000;
                        font-weight: 600;
                        border-color: #000000;
                        font-size: 15px;
                        option{
                            color: #000;
                            font-weight: 600;
                        }
                    }
                    .textArea{
                        border-radius: 10px;
                        margin-top: 15px;
                        border-color: #000000;
                    }
                }
            }
            .modal-footer{
                justify-content: center;
                border-top: none;
                padding: 0 !important;
                .submitBtn{
                    border-radius: 10px;
                    background: #000;
                    color: #fff;
                    padding: 5px 15px;
                    max-width: 175px;
                    width: 100%;
                    font-size: 15px;
                }
            }
        }
    }
}