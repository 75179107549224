.withdrawBox {
  padding: 100px 0 25px;
  background: #fff;
  min-height: 100%;
  display: flex;
  justify-content: center;

  .content {
    border-radius: 15px;
    .withdrawInner {
      border-bottom: 1px solid #d6edf4;
      padding-bottom: 25px;
      margin-top: 15px;
      .title{
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        h5{
          font-size: 16px;
          font-weight: 600;
          color: #000;
          span{
            display: inline-block;
            color: #54b0b0;
          }
        }
        span{
          margin: 0 10px;
          display: inline-block;
        }
      }
      .with_amt_btn{
        text-align: right;
        margin-bottom: 20px;
        a{
          display: inline-block;
          border: 1px solid #000;
          border-radius: 6px;
          padding: 5px 15px;
          font-size: 13px;
          background: #fff;
          color: #000;
          font-weight: 600;
        }
      }
      .submit_btn{
        text-align: center;
        a{
          display: block;
          margin-top: 8px;
          border: 1px solid #000;
          border-radius: 6px;
          padding: 8px 15px;
          font-size: 13px;
          background: #000;
          color: #fff;
          font-weight: 500;
        }
      }
    }

    .form-group {
      position: relative;
      margin: 8px 0;
      
      .form-control,
      .form-select {
        border-radius: 10px;
        background: #f7f8fc;
        border-color: #ddecf0;
        border-width: 2px;
        color: #292a2e;
        // padding-right: 35px;
        padding-right: 30px;
        min-height: 40px;
        &:focus {
          border-color: #919191;
        }
      }
      span {
        position: absolute;
        top: 40%;
        right: 15px;
        transform: translateY(-40%);
        color: #676767;
      }
    }
    .transaction_box{
      padding-top: 10px;
      .border-1{
        margin-top: 15px;
        border: 1px solid #d6edf4;
        border-radius: 8px;
        .table{
          text-align: left;
          margin: 0;
          thead{
            tr{
              border-width: 0;
            }
            th{
              font-size: 14px;
              font-weight: 700;
            }
          }
          tbody{
            border: 1px solid #d6edf4;
            td{
              font-size: 14px;
              font-weight: 500;
            }
            .instant_text{
              color: #f63643;
            }
            .recive_text{
              color: #74b0bd;
            }
          }
          a{
            vertical-align: -webkit-baseline-middle;
            color: #000;
          }
        }
        .attach_docs{
          display: flex;
          img{
            width: 50px;
            height: 35px;
            min-width: 50px;
            border: 1px solid #d6edf4;
            border-radius: 6px;
          }
          span{
            display: inline-block;
            width: 100%;
            margin-left: 5px;
            color: #66bbc0;
            font-size: 14px;
            font-weight: 600;
          }
        }
        .action_btn{
          display: flex;
          justify-content: space-between;
          align-items: center;
          .del_btn{
            display: inline-block;
            color: #ff0907;
            font-size: 12px;
          }
          .edit_btn{
            color: #5a5a5a;
            display: inline-block;
            font-size: 12px;
          }
        }
      }
    }
  }
}

.withdrawModal {
  .modal-dialog {
    max-width: 400px;
    .modal-content {
      padding: 25px 15px;
      border-radius: 15px;
      .modal-header { 
        background: #fff !important;
        margin-bottom: 10px;
        position: relative;
        justify-content: center;
        padding: 0 !important;
        border-bottom: none;
        .btn-close {
          position: absolute;
          top: -8px;
          right: 5px;
          color: #000;
          font-size: 14px;
          font-weight: 900;
          padding: 0;
          &:focus {
            box-shadow: none;
          }
        }
        .modal-title {
          font-size: 14px;
          font-weight: 600;
          color: #020202;
          text-align: center;
        }
      }
      .modal-body {
        padding: 0;
      }
      .modal-footer {
        justify-content: center;
        border-top: none;
        .submitBtn {
          border-radius: 10px;
          background: #000;
          color: #fff;
          padding: 7px 15px;
          max-width: 150px;
          width: 100%;
          font-size: 15px;
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .withdrawBox {
    .content {
      padding: 0px;
      .tabBox {
        .nav {
          .nav-item {
            display: block;
            width: 100%;
            .nav-link {
              width: 100%;
              margin: 7px 0;
            }
          }
        }
        .tab-content {
          .tab-pane {
            .jobBox {
              .para {
                .numBox {
                  p {
                    justify-content: flex-start;
                  }
                }
              }
            }
          }
        }
      }
    .withdrawInner {
      .title{
        text-align: center;
      }
      .with_amt_btn {
        a{
          max-width: none;
          text-align: center;
          width: 100%;
        }
      }
    }
    }
  }
  .withdrawModal{
    .modal-dialog {
      .modal-content {
        padding: 15px 4px;
        .modal-footer{
          padding: 0 !important;
        }
      }
    }
  }
}
