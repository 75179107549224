.myDoc {
  padding: 100px 0 25px;
  background: #fff;
  min-height: 100%;

  .myDocBox {
    border-radius: 20px;
    padding: 0px 15px;

    .topBox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;

      h4 {
        font-weight: 600;
        font-size: 18px;
      }

      .editDetail{
        .addBtn{
          background: #000;
          color: #fff;
          border-radius: 10px;
          font-weight: 400;
          font-size: 13px;
          margin-right: 3px;
          max-width: 150px;
          display: inline-block;
          text-align: center;
          padding-right: 20px;
        }
      }
    }

    .uploded_docs{
      margin-top: 25px;
      padding-top: 15px;
      border-top: 1px solid #d6edf4;
      h5{
        font-size: 17px;
        font-weight: 600;
      }
      .border-1{
        margin-top: 15px;
        border: 1px solid #d6edf4;
        border-radius: 8px;
        .table{
          text-align: left;
          margin: 0;
          thead{
            tr{
              border-width: 0;
            }
            th{
              font-size: 14px;
              font-weight: 600;
            }
          }
          tbody{
            border: 1px solid #d6edf4;
            td{
              font-size: 15px;
            }
          }
          a{
            vertical-align: -webkit-baseline-middle;
            color: #000;
          }
        }
        .attach_docs{
          display: flex;
          img{
            width: 50px;
            height: 35px;
            min-width: 50px;
            border: 1px solid #d6edf4;
            border-radius: 6px;
          }
          span{
            display: inline-block;
            width: 100%;
            margin-left: 5px;
            color: #66bbc0;
            font-size: 14px;
            font-weight: 600;
          }
        }
        .action_btn{
          // display: flex;
          justify-content: space-between;
          align-items: center;
          .del_btn{
            display: inline-block;
            color: #ff0907;
            font-size: 15px;
            margin-right: 10px;
          }
          .edit_btn{
            color: #5a5a5a;
            display: inline-block;
            font-size: 15px;
          }
        }
      }
    }

    .form-group {
      position: relative;
      margin: 8px 0;
      .form-control,.form-select {
        background-color: #f7f8fc;
        color: #a0a7b9;
        border-radius: 10px;
        border-color: #c2d4da;
        padding-right: 35px;
        min-height: 40px;
        &:focus {
          border-color: #919191;
        }
        &::placeholder{
          color: #a0a7b9;
        }
      }
      span {
        position: absolute;
        top: 40%;
        right: 15px;
        transform: translateY(-40%);
        color: #676767;
      }
    }

    .addDocs_btn{
      text-align: right;
      margin-top: 8px;
      a {
        display: inline-block;
        font-size: 14px;
        font-weight: 600;
        color: #fff;
        max-width: 185px;
        width: 100%;
        text-align: center;
        padding: 10px 15px;
        background: #000;
        border-radius: 10px;
      }
    }
  }
}


@media (max-width: 991px){
  .myDoc {
    .myDocBox {
      padding: 0;
      .flexBox {
        .left {
          h5{
            font-size: 18px;
          }
          p{
            font-size: 15px;
          }
          button{
            font-size: 15px;
          }
        }
      }
    }
  }
}

@media (max-width: 576px){
  .myDoc {
    .myDocBox{
      padding: 0;
      .topBox{
        justify-content: space-between;
      button{
        font-size: 14px;
      }
       h4{
        font-size: 18px;
      }
      a{
        font-size: 16px;
      }
    }
    .flexBox{
      padding: 15px 10px;
      align-items: initial;
      h5{
        text-align: center;
      }
        .imgBox{
          width: 100%;
          height: auto;
          margin: 15px 0;
          img{
            width: 100%;
            height: auto;
          }
        }
      .left{
        margin-top: 15px;
      }
    }
    .addDocs_btn{
      text-align: center;
      a{
        max-width: none ;
      }
    }
    }
  }
}