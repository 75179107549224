.e_notes {
  padding: 50px 0;
  background: #f5f5f5;
  min-height: 100%;
  // display: flex;
  // justify-content: center;
  // align-items: center;

  .content {
    border-radius: 15px;
    box-shadow: 0px 0px 8px #e5e5e5, 5px 5px 8px #e9e9e9;
    padding: 50px 35px;
    background-color: #fff;
    .title {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      position: relative;
      .backArrow {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        a {
          border: 0;
          outline: none;
          background: none;
          color: #000;
        }
      }
      .heading {
        width: 100%;
        text-align: center;
        h1 {
          font-size: 20px;
          font-weight: 700;
        }
      }
    }
    .form-group {
      margin: 10px 0;
      position: relative;
      .form-select,
      .form-control {
        border-radius: 25px;
        border-color: #a8a8a8;
        color: #020202;
        font-weight: 500;
        padding: 10px;
        min-height: 40px;
        option {
          color: #a8a8a8;
          font-weight: 400;
        }
        &::placeholder {
          color: #020202;
        }
      }
      svg {
        position: absolute;
        right: 15px;
        top: 40%;
        transform: translateY(-40%);
        color: #a8a8a8;
        cursor: pointer;
      }
    }
    .addMore {
      text-align: center;
      margin-top: 35px;
      .submitBtn {
        display: inline-block;
        margin: 35px auto 0;
        background: linear-gradient(#a0a0a0, #323232);
        color: #fff;
        padding: 7px 75px;
        font-size: 16px;
        border-radius: 25px;
      }
    }
    .tabBox {
      margin-top: 15px;
      .nav {
        justify-content: center;
        .nav-item {
          .nav-link {
            background: #fff;
            border: 1px solid #d1d1d1;
            color: #020202;
            font-weight: 700;
            padding: 5px 15px;
            border-radius: 25px;
            width: 125px;
            margin-left: 8px;
            &.active {
              background: linear-gradient(#a0a0a0, #323232);
              color: #fff;
            }
          }
        }
      }
      .tab-content {
        .tab-pane {
          .form-group {
            margin: 15px 0 0;
            .form-control {
              border-radius: 10px;
              padding: 3px 8px;
              min-height: 200px;
            }
          }
          .audioBox {
            border-radius: 10px;
            border: 1px solid #85ddd2;
            min-height: 225px;
            .imgBox {
              border-bottom: 1px solid #efefef;
              text-align: center;
              padding: 15px 0;
            }
            .voiceBox {
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;
              padding: 50px 0;
              a {
                display: inline-block;
                color: #4e4e4e;
                font-size: 28px;
              }
              .redCircle {
                margin-left: 20px;
                svg {
                  fill: #ed1b24;
                  font-size: 50px;
                }
              }
            }
          }
        }
      }
    }
  }
}


