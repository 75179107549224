.forgotPasswordPage {
    padding: 50px 0;
    // background: url(../../images/home_bg.png) no-repeat center bottom;
    background-size: cover;
    min-height: 100%;
    display: flex;
    justify-content: center;
    min-height: 100%;
    .loginForm {
      text-align: center;
      margin: 50px 0 0;
      background: #fff;
      border: 1px solid #d6edf4;
      border-radius: 10px;
      padding: 35px;
      h4 {
        color: #000000;
        font-weight: 600;
        font-size: 20px;
        color: #000;
        margin-bottom: 35px;
      }
      .form-group {
        position: relative;
        margin: 15px 0 0;
        .form-control {
          background-color: #f7f8fc;
          color: #a0a7b9;
          border-radius: 10px;
          border-color: #c2d4da;
          padding-right: 35px;
          min-height: 40px;
          &:focus {
            border-color: #919191;
          }
          &::placeholder{
            color: #a0a7b9;
          }
        }
        span {
          position: absolute;
          top: 40%;
          right: 15px;
          transform: translateY(-40%);
          color: #676767;
        }
      }
      .loginBtn {
        text-align: center;
        a, button {
          display: inline-block;
          background: #000;
          margin-top: 15px;
          color: #fff;
          font-size: 14px;
          border-radius: 10px;
          padding: 10px 15px;
          max-width: 165px;
          width: 100%;
        }
      }
      .notReg {
        p {
          text-align: center;
          color: #6f8b99;
          font-weight: 500;
          font-size: 14px;
          .signupBtn {
            font-size: 15px;
            display: inline-block;
            font-weight: 600;
            color: #000000;
          }
        }
      }
    }
  }
  
  @media (max-width: 991px) {
    .loginPage {
      .loginForm {
        padding: 20px;
        .notReg {
          p {
            font-size: 13px;
          }
        }
      }
    }
  }
  
  @media (max-width: 991px) {
    .loginPage {
      .loginForm {
        padding: 20px 10px;
        margin: 25px 0 0;
        .notReg {
          p {
            font-size: 13px;
          }
        }
      }
    }
  }
  