.workInner {
    padding: 100px 0 25px;
    // background: url(../../images/signup_bg.png) no-repeat center bottom;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100%;
  
    .userBox {
      h4 {
        color: #000000;
        font-weight: 600;
        font-size: 20px;
        color: #000;
        // text-align: center;
      }
      h5 {
        color: #000000;
        font-weight: 600;
        font-size: 16px;
        color: #000;
        margin-bottom: 15px;
      }
      p {
        margin: 0px 0 25px;
        font-weight: 500;
        font-size: 17px;
      }
      .mapBox {
        background: #fff;
        padding-bottom: 20px;
        border-bottom: 1px solid #cbe0e9;
        .update_box{
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 15px;
          h5{
            margin-bottom: 0;
          }
          a{
            margin-top: 0;
          }
        }
        .profileBox {
          display: flex;
          border-bottom: 1px solid #cee1e9;
          // box-shadow: 0px 1px 6.58px 0.42px rgba(58, 58, 58, 0.14);
          margin: 15px 0;
          align-items: center;
          padding-bottom: 20px;
          .imgBox {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            border: 2px solid #9ca4bc;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #7d7d7d;
            span{
              line-height: 0;
              svg{
                color: #9ca4bc;
              }
            }
          }
          .content {
            margin-left: 15px;
            h4 {
              font-size: 17px;
              font-weight: 600;
              color: #000000;
              text-align: left;
            }
            p {
              font-size: 13px;
              margin: 0;
              margin-top: 3px;
              color: #8294b3;
              font-weight: 400;
              display: flex;
              align-items: center;
              svg {
                margin-right: 5px;
                font-size: 14px;
                fill: #8294b3;
              }
            }
          }
        }
        .mapNav {
          border-radius: 15px;
          border: 1px solid #6d6d6d;
          overflow: hidden;
        }
        .form-group {
          position: relative;
          margin-top: 10px;
          .form-control {
            color: #4b4b4b;
            border-radius: 10px;
            border-color: #909090;
            padding-right: 35px;
            min-height: 40px;
            font-size: 15px;
            &:focus {
              border-color: #919191;
            }
            &::placeholder{
              color: #4b4b4b;
            }
          }
          span {
            position: absolute;
            top: 40%;
            right: 5px;
            font-size: 14px;
            transform: translateY(-40%);
            color: #000;
            line-height: 0;
          }
        }
        .updateLoc{
          margin-top: 15px;
          display: block;
          text-align: center;
          font-size: 13px;
          font-weight: 500;
          color: #000;
        }
      }
      .nextBtn {
        margin: 35px 0 0;
        text-align: center;
        a {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          background: #000;
          color: #fff;
          border-radius: 10px;
          padding: 8px 10px;
          max-width: 235px;
          width: 100%;
          span {
            margin-left: 5px;
          }
        }
      }
      .range_box{
        margin-top: 15px;
        .css-1aznpnh-MuiSlider-root {
          color: #01b399;
          height: 6px;
          .css-14pt78w-MuiSlider-rail {
            background-color: #d7d7d7;
          }
          .css-1tfve6q-MuiSlider-mark {
            display: none;
          }
          .css-6cwnna-MuiSlider-mark {
            display: none;
          }
          .css-eg0mwd-MuiSlider-thumb {
            background-color: #fff;
            border: 2px solid #064f70;
          }
          .css-1eoe787-MuiSlider-markLabel,
          .css-yafthl-MuiSlider-markLabel {
            color: #040404;
            font-weight: 500;
            font-size: 12px;
          }
        }
        p{
          margin-bottom: 10px;
          font-size: 15px;
          font-weight: 600;
        }
      }
      .otpForm {
        text-align: center;
        background: #fff;
        border: 1px solid #fff;
        border-radius: 10px;
        h4 {
          color: #000000;
          font-weight: 700;
          font-size: 20px;
          color: #000;
        }
        p {
          font-weight: 600;
          font-size: 16px;
          text-align: left;
        }
        .transport {
          margin-left: 35px;
          p {
            margin: 25px 0 15px;
            font-size: 14px;
          }
          &.borderB{
            border-bottom: 1px solid #e1f2f8;
            padding-bottom: 20px;
            margin-right: 35px;
          }
        }
        .form-check {
          display: flex;
          padding-left: 0;
          align-items: center;
          .form-check-input {
            margin-top: 0;
            border-radius: 2px;
            border: 2px solid #000000;
            &:checked {
              background-color: #000;
            }
          }
          .form-check-label {
            position: relative;
            font-weight: 500;
            color: #000000;
            font-size: 13px;
          }
          .form-check-input {
            margin-left: 0;
            margin-right: 3px;
          }
        }
      }
      .workProfileForm {
        background: #fff;
        border: 1px solid #e5f4f7;
        border-radius: 10px;
        padding: 20px 35px;
        margin-top: 20px;
        margin-left: 35px;
        p{
          margin-bottom: 10px;
        }
        .form-check {
          display: flex;
          align-items: center;
          padding-left: 0;
          margin: 12px 0;
          .form-check-input {
            border-radius: 2px;
            border: 2px solid #000000;
          }
          .form-check-label {
            position: relative;
            font-weight: 600;
            color: #000000;
          }
          a {
            position: absolute;
            right: -20px;
            display: inline-block;
            color: #000000;
          }
          .form-check-input {
            margin-left: 0;
            margin-right: 5px;
            margin-top: 0;
            &:checked {
              background-color: #000;
            }
          }
        }
      }
      .addSpec{
        text-align: right;
        a{
          display: inline-block;
          color: #000;
          font-weight: 500;
        }
      }
    }
  }
  
  @media (max-width: 1199px) {
    .workInner {
      .userBox {
        .mapBox {
          padding: 15px;
          .profileBox {
            padding:20px 10px;
            margin: 15px 0px;
          }
        }
        .workProfileForm {
          .form-check {
            .form-check-label {
              font-size: 15px;
            }
          }
        }
        .otpForm {
          padding: 15px 35px;
        }
      }
    }
  }
  
  @media (max-width: 576px) {
    .workInner {
      padding: 25px 0;
      .userBox {
        margin-top: 25px;
        .workProfileForm {
          padding: 15px 10px;
          margin-top: 30px;
        }
        p {
          margin: 10px 0;
        }
        .nextBtn {
          margin: 25px 0 0;
        }
        .mapBox{
          padding: 10px;
          .profileBox{
            margin: 15px 0px;
          }
        }
        .otpForm{
            margin-top: 15px;
          p{
            margin: 10px 0 15px;
          }
        }
      }
    }
  }
  