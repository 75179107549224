.previous_injury {
    padding: 100px 0 25px;
    background: #fff;
    // min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .ticket_box{
      h6{
        font-size: 17px;
        color: #000;
        font-weight: 600;
      }
      .border-1{
        margin-top: 15px;
        border: 1px solid #d6edf4;
        border-radius: 8px;
        .table{
          text-align: left;
          margin: 0;
          thead{
            tr{
              border-width: 0;
            }
            th{
              font-size: 14px;
              font-weight: 700;
            }
          }
          tbody{
            border: 1px solid #d6edf4;
            td{
              font-size: 14px;
              font-weight: 500;
            }
            .attach_docs{
              display: flex;
              img{
                width: 40px;
                height: 30px;
                min-width: 40px;
                border: 1px solid #d6edf4;
                border-radius: 6px;
                margin-right: 4px;
              }
              span{
                display: inline-block;
                width: 100%;
                margin-left: 5px;
                color: #66bbc0;
                font-size: 14px;
                font-weight: 600;
              }
            }
            .starRate{
              display: flex;
              svg{
                fill: #facf1e;
              }
            }
            .view_btn{
              display: inline-block;
              font-size: 12px;
              color: #74b6b7;
            }
          }
          a{
            vertical-align: -webkit-baseline-middle;
            color: #3baaaf;
            font-weight: 500;
            font-size: 13px;
          }
        }
      }
    }
  
  }

  @media (max-width: 991px){
    .previous_injury {
      .content{
        .descBox {
          .picsBox{
            justify-content: unset;
            img{
              margin: 0 5px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 576px){
    .previous_injury {
      .content {
        .title{
          margin-bottom: 10px;
        }
      }
      .ticket_box {
        h6{
          text-align: center;
        }
      }
    }
  }
  