.PreEmployee {
  padding: 50px 0;
  background: url(../../images/signup_bg.png) no-repeat center bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  .otpForm {
    text-align: center;
    margin: 50px 0 0;
    background: #fff;
    border: 1px solid #fff;
    border-radius: 10px;
    padding: 35px;
    h4 {
      color: #000000;
      font-weight: 700;
      font-size: 20px;
      color: #000;
      margin-bottom: 25px;
    }
    .radioBtn {
      margin: 5px 0;
      display: flex;
      align-items: center;
      padding: 12px 0;
      .label {
        font-weight: 700;
      }
      .form-check-inline {
        margin-left: 35px;
        margin-right: 0;
        .form-check-input {
          border: 1px solid #000000;
          &:checked {
            background-image: none;
            background: #000000;
          }
          &:focus {
            box-shadow: none;
          }
        }
        .form-check-label {
          font-weight: 600;
          color: #000;
        }
      }
    }
    .form-group {
      position: relative;
      margin: 15px 0;
      .form-control,
      .form-select {
        border-radius: 25px;
        border-color: #919191;
        padding-right: 35px;
        min-height: 40px;
        &:focus {
          border-color: #919191;
        }
        option {
          color: #676767;
        }
      }
      span {
        position: absolute;
        top: 40%;
        right: 15px;
        transform: translateY(-40%);
        color: #676767;
      }
    }
    .save_Proceed {
      text-align: center;
      button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(#a0a0a0, #323232);
        color: #fff;
        border-radius: 25px;
        padding: 10px 15px;
        max-width: 185px;
        width: 100%;
        font-size: 14px;
        margin: 15px 0 0;
        span {
          margin-left: 5px;
          display: inline-block;
        }
      }
    }
  }
  .preBtn {
    text-align: center;
    button {
      display: inline-block;
      align-items: center;
      justify-content: center;
      background: #fff;
      color: #393939;
      border-radius: 25px;
      padding: 10px 25px;
      max-width: 185px;
      width: 100%;
      margin-top: 25px;
      font-size: 14px;
      font-weight: 700;
      span {
        margin-right: 5px;
      }
    }
  }
}

@media (max-width: 576px) {
  .PreEmployee {
    padding: 25px 0;
    .otpForm {
      margin: 15px 0 0;
      padding: 25px 10px;
      h4 {
        margin-bottom: 15px;
      }
      .radioBtn {
        margin: 0;
      }
    }
    .preBtn {
      button {
        margin: 0;
      }
    }
  }
}
