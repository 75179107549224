.privacyBanner{
    background: url(../../images/how_work/works-banner.png) no-repeat center bottom;
    background-size: cover;
    // margin-top: -63px;
    padding: 50px 0 0;

   .work{
    padding: 150px 0;
    text-align: center;
    color: #fff;
    max-width: 500px;
    margin: auto;

    h1{
        font-size: 38px;
        font-weight: 400;
       }
   }       
}
.privacy-section{
    padding: 50px 0px 20px;
    .privacy_box{
        border: 1px solid #d6edf4;
        border-radius: 10px;
        padding: 20px;
        h3{
            font-size: 24px;
            font-weight: 600;
            color: #000;
            margin-bottom: 10px;
        }
        .nextBtn {
            margin: 50px 0 0;
            text-align: center;
            a {
              display: inline-flex;
              align-items: center;
              justify-content: center;
              color: #fff !important;
              background: #000;
              border-radius: 10px;
              padding: 10px 60px;
              cursor: pointer;
              font-size: 15px;
              span {
                margin-left: 5px;
              }
            }
          }
    }
} 
.subscribe {
    background: #000000;
    padding: 50px 0;

    p {
        color: #fff;
        font-weight: 400;
    }

    .subBtn {
        a {
            display: inline-block;
            background: #4bb1b4;
            color: #fff;
            border: 1px solid transparent;
            border-radius: 25px;
            padding: 7px 10px;
            font-size: 15px;
            max-width: 180px;
            width: 100%;
            text-align: center;
        }
    }
}  

@media (max-width: 1399px){
    .privacyBanner{
        .work
         h1{
            font-size: 36px;
        }
    }
}

@media (max-width: 1199px){
    .subscribe {
        padding: 40px 0px;
        padding: 35px 0px;

        p {
            font-size: 16px;
        }
    }
    .privacy-section {
        .para-A{
          p{
          font-size: 16px;
          }
        }
    }
}

@media (max-width: 1024px){
    .privacyBanner {
        .work {
            h1{
                font-size: 32px;
            }
        }
    }
}

@media (max-width: 991px){
    .privacyBanner{
        padding: 50px 0 0;
    }
    .privacy-section{
    .para-A{
        p{
        font-size: 15px;
        }
    }
  }
}

@media (max-width: 576px){
    .privacyBanner {
        .work{
            padding: 150px 0 100px;
            h1 {
                font-size: 24px;
            }
        }
    }
    .subscribe {
        text-align: center;
        .subBtn{
            margin-top: 15px;
        }
    }
    .privacy-section {
        padding: 25px 0px 15px;
        text-align: center;

        .para-A{
            margin-bottom: 0px;
            h3{
                font-size: 20px;
                span{
                    display: inline-block;
                }
            }
            p{
                margin-bottom: 10px;
            }
            ol{
                text-align: left;
                li{
                    margin: 10px 0px;
                    font-size: 15px;
                }
            }
            ul{
                li{
                    margin: 10px 0px;
                    font-size: 15px;
                }
            }
        }
    }
}