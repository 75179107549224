.licensePage {
  padding: 50px 0;
  background: url(../../images/signup_bg.png) no-repeat center bottom;
  background-size: cover;
  // height: calc(100vh - 0px);

  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .signupForm {
    text-align: center;
    margin: 50px 0 0;
    background: #fff;
    border: 1px solid #fff;
    border-radius: 10px;
    padding: 35px;
    h4 {
      color: #000000;
      font-weight: 700;
      font-size: 20px;
      color: #000;
      margin-bottom: 25px;
    }
    .form-check-input {
      border: 1px solid #000000;
      &:checked {
        background-image: none;
        background: #000000;
      }
      &:focus {
        box-shadow: none;
      }
    }
    .form-check-label {
      font-weight: 600;
      color: #000;
    }
    .form-group {
      position: relative;
      margin: 15px 0;
      .form-control {
        border-radius: 25px;
        border-color: #919191;
        &:focus {
          border-color: #919191;
        }
      }
      span {
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
        color: #676767;
      }
    }
    .nextBtn {
      margin: 50px 0 0;
      text-align: center;
      button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(#a0a0a0, #323232);
        color: #fff;
        border-radius: 25px;
        padding: 8px 60px;
        span {
          margin-left: 5px;
        }
      }
    }
  }
  .preBtn {
    text-align: center;
    button {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      background: #fff;
      color: #393939;
      font-weight: 700;
      border-radius: 25px;
      padding: 8px 30px;
      span {
        margin-right: 5px;
      }
    }
  }
}

@media (max-width: 576px){
  .licensePage{
    .signupForm {
      margin-top: 25px 0 0;
      h4{
        font-size: 18px;
        margin-bottom: 20px;
      }
      .nextBtn{
        margin: 35px 0 0;
      }
    }
  }
}
