.loginPage {
  padding: 50px 0;
  background-size: cover;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  .loginForm {
    text-align: center;
    margin: 50px 0 0;
    background: #fff;
    border: 1px solid #d6edf4;
    border-radius: 10px;
    padding: 25px 20px;
    h4 {
      color: #000000;
      font-weight: 600;
      font-size: 17px;
      color: #000;
      margin-bottom: 25px;
    }
    .form-group {
      position: relative;
      margin: 15px 0 0;
      .form-control {
        background-color: #f7f8fc;
        color: #a0a7b9;
        border-radius: 10px;
        border-color: #c2d4da;
        padding-right: 35px;
        min-height: 40px;
        &:focus {
          border-color: #919191;
        }
        &::placeholder{
          color: #a0a7b9;
        }
      }
      span {
        position: absolute;
        top: 40%;
        right: 15px;
        transform: translateY(-40%);
        color: #676767;
      }
    }
    .loginBtn {
      text-align: center;
      a {
        display: inline-block;
        margin-top: 15px;
        background-color: #000;
        color: #fff;
        font-size: 14px;
        border-radius: 10px;
        padding: 10px 15px;
        max-width: 165px;
        width: 100%;
      }
    }
    .fbBtn {
      text-align: center;
      button {
        border-radius: 10px;
        color: #fff;
        display: inline-block;
        max-width: 235px;
        margin-bottom: 15px;
        width: 100%;
        background: #0178f2;
        font-size: 15px;
        padding: 9px 15px;
        position: relative;
        box-shadow: 0px 4px 7px 0px rgba(1, 120, 242, 0.36);
        border: 1px solid transparent;
        span {
          position: absolute;
          left: 10px;
        }
      }
    }
    .googleBtn {
      text-align: center;
      button {
        overflow: hidden;
        border-radius: 10px !important;
        max-width: 235px;
        width: 100%;
        font-size: 15px !important;
        position: relative;
        box-shadow: 0px 4px 7px 0px rgba(213, 220, 222, 0.36) !important;
        border: 1px solid #cacaca !important;
        span {
          position: absolute;
          left: 48px;
          font-weight: 500 !important;
        }
      }
      div{
        padding: 9px !important;
      }
    }
    .notReg {
      margin-top: 35px;
      padding-top: 25px;
      border-top: 1px solid #bfbfbf;
      p {
        text-align: center;
        color: #6f8b99;
        font-weight: 500;
        font-size: 14px;
        .signupBtn {
          font-size: 15px;
          display: inline-block;
          font-weight: 600;
          color: #000000;
        }
      }
    }
    .forgot-password{
      text-align: right;
      a{
        font-size: 15px;
        color: #000;
        font-weight: 500;
      }
    }
  }
  .login_with_pass{
    border-right: 1px solid #bfbfbf;
    padding-right: 25px;
  }
}

@media (max-width: 991px) {
  .loginPage {
    .loginForm {
      padding: 20px 10px;
      margin: 25px 0 0;
      .notReg {
        p {
          font-size: 13px;
        }
      }
    }
  }
}

@media (max-width: 576px){
  .loginPage {
    padding: 50px 0 15px;
    .login_with_pass{
      border-right: none;
      border-bottom: 1px solid #bfbfbf;
      padding-right: 0;
      padding-bottom: 25px;
    }
    .social_login{
      padding-top: 15px;
    }
  }
}

.Loadingstyle{
  display: inline !important;
}

.googleButton span {
  padding: 10px 10px 10px 0px !important;
}
.kep-login-facebook{
  text-transform: capitalize !important;
  font-weight: 500 !important;
  font-family: inherit !important;
}