.guardSec {
    .workGuard {
        padding: 50px 0;

        .imgBox {
            text-align: right;
            padding-right: 50px;

            img {
                width: 600px;
                height: 400px;
                object-fit: cover;
                // border-radius: 50%;
            }
        }

        .content {
            h3 {
                font-size: 24px;
                font-weight: 700;
            }

            p {
                margin: 20px 0;
                font-weight: 400;
            }

            a {
                display: inline-block;
                background: #4bb1b4;
                color: #fff;
                border: 1px solid transparent;
                border-radius: 25px;
                padding: 7px 10px;
                font-size: 15px;
                max-width: 180px;
                width: 100%;
                text-align: center;
            }
        }
    }

    .hireBg {
        background: #f9f9f9;
        padding: 50px 0;

        .imgBox {
            padding-left: 50px;

            img {
                width: 600px;
                height: 400px;
                object-fit: cover;
                // border-radius: 50%;
            }
        }

        .content {
            text-align: right;

            h3 {
                font-size: 24px;
                font-weight: 700;
            }

            p {
                margin: 20px 0;
                font-weight: 400;
            }

            a {
                display: inline-block;
                background: #4bb1b4;
                color: #fff;
                border: 1px solid transparent;
                border-radius: 25px;
                padding: 7px 10px;
                font-size: 15px;
                max-width: 180px;
                width: 100%;
                text-align: center;
            }
        }
    }
}

.needAssistance {
    padding: 50px 0;
    background: #ededed;
    text-align: center;

    .content {
        p {
            color: #000000;
            font-weight: 400;
        }

        a {
            display: inline-block;
            background: #4bb1b4;
            color: #fff;
            border: 1px solid transparent;
            border-radius: 25px;
            padding: 7px 10px;
            font-size: 15px;
            max-width: 180px;
            width: 100%;
            text-align: center;
            margin-top: 30px;
        }
    }
}

.goGuardApp {
    padding: 50px 0 0;

    h1 {
        text-align: center;
        font-size: 30px;
        font-weight: 700;
        margin-bottom: 50px;
    }

    .box {
        .content {
            text-align: center;

            h5 {
                color: #000000;
                font-weight: 700;
                font-size: 20px;
            }

            p {
                font-weight: 400;
                margin: 10px 0;
            }

            .imgBox {
                display: flex;
                justify-content: space-between;
                margin: 25px 0 35px;

                img {
                    width: 125px;
                }
            }

            a {
                color: #4bb1b4;
                display: inline-block;
                font-weight: 400;
                font-size: 15px;
            }
        }

        .mobImg {
            text-align: center;

            img {
                width: 200px;
            }
        }
    }
}

.get_touch {
    padding: 50px 0;
    background-color: #f4f4f4;
    background-image: url(../../images/welcomePage/get_touch.jpg);
    background-position: right;
    background-size: 50%;
    background-repeat: no-repeat;

    h2 {
        color: #173839;
        font-weight: 700;
        font-size: 28px;
    }

    p {
        color: #173839;
        font-weight: 400;
        margin: 20px 0;
    }

    a {
        display: inline-block;
        background: #4bb1b4;
        color: #fff;
        border: 1px solid transparent;
        border-radius: 25px;
        padding: 7px 10px;
        font-size: 15px;
        max-width: 180px;
        width: 100%;
        text-align: center;
    }
}

.subscribe {
    background: #000000;
    padding: 50px 0;

    p {
        color: #fff;
        font-weight: 400;
    }

    .subBtn {
        a {
            display: inline-block;
            background: #4bb1b4;
            color: #fff;
            border: 1px solid transparent;
            border-radius: 25px;
            padding: 7px 10px;
            font-size: 15px;
            max-width: 180px;
            width: 100%;
            text-align: center;
            cursor: pointer;
        }
    }
}

.banner {
    background: url('../../images/welcomePage/banner.png') no-repeat center bottom;
    background-size: cover;
    padding: 35px 0;

    .outerBox {
        position: relative;
        .item {
            .mainBox {
                padding: 150px 0;
                text-align: center;
                color: #fff;
                max-width: 500px;
                margin: auto;

                h1 {
                    font-size: 38px;
                    font-weight: 400;
                }

                a {
                    display: inline-block;
                    background: #4bb1b4;
                    color: #fff;
                    border: 1px solid transparent;
                    border-radius: 25px;
                    margin-top: 25px;
                    padding: 7px 10px;
                    font-size: 15px;
                    max-width: 180px;
                    width: 100%;
                    text-align: center;
                }
            }
        }

        .owl-carousel {
            .owl-dots{
                display: none;
            }
            .owl-nav {
                display: block;

                button {
                    color: #fff;
                    font-size: 50px;
                    position: absolute;
                    transform: translateY(-50%);
                    top: 50%;

                    &.owl-prev {
                        left: 0;
                        &:hover{
                            background: none;
                        }
                    }

                    &.owl-next {
                        right: 0;
                        &:hover{
                            background: none;
                        }
                    }
                }
            }
        }
    }
}

.career{
    background: url('../../images/career_bg.png') no-repeat center bottom;
    background-size: cover;
    padding: 50px 0 0;
    .careerBox{
        padding: 150px 0;
        h1{
            text-align: center;
            color: #fff;
            font-size: 38px;
            font-weight: 400;
        }
    }
}

.lifeSec{
    text-align: center;
    padding: 50px 0;
    h5{
        font-size: 16px;
        font-weight: 700;
        color: #000;
    }
    h4{
        font-size: 20px;
        font-weight: 400;
        margin: 10px 0 35px;
    }
    p{
        font-weight: 400;
        color: #000;
    }
}

.why_joinUs{
    padding: 50px 0;
    background: #f4f4f4;
    text-align: center;
    h5{
        font-size: 16px;
        font-weight: 700;
        color: #000;
    }
    ul{
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-top: 25px;
        li{
            margin: 0 10px;
            padding: 0 15px;
            img{
                width: 65px;
                object-fit: cover;
                margin-bottom: 10px;
            }
            p{
                color: #000;
                font-weight: 500;
            }
        }
    }
}

.opening{
    padding: 50px 0;
    background: #fff;
    text-align: center;
    h5{
        font-size: 16px;
        font-weight: 700;
        color: #000;
        margin-bottom: 20px;
    }
    .opening_box{
        box-shadow: 0px 6px 15px 0px rgba(28, 29, 29, 0.09);
        border-radius: 5px;
        padding: 20px 10px;
        margin: 10px 0;
        h4{
            font-size: 16px;
            font-weight: 400;
            color: #000;
            font-weight: 500;
        }
        span{
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            font-weight: 500;
            margin: 10px 0;
            svg{
                margin-right: 5px;
            }
        }
        p{
            font-size: 13px;
            color: #000;
        }
        a{
            margin-top: 15px;
            display: inline-block;
            border-radius: 25px;
            font-size: 14px;
            color: #fff;
            background: #4ab0b4;
            padding: 5px 50px;
        }
    }
}

.apply_now{
    padding: 50px 0;
    background: #f4f4f4;
    h5{
        font-size: 16px;
        color: #000;
        font-weight: 700;
        text-align: center;
    }
    .apply_form{
        margin: 20px auto 0;
        max-width: 850px;
        width: 100%;
        text-align: center;
        .form-group {
            position: relative;
            margin: 8px 0;
            .form-control,.form-select {
              background-color: #fff;
              color: #000;
              border-radius: 25px;
              border-color: #fff;
              padding-right: 35px;
              font-size: 14px;
              min-height: 40px;
              font-weight: 400;
              &:focus {
                border-color: #fff;
              }
              &::placeholder{
                color: #000;
              }
            }
            span {
              position: absolute;
              top: 40%;
              right: 15px;
              transform: translateY(-40%);
              color: #000;
            }
            .textarea{
                border-radius: 10px;
            }
          }
          a{
            margin-top: 15px;
            display: inline-block;
            border-radius: 25px;
            font-size: 14px;
            color: #fff;
            background: #4ab0b4;
            padding: 10px 50px;
        }
    }
}

@media (max-width: 1399px) {
    .goGuardApp {
        .box {
            .content {
                .imgBox {
                    margin: 20px 0 30px;
                    margin: 15px 0 25px;
                }
            }
        }
    }

    .get_touch {
        background-size: 50% 310px;
    }

    .banner {
        .outerBox {
            .item {
                .mainBox {
                    h1 {
                        font-size: 32px;
                    }
                }
            }
        }
    }
}

@media (max-width: 1199px){
    .subscribe {
        padding: 40px 0px;
        padding: 35px 0px;

        p {
            font-size: 16px;
        }
    }

    .get_touch {
        h2 {
            font-size: 26px;
        }
    }

    .goGuardApp {
        h1 {
            font-size: 26px;
        }
    }
}

@media (max-width: 1024px){
    .career {
        .careerBox {
            h1{
                font-size: 32px;
            }
        }
    }
}

@media (max-width: 991px){
    .guardSec {
        .workGuard {
            .imgBox{
                padding-right: 0;
                text-align: center;
                img{
                    height: 315px;
                }
            }
        }
        .hireBg{
            .imgBox{
                padding-left: 0;
                text-align: center;
                img{
                    height: 315px;
                }
            }
        }
    }
    .goGuardApp {
        .box {
            .content {
                .imgBox {
                    img {
                        width: 95px;
                    }

                    margin: 10px 0 20px;
                }

                p {
                    font-size: 14px;
                }
            }
        }

        padding: 40px 0px 0px 0px;
    }
    .get_touch {
        background-size: 50% 325px;
        h2 {
            font-size: 24px;
        }
        p {
            margin: 15px 0px;
            font-size: 14px;
        }
    }
    .welcomeHeader{
        .navbar {
            background-color: rgba(52, 52, 52,1);
            .navbar-nav{
                margin-top: 10px;
            }
        }
        .navbar-light {
            .navbar-toggler{
                border: none;
                &:focus{
                    box-shadow: none;
                }
                svg{
                    fill: #fff;
                }
            }
            .navbar-toggler-icon{
                display: none;
            }
        }
    }
}

@media (max-width: 576px){
    .guardSec {
        .workGuard,.hireBg{
            text-align: center;
            padding: 35px 0;
            .content{
                margin-top: 15px;
                text-align: center;
                p{
                    margin: 10px 0;
                }
            }
            .imgBox{
                img{
                    height: auto;
                }
            }
        }
    }
    .goGuardApp {
        padding: 35px 0px 0px;
        h1{
            font-size: 24px;
            margin-bottom: 0;
        }
        .box{
            .content{
                margin-top: 35px;
                .imgBox{
                    img{
                        width: 125px;
                    }
                }
            }
            .mobImg{
                margin-top: 35px;
            }
        }
    }
    .get_touch{
        background-size: cover;
        padding: 35px 0;
        text-align: center;
        h2{
            color: #9b9696;
        }
        p{
            color: #d1d1d1;
        }
    }
    .subscribe{
        text-align: center;
        .subBtn{
            margin-top: 15px;
        }
    }
    .topHeader{
        background: #f9f9f9;
        padding: 15px 0;
        .box{
            justify-content: center;
        }
    }
    .banner {
        .outerBox {
            .item{
                .mainBox{
                    padding: 65px 0 0;
                    h1{
                        font-size: 26px;
                    }
                    a{
                        font-size: 14px;
                        max-width: 135px;
                    }
                }
            }
            .owl-carousel {
                .owl-nav{
                    display: none;
                }
                .owl-dots{
                    display: flex;
                    justify-content: center;
                    margin-top: 15px;
                    .owl-dot{
                        span{
                            display: inline-block;
                            width: 12px;
                            height: 12px;
                            border-radius: 50%;
                            background: transparent;
                            border: 1px solid #fff;
                            margin: 0 3px;
                        }
                        &.active{
                            span{
                                background: #fff;
                                border: 1px solid #fff;
                            }
                        }
                    }
                }
            }
        }
    }
    .needAssistance{
        padding: 25px 0;
    }
}
