.workProfile {
  padding: 50px 0;
  background: url(../../images/signup_bg.png) no-repeat center bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  .otpForm {
    text-align: center;
    margin: 50px 0 0;
    background: #fff;
    border: 1px solid #fff;
    border-radius: 10px;
    padding: 35px;
    h4 {
      color: #000000;
      font-weight: 700;
      font-size: 20px;
      color: #000;
      margin-bottom: 25px;
    }
    .mapBox {
      width: 100%;
      border: 1px solid #333333;
      border-radius: 25px;
      overflow: hidden;
      margin-bottom: 35px;
    }
    .form-group {
      position: relative;
      margin: 15px 0;
      .form-control,
      .form-select {
        border-radius: 25px;
        border-color: #919191;
        padding-right: 35px;
        min-height: 40px;
        &:focus {
          border-color: #919191;
        }
        option {
          color: #676767;
        }
      }
      span,
      a {
        position: absolute;
        top: 40%;
        right: 15px;
        transform: translateY(-40%);
        color: #676767;
      }
      .infoCircle {
        right: -25px;
        display: inline-block;
        cursor: pointer;
      }
    }
    .nextBtn {
      text-align: center;
      button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(#a0a0a0, #323232);
        color: #fff;
        border-radius: 25px;
        padding: 10px 15px;
        max-width: 185px;
        width: 100%;
        font-size: 14px;
        span {
          margin-left: 5px;
          display: inline-block;
        }
      }
    }
  }
}



@media (max-width: 576px) {
  .workProfile {
    padding: 25px 0;
    .otpForm {
      margin: 25px 0 0;
      padding: 25px 10px;
      h4 {
        margin-bottom: 10px;
      }
    }
  }
}
