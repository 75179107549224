.banner-work {
    background: url(../../images/how_work/works-banner.png) no-repeat center bottom;
    background-size: cover;
    padding: 50px 0 0;

    .work {
        padding: 150px 0;
        text-align: center;
        color: #fff;
        max-width: 500px;
        margin: auto;

        h1 {
            font-size: 38px;
            font-weight: 400;
        }
    }
}

.working {
    padding: 50px 0px;
    text-align: center;

    .work-process {
       

        h3 {
            font-size: 24px;
            font-weight: 700;
            margin-bottom: 25px;
        }
    }
    .imgBox{
        img{
            width: 175px;
        }
    }
}

.diagram {
    padding: 50px 0px;
    background-color: #f3f3f3;
    text-align: center;
    h3 {
        font-size: 24px;
        font-weight: 700;
    }
    h4 {
        font-size: 24px;
        font-weight: 700;
    }
    h6{
        font-size: 14px;
        font-weight: 700;
    }
    p {
        margin: 0 0 10px 0;
    }
}

.process_diagram{
    padding: 50px 0;
    background-color: #f9f9f9;
    h3{
        font-size: 20px;
        font-weight: 700;
        margin-top: 15px;
        margin-bottom: 15px;
        text-align: center;
    }
    .mobileBox{
        border-left: 4px solid #0090cc;
        border-right: 4px solid #0090cc;
        border-bottom: 4px solid #0090cc;
        max-width: 400px;
        margin: auto;
        min-height: 350px;
        position: relative;
        &::after{
            content: '';
            position: absolute;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: #0090cc;
            bottom: -7px;
            left: 50%;
            transform: translateX(-50%);
        }
        .box{
            position: absolute;
            align-items: center;
            display: flex;
            .cont{
                flex: 1;
                text-align: right;
                padding-right: 8px;
                max-width: 225px;
                width: 100%;
                h6{
                    font-size: 12px;
                    font-weight: 700;
                }
            }
            .imgBox{
                width: 45px;
                height: 45px;
                border: 2px solid #0090cc;
                border-radius: 50%;
                padding: 7px;
                background-color: #fff;
                overflow: hidden;
                text-align: center;
                img{
                    width: 22px;
                }
            }
            &.box1,&.box2,&.box3,&.box4{
                &::after{
                    content: "";
                    position: absolute;
                    border: solid #0090cc;
                    border-width: 0 4px 4px 0;
                    display: inline-block;
                    padding: 5px;
                    bottom: -22px;
                    left: 89%;
                    transform: rotate(45deg);
                }
            }
            &.box1{
                left: -250px;
                top: 15px;
            }
            &.box2{
                left: -250px;
                top: 200px;
                &::after{
                    // left: 73%;
                }
            }
            &.box3{
                left: -107px;
                top: 185px;
                &::after{
                    left: 78%;
                }
            }
            &.box4{
                left: -80px;
                top: 270px;
                &::after{
                    left: 71%;
                }
            }
        }
        .lbox{
            position: absolute;
            display: flex;
            align-items: center;
            right: 0;
            span{
                width: 15px;
                height: 15px;
                border-radius: 50%;
                background: #fff;
                border: 1px solid #0090cc;
            }
            .cont{
                flex: 1;
                padding-left: 8px;
                max-width: 225px;
                width: 100%;
                h6{
                    font-size: 12px;
                    font-weight: 700;
                }
            }
            .imgBox{
                width: 45px;
                height: 45px;
                border: 2px solid #0090cc;
                border-radius: 50%;
                padding: 7px;
                background-color: #fff;
                overflow: hidden;
                text-align: center;
                img{
                    width: 22px;
                }
            }
            &.box5,&.box6{
                &::after{
                    content: "";
                    position: absolute;
                    border: solid #0090cc;
                    border-width: 0 4px 4px 0;
                    display: inline-block;
                    padding: 5px;
                    bottom: -22px;
                    left: 6%;
                    transform: rotate(45deg);
                }
            }
            &.box5{
                top: 15px;
                right: -250px;
            }
            &.box6{
                top: 200px;
                right: -250px;
            }
            &.resIndi {
                top: 110px;
                right: -170px;
                .cont{
                    // max-width: 100px;
                }
            }
            &.resIncop{
                top: 135px;
                right: -193px;
                .cont{
                    // max-width: 100px;
                }
            }
            &.resBusin{
                top: 160px;
                right: -193px;
                .cont{
                    // max-width: 100px;
                }
            }
        }
    }
    .merger_box{
        position: relative;
        height: 45vh;
        z-index: 1;
        &::after{
            content: '';
            position: absolute;
            background: #0090cc;
            width: 4px;
            height: 100%;
            left: 50%;
            transform: translateX(-50%);
            z-index: -1;
        }
        .box{
            position: absolute;
            display: flex;
            align-items: center;
            .cont{
                flex: 1;
                padding-left: 8px;
                h6{
                    font-size: 12px;
                    font-weight: 700;
                }
            }
            .imgBox{
                width: 45px;
                height: 45px;
                border: 2px solid #0090cc;
                border-radius: 50%;
                padding: 7px;
                background-color: #fff;
                overflow: hidden;
                text-align: center;
                img{
                    width: 22px;
                }
            }
            &.box1{
                top: 115px;
                left: 46%;
            }
            &.box2{
                top: 200px;
                left: 46%;
            }
            &.box3{
                top: 25px;
                left: 46%;
            }
            &.box4{
                top: 270px;
                left: 46%;
                &::after{
                    display: none;
                }
            }
            &::after{
                content: "";
                position: absolute;
                border: solid #0090cc;
                border-width: 0 4px 4px 0;
                display: inline-block;
                padding: 5px;
                bottom: -22px;
                left: 18px;
                transform: rotate(45deg);
            }
        }
    }
}

.MobileView{
    padding: 50px 0;
    background-color: #f9f9f9;
    h3{
        font-size: 20px;
        font-weight: 700;
        margin-top: 15px;
        margin-bottom: 15px;
        text-align: center;
    }
    .mobileBox{
        border-left: 4px solid #0090cc;
        border-right: 4px solid #0090cc;
        border-bottom: 4px solid #0090cc;
        max-width: 175px;
        margin: auto;
        min-height: 320px;
        position: relative;
        &::after{
            content: '';
            position: absolute;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: #0090cc;
            bottom: -7px;
            left: 50%;
            transform: translateX(-50%);
        }
        .box{
            position: absolute;
            align-items: center;
            display: flex;
            .cont{
                flex: 1;
                text-align: right;
                padding-right: 8px;
                h6{
                    font-size: 12px;
                    font-weight: 700;
                }
            }
            .imgBox{
                width: 45px;
                height: 45px;
                border: 2px solid #0090cc;
                border-radius: 50%;
                padding: 7px;
                background-color: #fff;
                overflow: hidden;
                text-align: center;
                img{
                    width: 22px;
                }
            }
            &.box1,&.box2{
                &::after{
                    content: "";
                    position: absolute;
                    border: solid #0090cc;
                    border-width: 0 4px 4px 0;
                    display: inline-block;
                    padding: 5px;
                    bottom: -22px;
                    left: 66%;
                    transform: rotate(45deg);
                }
            }
            &.box1{
                left: -68px;
                top: 15px;
            }
            &.box2{
                left: -85px;
                top: 215px;
                &::after{
                    left: 73%;
                }
            }
        }
        .lbox{
            position: absolute;
            display: flex;
            align-items: center;
            right: 0;
            span{
                width: 15px;
                height: 15px;
                border-radius: 50%;
                background: #fff;
                border: 1px solid #0090cc;
            }
            .cont{
                flex: 1;
                padding-left: 8px;
                h6{
                    font-size: 12px;
                    font-weight: 700;
                }
            }
            .imgBox{
                width: 45px;
                height: 45px;
                border: 2px solid #0090cc;
                border-radius: 50%;
                padding: 7px;
                background-color: #fff;
                overflow: hidden;
                text-align: center;
                img{
                    width: 22px;
                }
            }
            &.box5,&.box6{
                &::after{
                    content: "";
                    position: absolute;
                    border: solid #0090cc;
                    border-width: 0 4px 4px 0;
                    display: inline-block;
                    padding: 5px;
                    bottom: -22px;
                    left: 17%;
                    transform: rotate(45deg);
                }
            }
            &.box5{
                top: 15px;
                right: -82px;
            }
            &.box6{
                top: 215px;
                right: -82px;
            }
            &.resIndi {
                top: 90px;
                right: -109px;
                .cont{
                    max-width: 100px;
                }
            }
            &.resIncop{
                top: 125px;
                right: -109px;
                .cont{
                    max-width: 100px;
                }
            }
            &.resBusin{
                top: 162px;
                right: -109px;
                .cont{
                    max-width: 100px;
                }
            }
        }
    }
    .merger_box{
        position: relative;
        height: 315px;
        z-index: 1;
        &::before{
            position: absolute;
            content: '';
            width: 4px;
            height: 100%;
            background: #0090cc;
            top: 0px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 0;
        }
        .box{
            position: absolute;
            display: flex;
            align-items: center;
            .cont{
                flex: 1;
                padding-left: 8px;
                h6{
                    font-size: 12px;
                    font-weight: 700;
                }
            }
            .imgBox{
                width: 45px;
                height: 45px;
                border: 2px solid #0090cc;
                border-radius: 50%;
                padding: 7px;
                background-color: #fff;
                overflow: hidden;
                text-align: center;
                img{
                    width: 22px;
                }
            }
            &.box1{
                top: 160px;
                left: 44%;
                &::after{
                    left: 12%;
                }
            }
            &.box2{
                top: 240px;
                left: 44%;
                &::after{
                    display: none;
                }
            }
            &.box3{
                left: 44%;
                top: 10px;
            }
            &.box4{
                left: 44%;
                top: 85px;
            }
            &::after{
                content: "";
                position: absolute;
                border: solid #0090cc;
                border-width: 0 4px 4px 0;
                display: inline-block;
                padding: 5px;
                bottom: -22px;
                left: 14%;
                transform: rotate(45deg);
            }
        }
    }
}


@media (max-width: 1680px){
    .process_diagram{
        .merger_box{
            height: 55vh;
        }
    }
}

@media (max-width: 1520px){
    .process_diagram{
        .merger_box{
            height: 60vh;
        }
    }
}

@media (max-width: 1399px){
    .process_diagram{
        .merger_box{
            height: 380px;
            .box{
                &::after{
                    left: 15px;
                }
            }
        }
        
    }
}

@media screen and (min-width: 1367px) and (max-width: 1368px){
    .process_diagram {
        .merger_box {
            height: 45vh;
            .box{
                &::after{
                    left: 9%;
                }
                &.box3{
                    left: 45%;
                    &::after{
                        left: 20px !important;
                    }
                }
                &.box4{
                    left: 46% !important;
                    &::after{
                        left: 11%;
                    }
                }
                &.box1,&.box2{
                    &::after{
                        left: 16px;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1279px) and (max-width: 1280px){
    .process_diagram {
        .merger_box {
            height: 45vh;
            .box{
                &::after{
                    left: 9%;
                }
                &.box3{
                    left: 46%;
                    &::after{
                        left: 15px !important;
                    }
                }
                &.box4{
                    left: 46% !important;
                    &::after{
                        left: 11%;
                    }
                }
                &.box1,&.box2{
                    &::after{
                        left: 16px;
                    }
                }
            }
        }
    }
}

@media (max-width: 1199px){
    .process_diagram{
        .merger_box{
            .box{
                width: 100%;
                &.box1,&.box2,&.box3,&.box4{
                    left: 45%;
                }
            }
        }
    }
    .subscribe {
        padding: 40px 0px;
        padding: 35px 0px;
    
        p {
          font-size: 16px;
        }
      }
}

@media (max-width: 1024px){
    .banner-work {
        .work {
            h1{
                font-size: 32px;
            }
        }
    }
    .process_diagram .merger_box {
        height: 375px;
    }
}

@media screen and (min-width: 911px) and (max-width: 912px){
    .process_diagram {
        .merger_box {
            height: 30vh;
        }
    }
}

@media (max-width: 991px){
    .process_diagram {
        .mobileBox{
            max-width: 265px;
        }
        .merger_box{
            .box{
                &.box1,&.box2,&.box3,&.box4{
                    left: 43%;
                }
                &::after{
                    left: 17px;
                }
            }
        }
    }
}

@media screen and (min-width: 843px) and (max-width: 851px){
    .process_diagram {
        .merger_box {
            height: 100vh;
        }
    }
}

@media (min-width: 768px){
    .process_diagram{
            display: block;
        }
        .MobileView{
            display: none;
        }
}

@media (max-width: 767px){
    .banner-work{
        .work{
            padding: 75px 0 25px;
            h1{
                font-size: 32px;
            }
        }
    }
    .working{
        padding: 25px 0;
        .imgBox{
            margin-bottom: 25px;
        }
        .work-process{
            h3{
                margin-bottom: 0;
            }
            p{
                margin-top: 8px;
            }
        }
    }
    .diagram{
        padding: 25px 0;
        h4{
            font-size: 20px;
        }
        // .desktopView{
        //    .imgBox{
        //        margin-bottom: 25px;
        //    }
        // }
        // .mobileView{
        //     display: block;
        // }
        // .box{
        //     margin: 5px 0;
        //     &.space{
        //         margin: 0;
        //         max-width: none;
        //     }
        //     &::before{
        //         display: none;
        //     }
        //     .innerBox{
        //         margin: 20px 0;
        //         height: auto;
        //         .contentBox{
        //             h4{
        //                 font-size: 16px;
        //                 margin:8px 0;
        //             }
        //             p{
        //                 margin: 0;
        //                 font-size: 15px;
        //             }
        //         }
        //     }
        //     .owl-theme {
        //         .owl-nav{
        //             display: none;
        //         }
        //         .owl-dots{
        //             .owl-dot{
        //                 &.active{
        //                     span{
        //                         background: #43caf3;
        //                     }
        //                 }
        //                 span{
        //                     margin: 0 5px;
        //                 }
        //             }
        //         }
        //     }
        // }
    }
    .process_diagram{
        display: none;
    }
    .MobileView{
        display: block;
    }
    .subscribe{
        padding: 25px 0;
    }
}

@media screen and (min-width: 719px) and (max-width: 720px){
    .MobileView {
        .merger_box {
            .box{
                &::after{
                    left: 12%;
                }
                &.box3{
                    left: 46%;
                    &::after{
                        left: 11%;
                    }
                }
                &.box1{
                    left: 46%;
                    &::after{
                        left: 9%;
                    }
                }
                &.box4{
                    left: 46%;
                    &::after{
                        left: 11%;
                    }
                }
                &.box2{
                    left: 46%;
                }
            }
        }
    }
}

@media screen and (min-width: 652px) and (max-width: 653px){
    .MobileView {
        .merger_box {
            .box{
                &::after{
                    left: 12%;
                }
                &.box3{
                    left: 46%;
                    &::after{
                        left: 11%;
                    }
                }
                &.box1{
                    left: 46%;
                    &::after{
                        left: 9%;
                    }
                }
                &.box4{
                    left: 46%;
                    &::after{
                        left: 11%;
                    }
                }
                &.box2{
                    left: 46%;
                }
            }
        }
    }
}

@media (max-width: 576px){
    .banner-work{
        padding: 50px 0 0;
        .work{
            padding: 150px 0 100px;
            h1{
                font-size: 24px;
            }
        }
    }
    .faqBanner {
        .work {
          padding: 150px 0 100px;
          h1 {
            font-size: 26px;
          }
        }
    }
    .subscribe {
    text-align: center;
    .subBtn {
        margin-top: 15px;
    }
    }
    .faq-section {
    padding: 25px 0px;
    text-align: center;
    .part-b {
        margin-bottom: 15px;
        p {
        font-size: 16px;
        }
        h3 {
        font-size: 20px;
        }
    }
    }
}

@media screen and (min-width: 539px) and (max-width: 540px){
    .MobileView {
        .merger_box {
            .box{
                &::after{
                    left: 12%;
                }
                &.box3{
                    left: 46%;
                    &::after{
                        left: 11%;
                    }
                }
                &.box1{
                    left: 46%;
                    &::after{
                        left: 9%;
                    }
                }
                &.box4{
                    left: 46%;
                    &::after{
                        left: 11%;
                    }
                }
                &.box2{
                    left: 46%;
                }
            }
        }
    }
}

@media screen and (min-width: 390px) and (max-width: 393px){
    .MobileView {
        .mobileBox{
            max-width: 110px;
        }
        .merger_box {
            .box{
                &::after{
                    left: 12%;
                }
                &.box1{
                    &::after{
                        left: 10%;
                    }
                }
            }
        }
    }
}

@media (max-width: 385px){
    .MobileView {
        .mobileBox{
            max-width: 110px;
        }
        .merger_box {
            .box{
                &.box1,&.box2{
                    left: 43%;
                }
                &::after{
                    left: 15px;
                }
                &.box1{
                    &::after{
                        left: 19px;
                    }
                }
            }
        }
    }
}

@media (max-width: 375px){
    .MobileView {
        .mobileBox{
            max-width: 110px;
        }
        .merger_box {
            .box{
                &.box1,&.box2{
                    left: 43%;
                }
                &::after{
                    left: 15px;
                }
                &.box1{
                    &::after{
                        left: 18px;
                    }
                }
            }
        }
    }
}

@media (max-width: 360px){
    .MobileView {
        .mobileBox{
            max-width: 110px;
        }
        .merger_box {
            .box{
                &.box1,&.box2{
                    left: 43%;
                }
                &::after{
                    left: 13px;
                }
                &.box1{
                    &::after{
                        left: 12%;
                    }
                }
                &.box3{
                    &::after{
                        left: 13px;
                    }
                }
            }
        }
    }
}

@media (max-width: 320px){
    .MobileView {
        .mobileBox{
            max-width: 110px;
        }
        .merger_box {
            .box{
                &::after{
                    left: 9%;
                }
                &.box3{
                    left: 42%;
                    &::after{
                        left: 13%;
                    }
                }
                &.box4{
                    left: 43%;
                    &::after{
                        left: 11%;
                    }
                }
                &.box1{
                    &::after{
                        left: 9%;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 279px) and (max-width: 280px){
    .MobileView {
        .mobileBox{
            max-width: 70px;
        }
        .merger_box {
            .box{
                &::after{
                    left: 12%;
                }
                &.box1{
                    left: 41%;
                    &::after{
                        left: 11%;
                    }
                }
                &.box1{
                    left: 41%;
                }
                &.box3{
                    left: 41%;
                }
                &.box4{
                    left: 41%;
                    &::after{
                        left: 13%;
                    }
                }
            }
        }
    }
}

.accordion {
    .accordion-item {
      /*margin-bottom: 15px;*/
      overflow: hidden;
      /*border-radius: 10px;
      box-shadow: 6px 0px 15px rgba(28, 29, 29, 0.1);
      border: 1px solid #e1e1e1 !important;*/
  
      .accordion-button {
        font-weight: 600;
  
        &.collapsed {
          &::after {
            background-image: url("../../images/plusIcon.png");
          }
        }
  
        &::after {
          background-image: url("../../images/minIcon.png");
        }
      }
  
      .accordion-body {
        padding: 0rem 1.25rem 1rem;
        //   font-weight: 500;
        text-align: left;
      }
    }
  }
  
  .accordion-button:not(.collapsed) {
    color: #212529;
    background-color: #fff;
    box-shadow: none;
  }
  
//   @media (max-width: 1199px) {
//     .subscribe {
//       padding: 40px 0px;
//       padding: 35px 0px;
  
//       p {
//         font-size: 16px;
//       }
//     }
//   }
  
//   @media (max-width: 576px) {
//     .faqBanner {
//       .work {
//         padding: 150px 0 100px;
//         h1 {
//           font-size: 26px;
//         }
//       }
//     }
//     .subscribe {
//       text-align: center;
//       .subBtn {
//         margin-top: 15px;
//       }
//     }
//     .faq-section {
//       padding: 25px 0px;
//       text-align: center;
//       .part-b {
//         margin-bottom: 15px;
//         p {
//           font-size: 16px;
//         }
//         h3 {
//           font-size: 20px;
//         }
//       }
//     }
//   }
  