.add_docu {
  padding: 50px 0;
  background: #f5f5f5;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .content {
    border-radius: 15px;
    box-shadow: 0px 0px 8px #e5e5e5, 5px 5px 8px #e9e9e9;
    padding: 50px 35px;
    background-color: #fff;
    .title {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      position: relative;
      .backArrow {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        a {
          border: 0;
          outline: none;
          background: none;
          display: inline-block;
          color: #020202;
        }
      }
      .heading {
        width: 100%;
        text-align: center;
        h1 {
          font-size: 20px;
          font-weight: 700;
        }
      }
    }
    .form-group {
      margin: 10px 0;
      position: relative;
      .form-select,
      .form-control {
        border-radius: 25px;
        border-color: #a8a8a8;
        color: #a8a8a8;
        font-weight: 500;
        padding: 10px;
        min-height: 40px;
        padding-right: 35px;
        option {
          color: #a8a8a8;
          font-weight: 400;
        }
        &::placeholder {
          color: #a8a8a8;
        }
      }
      .attachIcon {
        position: absolute;
        right: 15px;
        top: 40%;
        transform: translateY(-40%);
        color: #a8a8a8;
        cursor: pointer;
      }
    }
    .addMore {
      text-align: center;
      margin-top: 35px;
      .addBtn {
        color: #838383;
        font-weight: 700;
        span {
          margin-right: 5px;
        }
      }
      .submitBtn {
        display: block;
        margin: 35px auto 0;
        background: linear-gradient(#a0a0a0, #323232);
        color: #fff;
        padding: 7px 75px;
        font-size: 16px;
        border-radius: 25px;
        max-width: 250px;
        width: 100%;
      }
    }
  }
}

@media (max-width: 576px) {
  .add_docu {
    padding: 25px 0;
    .content {
      padding: 25px 10px;
      .addMore {
        margin-top: 20px;
        .submitBtn {
          margin: 15px auto 0;
        }
      }
    }
  }
}
