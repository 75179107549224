.term_Banner{
    background: url(../../images/how_work/works-banner.png) no-repeat center bottom;
    background-size: cover;
    // margin-top: -63px;
    padding: 50px 0 0;

   .work{
    padding: 150px 0;
    text-align: center;
    color: #fff;
    max-width: 500px;
    margin: auto;

    h1{
        font-size: 38px;
        font-weight: 400;
       }
   }       
}
.term-section{
    padding: 50px 0px 20px;
      .para-A{
        margin-bottom: 10px;
        ol{
            padding-left: 1rem;
            li{
                &::marker{
                    color: #4bb1b4;
                    font-weight: 500;
                }
            }
        }
        ul{
            li{
                margin: 0px;
                padding:0px;
                list-style-type: none;
                margin-bottom: 20px;  
            }
        }

       h3{ 
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 10px;
        span{
            display: block;
        }
        }
        p{
        font-weight: 400;
        margin-bottom: 15px;
        }
      }
    h4.ql-align-justify{
        font-size: 16px;
        font-weight: 700;
        color: #000;
        margin-bottom: 8px;
    }
} 
.subscribe {
    background: #000000;
    padding: 50px 0;

    p {
        color: #fff;
        font-weight: 400;
    }

    .subBtn {
        a {
            display: inline-block;
            background: #4bb1b4;
            color: #fff;
            border: 1px solid transparent;
            border-radius: 25px;
            padding: 7px 10px;
            font-size: 15px;
            max-width: 180px;
            width: 100%;
            text-align: center;
        }
    }
}  

@media (max-width: 1399px){
    .term_Banner{
        .work
         h1{
            font-size: 36px;
        }
    }
}

@media (max-width: 1199px){
    .subscribe {
        padding: 35px 0px;
    }
}

@media (max-width: 1024px){
    .term_Banner {
        .work {
            h1{
                font-size: 32px;
            }
        }
    }
}

@media (max-width: 991px){
    .term_Banner {
        padding: 50px 0 0;
    }
}

@media (max-width: 576px){
    .term_Banner {
        .work{
            padding: 150px 0 100px;
            h1 {
                font-size: 24px;
            }
        }
    }
    .subscribe {
        text-align: center;
        padding: 25px 0;
        .subBtn{
            margin-top: 15px;
        }
    }
    .term-section {
        padding: 25px 0px;
        text-align: center;

        .para-A{
            margin-bottom: 15px;
            h3{
                font-size: 20px;
                span{
                    display: inline-block;
                }
            }
            p{
                margin-bottom: 10px;
            }
            ol{
                li{
                    margin: 10px 0px;
                    font-size: 15px;
                    text-align: justify;
                }
            }
            ul{
                li{
                    margin: 10px 0px;
                    font-size: 15px;
                }
            }
        }
    }
}

.submitBtnbtn {
    border-radius: 25px;
    // background: #525252;
    border: 0;
    background: linear-gradient(#a0a0a0, #323232);
    color: #fff;
    padding: 5px 15px;
    max-width: 150px;
    width: 100%;
    font-size: 15px;
  }

  .modal-footer{
    justify-content: center !important;
    padding: 0.5rem !important;
  }

  .modal-header{
    padding: .50rem 1rem !important;
    background: #4bb1b4 !important;
    color: white !important;
  }
  .modal-content{
    border-radius: 0.9rem !important;
  }

