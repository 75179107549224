.report_injury {
  // padding: 50px 0;
  padding: 100px 0 25px;
  background: #f5f5f5;
  min-height: 100%;

  .content {
    border-radius: 15px;
    box-shadow: 0px 0px 8px #e5e5e5, 5px 5px 8px #e9e9e9;
    padding: 50px 45px;
    background-color: #fff;
    .title {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      position: relative;
      .heading {
        width: 100%;
        text-align: center;
        h1 {
          font-size: 20px;
          font-weight: 700;
        }
      }
    }
    .form-group {
      margin: 15px 0 0;
      position: relative;
      z-index: 1;
      .form-select,
      .form-control {
        border-radius: 25px;
        border-color: #a8a8a8;
        color: #000;
        font-weight: 500;
        padding: 10px;
        min-height: 40px;
        background: transparent;
        option {
          color: #000;
          font-weight: 500;
        }
        &::placeholder {
          color: #000000;
        }
        span {
          color: #000;
          font-size: 15px;
        }
      }
      svg {
        position: absolute;
        top: 15px;
        right: 15px;
        fill: #676767;
        z-index: -1;
      }
      .textArea {
        border-radius: 12px;
      }
      &.attachInput{
        border: 1px solid #a8a8a8;
        border-radius: 12px;
        padding: 0 10px;
        .form-control{
          border: none;
          padding: 5px 0 0;
          min-height: auto;
        }
        ul{
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          margin-top: -15px;
          margin-bottom: 5px;
          li{
            margin-right: 5px;
            position: relative;
            img{
              width: 60px;
              border-radius: 5px;
              height: 40px;
              object-fit: cover;
              border: 1px solid #c3c3c3;
            }
            a{
              position: absolute;
              top: -6px;
              right: -2px;
              background: #eb5c3e;
              color: #fff;
              border-radius: 50%;
              width: 15px;
              height: 15px;
              text-align: center;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 12px;
            }
          }
        }
        svg{
          cursor: pointer;
          z-index: 0;
          font-size: 20px;
          top: 8px;
        }
      }
    }
    .btnBox {
      text-align: center;
      margin-top: 20px;
      
      a {
        background: #000;
        color: #fff;
        padding: 8px;
        border-radius: 10px;
        font-weight: 400;
        font-size: 13px;
        margin-right: 10px;
        max-width: 155px;
        width: 100%;
        display: inline-block;
        text-align: center;
      }
    }
  }
}

@media (max-width: 576px) {
  .report_injury {
    .content {
      padding: 15px 10px;
      .form-group {
        &.attachInput {
          margin: 10px auto;
        }
      }
      .btnBox{
        a{
          max-width: 125px;
        }
      }
    }
  }
}
