.privacy_policy {
  padding: 100px 0 25px;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  background-color: #fff;
  align-items: center;

  .content {
    border-radius: 15px;
    padding: 25px;
    border: 1px solid #d6edf4;
    text-align: left;
    .privacyTitle {
      text-align: left;
      position: relative;
    }
    h4 {
      font-weight: 600;
      color: #000000;
      font-size: 18px;
      margin-bottom: 10px;
    }
    .notifyBox {
      .cls-maxheight-scroll{
        overflow: auto !important;
        height: 378px !important;
      }
      p {
        font-weight: 500;
        color: #000;
        margin: 10px 0;
      }
      p{
        &.ql-align-justify{
          a{
            background-color: #fff !important;
            color: #409ca5 !important;
          }
        }
      }
      .time {
        text-align: center;
        a {
          display: inline-block;
          font-size: 15px;
          color: #fff;
          font-weight: 500;
          border-radius: 10px;
          max-width: 185px;
          background: #000;
          width: 100%;
          padding: 10px 15px;
          margin: 20px 0 16px;
        }
      }
    }
  }
}

@media (max-width: 576px){
  .privacy_policy{
    padding: 85px 0 25px;
    text-align: center;
    .content{
      padding: 15px;
      h4{
        margin-bottom: 0;
      }
      .notifyBox{
        .time{
          a{
            margin: 15px 0 0;
          }
        }
      }
      .privacyTitle{
        text-align: center;
      }
    }
  }
}
