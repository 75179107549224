.signupPage {
  padding: 75px 0 25px;
  // background: url(../../images/signup_bg.png) no-repeat center bottom;
  background-size: cover;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .signupForm {
    text-align: center;
    background: #fff;
    /*border: 1px solid #fff;*/
    border-radius: 10px;
    .form-check-input {
      border: 1px solid #9facbf;
		padding-right: 0;
      &:checked {
        background-image: none;
        background: #53b8ba;
        border-color: #53b8ba;
      }
      &:focus {
        box-shadow: none;
      }
    }
    .form-check-label {
      font-weight: 600;
      color: #000;
    }
    .signUpBox {
      text-align: left;
      position: relative;
      .backBtn {
        display: inline-block;
        color: #000000;
        font-weight: 900;
        position: absolute;
        left: 0px;
        top: 2px;
      }
      h3 {
        color: #000000;
        font-weight: bold;
		font-size: 32px;
      }
    }
    .form-group {
      position: relative;
      .form-control {
        /*background-color: #f7f8fc;*/
        // color: #a0a7b9;
        border-radius: 10px;
        border-color: #c2d4da;
        padding-right: 35px;
        min-height: 40px;
        &:focus {
          border-color: #919191;
        }
        &::placeholder{
          color: #a0a7b9;
        }
      }
      span {
        position: absolute;
        top: 40%;
        right: 15px;
        transform: translateY(-40%);
        color: #676767;
      }
    }
    .nextBtn {
      text-align: center;
      margin: 25px 0 0;
      a {
        display: inline-flex;
        margin: 0;
        align-items: center;
        justify-content: center;
        background: #000000;
        color: #fff;
        /*border-radius: 10px;*/
        /*padding: 10px 50px;*/
        /*max-width: 185px;*/
        width: 100%;
        cursor: pointer;
        span {
          margin-left: 5px;
        }
      }
    }
    .alredy_account{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 10px;
      span{
        color: #8da0a9;
        font-size: 15px;
      }
      a{
        color: #000000;
        font-weight: 600;
        font-size: 14px;
        margin-left: 5px;
      }
    }
    h5 {
      color: #000000;
      font-weight: 700;
      text-align: center;
      font-size: 15px;
      margin: 25px 0;
    }
  }
  .backBtn {
    text-align: center;
    button {
      display: inline-block;
      justify-content: center;
      align-items: center;
      background: #fff;
      color: #393939;
      font-weight: 700;
      border-radius: 25px;
      padding: 10px 25px;
      max-width: 185px;
      width: 100%;
      span {
        margin-right: 5px;
      }
    }
  }

  
}
@media(max-width: 1366px){
  .signupPage {
    .signupForm {
      .form-group{
        margin: 15px 0 0;
      }
    }
  }
}

@media (max-width: 1440px){
  .signupPage{
    padding: 85px 0 25px;
  }
}

@media(max-width: 1366px){
  .signupPage {
    .signupForm {
      .form-group{
        margin: 15px 0 0;
      }
    }
  }
}

@media (max-width: 1199px) {
  .signupPage {
    .signupForm {
      .nextBtn {
        button {
          margin: 35px 0 0;
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .signupPage {
    .signupForm {
      h5{
        margin: 25px 0 15px;
      }
      .nextBtn {
        margin: 20px 0;
      }
      .alredy_account{
        margin-top: 0;
      }
    }
  }
}


.form-control.is-invalid, .was-validated .form-control:invalid {
  margin-bottom: 1rem;
  background-image: none;
  border-color: #f93154;
}
.form-control.is-valid, .was-validated .form-control:valid {
  margin-bottom: 1rem;
  background-image: none;
  border-color: #00b74a;
}
.invalid-feedback {
  display: block ; 
  width: 100%;
  // margin-top: 0.25rem;
  margin: -15px 0 0 0px;
  font-size: .875em;
  color: red;
}
$color--error: #F2180A;
$color--success: #11AF41;

  .validation {
    // display: flex;
    flex-direction: column;
    justify-content: space-around;
    box-sizing: border-box;
    margin-bottom: 1em;
  }
  
  .validation-item {
    margin-bottom: 0.85em
  }
  
  .validation-icon {
    display: inline-block;
    margin-right: 0.5em;
    width: 1.25em;
  }
  
  .validator {
    display: flex;
  }
  
  .error {
    @extend .validation-icon;
    color: $color--error;
  }
  
  .success {
    @extend .validation-icon;
    color: $color--success;
  }
  .input-container input {
    border: none;
    box-sizing: border-box;
    outline: 0;
    padding: .75rem;
    position: relative;
    width: 100%;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}