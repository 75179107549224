.job_history {
  padding: 100px 0 25px;
  background: #fff;
  min-height: 30%;
  display: flex;
  justify-content: center;
  align-items: center;

  .content {
    .title {
      h1 {
        font-size: 18px;
        font-weight: 600;
      }
      .form-group {
        position: relative;
        max-width: 185px;
        margin-left: auto;
        .form-control {
          border-radius: 10px;
          border-color: #919191;
          min-height: 30px;
          font-size: 14px;
          &:focus {
            border-color: #919191;
          }
        }
        svg {
          position: absolute;
          top: 10px;
          right: 10px;
          color: #676767;
          fill: #000;
        }
        select {
          color: #000000;
          font-size: 15px;
          font-weight: 500;
          option {
            color: #000000;
            font-size: 15px;
            font-weight: 600;
          }
        }
      }
    }
    .historyBox {
      .border-1{
        margin-top: 15px;
        border: 1px solid #d6edf4;
        border-radius: 8px;
        .table{
          text-align: left;
          margin: 0;
          thead{
            tr{
              border-width: 0;
            }
            th{
              font-size: 14px;
              font-weight: 700;
            }
          }
          tbody{
            border: 1px solid #d6edf4;
            td{
              font-size: 14px;
              font-weight: 500;
            }
            .starRate{
              display: flex;
              svg{
                fill: #facf1e;
              }
            }
            .view_btn{
              display: inline-block;
              font-size: 12px;
              color: #74b6b7;
            }
          }
          a{
            vertical-align: -webkit-baseline-middle;
            color: #000;
            font-weight: 600;
            font-size: 13px;
          }
        }
      }
    }
  }
}

