.terms_use_agreement {
    padding: 75px 0 25px;
    // background: url(../../images/home_bg.png) no-repeat center bottom;
    background-size: cover;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
  
    .content {
      border-radius: 15px;
      padding: 25px;
      border:1px solid #d6edf4;
      margin-top: 25px;
      .privacyTitle {
        text-align: left;
        position: relative;
      }
      h4 {
        text-align: left;
        font-weight: 700;
        color: #000000;
        font-size: 16px;
        margin-bottom: 20px;
      }
      .notifyBox {
        text-align: left;
        .cls-maxheight-scroll {
          overflow: auto !important;
          height: 378px !important;
        }
        p {
          font-size: 14px;
          font-weight: 500;
          color: #000;
          margin: 10px 0;
        }
        .time {
          text-align: center;
          a {
            display: inline-block;
            font-size: 15px;
            color: #fff;
            font-weight: 500;
            border-radius: 10px;
            max-width: 185px;
            width: 100%;
            background: #000;
            padding: 10px 15px;
            margin: 20px 0 16px;
          }
        }
      }
    }
  }
  
  @media (max-width: 576px){
    .terms_use_agreement{
      .content{
        padding: 20px;
        h4{
          margin-bottom: 0px;
          text-align: center;
        }
        .notifyBox{
          .time{
            a{
              margin: 15px 0 0;
            }
          }
        }
      }
    }
  }