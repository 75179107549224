.registrationPage {
  padding: 100px 0 25px;
  // background: url(../../images/signup_bg.png) no-repeat center bottom;
  background-size: cover;

  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .regForm {
    text-align: center;
    margin: 0;
    background: #fff;
    border: 1px solid #fff;
    border-radius: 10px;
    padding: 0;
    .regBox {
      border-bottom: none;
      position: relative;
      h4 {
        color: #000000;
        font-weight: 600;
        color: #000;
        text-align: center;
      }
      .editDetail {
        position: absolute;
        left: 0;
        top: 2px;
        display: flex;
        align-items: center;
        background: #000;
        border-radius: 5px;
        a {
          display: inline-block;
          align-items: center;
          justify-content: center;
          color: #fff;
          font-weight: 500;
          font-size: 11px;
          padding: 5px 8px;
          span{
            margin-left: 5px;
          }
          i{
            color: #fff;
          }
        }
      }
    }
    .avatar {
      margin: 25px 0;
      border-bottom: 1px solid #dbdbdb;
      padding-bottom: 20px;
      .profile-select {
        opacity: 0;
        height: 120px;
        position: absolute;
        width: 130px;
        margin: -10px 0 0 25px;
        cursor: pointer;
        z-index: 999999;
      }
      .profileImg-Img {
        text-align: center;
        position: relative;
        width: 100px;
        height: 100px;
        margin: auto;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        margin-bottom: 25px;
        line-height: 80px;
      }
      .profileImg {
        cursor: pointer;
        text-align: center;
        position: relative;
        width: 90px;
        height: 90px;
        margin: auto;
        border-radius: 50%;
        border: 2px solid #deedf1;
        background: #f5f9fc;
        display: flex;
        justify-content: center;
        margin-bottom: 25px;
        line-height: 75px;
        img{
          width: 90px;
          height: 90px;
          border-radius: 50%;
          object-fit: cover;
        }
        span {
          display: inline-block;
          color: #cecece;
          font-size: 35px;
        }
        .icon {
          position: absolute;
          right: -10px;
          bottom: 0px;
          .camIcon {
            border: 1px solid #898989;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #000;
            span {
              color: #fff;
              display: inline-block;
              font-size: 14px;
              line-height: 0;
            }
          }
        }
      }
    }
    h5 {
      // text-align: left;
      font-size: 15px;
      font-weight: 600;
      position: relative;
      text-align: left;
      // &::after {
      //   content: "";
      //   position: absolute;
      //   width: 50%;
      //   height: 1px;
      //   background: #5e5e5e;
      //   display: inline-block;
      //   top: 50%;
      //   left: 50%;
      //   transform: translateY(-50%, -50%);
      // }
      &.security_reg{
        min-height: 40px;
        margin-bottom: 8px;
        font-size: 14px;
        display: flex;
        align-items: center;
      }
    }
    .form-group {
      margin: 8px 0;
    }
  }
  .profileBox {
    // border: 1px solid #b5b5b5;
    border-radius: 10px;
    // padding: 0 20px 0 20px;
    margin: 10px 0px;
  }
  .profileHeading {
    margin: 20px 0 0 0;
    // text-decoration: underline;
    position: relative;
  }
  .identityBox {
    .preTitle {
      position: relative;
      margin: 15px 0;
      h5 {
        text-align: left;
        font-size: 16px;
        font-weight: 700;
        position: relative;
        &::after {
          content: "";
          position: absolute;
          width: 50%;
          height: 1px;
          background: #5e5e5e;
          display: inline-block;
          top: 50%;
          left: 50%;
          transform: translateY(-50%, -50%);
        }
      }
      .form-group {
        margin: 8px 0;
      }
    }
    .identityBox {
      .preTitle {
        position: relative;
        margin: 15px 0;
        h5 {
          // text-align: left;
          font-size: 16px;
          font-weight: 700;
          text-align: center;
          // &::after {
          //   content: "";
          //   position: absolute;
          //   width: calc(100% - 160px);
          //   height: 2px;
          //   background: #5e5e5e;
          //   display: inline-block;
          //   top: 12px;
          //   right: 0;
          // }
        }
      }
      .identityTitle {
        position: relative;
        margin-bottom: 15px;
        h5 {
          // text-align: left;
          font-weight: 700;
          font-size: 18px;
          color: #000;
          text-align: center;
        }
      }
    }
    .editDetail {
      position: absolute;
      top: -3px;
      right: 0;
      a {
        color: #484848;
        display: inline-flex;
        align-items: center;
        padding: 0;
        line-height: 0;
        svg {
          margin-right: 3px;
          font-size: 14px;
        }
      }
    }
    .input-group {
      border: none;
      outline: none;
      margin: 15px 0;
      .form-select {
        border: none;
        background-image: none;
        padding-top: 15px;
        padding-bottom: 0;
        font-weight: 700;
        font-size: 16px;
        color: #000;
        option {
          font-weight: 700;
          font-size: 16px;
          color: #000;
        }
      }
      .addBtn {
        padding-top: 15px;
        padding-bottom: 0;
        color: #a0a1a2;
        font-weight: 600;
        &:focus {
          box-shadow: none;
        }
      }
    }
    .form-group {
      position: relative;
      margin: 8px 0;
      .form-control,
      .form-select {
        border-radius: 10px;
        background: #f7f8fc;
        border-color: #ddecf0;
        border-width: 2px;
        color: #292a2e;
        // padding-right: 35px;
        padding-right: 30px;
        min-height: 40px;
        &:focus {
          border-color: #919191;
        }
      }
      span {
        position: absolute;
        top: 40%;
        right: 15px;
        transform: translateY(-40%);
        color: #676767;
        // background: aliceblue;
      }

      .update_files{
        border: 1px solid;
        display: block;
        text-align: center;
        border-radius: 10px;
        background: #f7f8fc;
        border-color: #ddecf0;
        border-width: 2px;
        color: #292a2e;
        padding-right: 30px;
        // min-height: 40px;
        position: relative;
        // line-height: 40px;
        cursor: pointer;
    }
    }

    .radioBtn {
      margin: 8px 0;
      display: flex;
      align-items: center;
      padding: 12px 0;
      .form-check {
        padding-left: 0;
      }
      .label {
        font-weight: 700;
      }
      .form-check-inline {
        margin-left: 45px;
        margin-right: 0;
        .form-check-input {
          border: 1px solid #000000;
          &:checked {
            background-image: none;
            background: #000000;
          }
          &:focus {
            box-shadow: none;
          }
        }
        .form-check-label {
          font-weight: 600;
          color: #000;
        }
      }
    }
    .dateFormat {
      .form-group {
        .form-control {
          padding-right: 12px;
        }
      }
    }
  }

  .nextBtn {
    margin: 50px 0 0;
    text-align: center;
    a, button {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: #fff !important;
      background: #000;
      border-radius: 10px;
      padding: 10px 60px;
      cursor: pointer;
      font-size: 15px;
      span {
        margin-left: 5px;
      }
    }
  }
}

.identityTitle {
  position: relative;
}

::-webkit-file-upload-button {
  display: none;
}

.identityBox {
  .identificationDocument {
    border-radius: 10px;
    .identiBox {
      padding: 0;
      display: flex;
      width: 100%;
      align-items: center;
      &:last-child {
        border: none;
      }

      .closeBtn {
        cursor: pointer !important;
        margin-left: 5px;
        line-height: 10px;
        padding: 5px;
      }
      .doc_upload{
        display: flex;
        flex-wrap: wrap;
        li{
          display: flex;
          align-items: center;
          border: 1px solid #d6edf4;
          border-radius: 10px;
          padding: 5px;
          margin: 0 5px 8px 0;
          .imgBox{
            width: 85px;
            height: 70px;
            border: 1px solid #fff;
            border-radius: 10px;
            overflow: hidden;
            margin-right: 5px;
            img{
              height: 100%;
            }
          } 
          .content{
            text-align: left;
            h6{
              font-size: 13px;
              color: #000;
            }
            span{
              font-size: 13px;
              color: #868b97;
              font-weight: 500;
            }
          }
        }
      }
      &.securityBox{
        .form-group{
          margin: 0 0 8px;
        }
      }
    }
    .border-1{
      border: 1px solid #d6edf4;
      border-radius: 8px;
      .table{
        text-align: left;
        margin: 0;
        thead{
          tr{
            border-width: 0;
          }
          th{
            font-size: 14px;
            font-weight: 500;
          }
        }
        tbody{
          border: 1px solid #d6edf4;
          td{
            font-size: 15px;
          }
        }
        a{
          vertical-align: -webkit-baseline-middle;
          color: #000;
        }
        .form-group{
          margin: 0;
          .form-control,.form-select{
            border: none;
            background: #f7f8fc;
            border-radius: 5px;
            padding: 0 10px;
            margin: 0;
            font-size: 13px;
            min-height: 28px;
          }
        }
      }
    }
    
  }
  &.borderT{
    margin-top: 10px;
    border-top: 1px solid #d6edf4;
  }
  .identity_docs{
    display: flex;
    border: 1px solid #d6edf4;
    border-radius: 10px;
    padding: 5px;
    .imgBox{
      width: 135px;
      max-width: 135px;
      height: 70px;
      border: 1px solid #fff;
      border-radius: 10px;
      overflow: hidden;
      margin-right: 5px;
      img{
        height: 100%;
      }
    } 
    .content{
      text-align: left;
      width: 100%;
      h6{
        font-size: 13px;
        color: #000;
      }
      span{
        font-size: 13px;
        color: #868b97;
        font-weight: 500;
      }
    }
  }
}

@media (max-width: 576px) {
  .registrationPage {
    .regForm {
      padding: 0;
      .avatar{
        margin: 20px 0 0;
      }
      h5{
        &.security_reg{
          min-height: 48px;
          margin: 0;
          font-size: 13px;
        }
      }
      .regBox {
        .editDetail{
          a{
            padding: 6px 12px;
            line-height: 0;
            span{
              display: none;
            }
          }
        }
      }
    }
    .profileBox{
      margin: 0;
    }
    .nextBtn{
      margin: 25px 0 0;
    }
    .identityBox{
      .form-group {
        .form-control{
          text-overflow: ellipsis;
        }
        span{
          right: 10px;
        }
      }
    }
  }
  .identityBox{
    .identificationDocument{
      margin-top: 15px;
      h5{
        margin: 10px 0 18px; 
      }
      .border-1 {
        .table {
          .form-group {
            .form-select#sSelect-0{
              padding: 0 25px 0 10px;
            }
          }
        }
      }
    }
  }
}
.input-container input {
  border: none;
  box-sizing: border-box;
  outline: 0;
  padding: .75rem;
  position: relative;
  width: 100%;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}
.closeBtn {
  cursor: pointer !important;
}

.table-responsive.border-1{
  table{
    width: calc(100% - .1px);

    tbody{
      border-left: 0 !important;
      border-bottom: 1px solid #fff !important;
      tr{
        td{
          &:first-child{
            border-left: 0;
          }
        }
      }
    }
    th{
      width: 20%;
      &:first-child{
        border-left: 0;
      }
      &:last-child{
        border-right: 0;
      }
    }
  }
  &::-webkit-scrollbar-track {
    // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }
  
  &::-webkit-scrollbar {
    width: 8px;
    height: 6px;
    background-color: #f5f5f5;
  }
  
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #000;
  }
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef !important;
  opacity: 1;
}

