.contract_agreement {
    padding: 25px 0;
    background: url(../../images/home_bg.png) no-repeat center bottom;
    background-size: cover;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  
    .content {
      border-radius: 15px;
      padding: 25px;
      background-color: #fff;
      margin-top: 25px;
      .privacyTitle {
        text-align: left;
        position: relative;
        .backBtn {
          display: inline-block;
          color: #000000;
          font-weight: 900;
          position: absolute;
          left: 0px;
          top: 2px;
        }
      }
      h4 {
        text-align: center;
        font-weight: 700;
        color: #000000;
        font-size: 20px;
        margin-bottom: 20px;
      }
      .notifyBox {
        text-align: center;
        .cls-maxheight-scroll {
          overflow: auto !important;
          height: 378px !important;
        }
        p {
          font-size: 16px;
          font-weight: 500;
          color: #000;
          margin: 10px 0;
        }
        .time {
          a {
            display: inline-block;
            font-size: 15px;
            color: #fff;
            font-weight: 500;
            border-radius: 25px;
            max-width: 185px;
            width: 100%;
            background: linear-gradient(#a0a0a0, #323232);
            padding: 10px 15px;
            margin: 20px 0 16px;
            cursor: pointer;
          }
        }
      }
    }
  }
  
  @media (max-width: 576px){
    .contract_agreement{
      .content{
        h4{
          margin-bottom: 0;
        }
      }
    }
  }
  