.faqBanner {
  background: url(../../images/how_work/works-banner.png) no-repeat center
    bottom;
  background-size: cover;
  padding: 50px 0 0;

  .work {
    padding: 150px 0;
    text-align: center;
    color: #fff;
    // max-width: 500px;
    margin: auto;

    h1 {
      font-size: 38px;
      font-weight: 400;
    }
  }
}
.faq-section {
  padding: 50px 0px 35px;
  .part-b {
    // margin-bottom: 15px;
    margin: 10px 0 10px 15px;
    p {
      font-size: 18px;
      font-weight: 400;
    }

    h3 {
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 10px;
    }
  }
}
.subscribe {
  background: #000000;
  padding: 50px 0;

  p {
    color: #fff;
    font-weight: 400;
    font-size: 18px;
  }

  .subBtn {
    a {
      display: inline-block;
      background: #4bb1b4;
      color: #fff;
      border: 1px solid transparent;
      border-radius: 25px;
      padding: 7px 10px;
      font-size: 15px;
      max-width: 180px;
      width: 100%;
      text-align: center;
    }
  }
}

.accordion {
  .accordion-item {
    /*margin-bottom: 15px;*/
    overflow: hidden;
    /*border-radius: 10px;
    box-shadow: 6px 0px 15px rgba(28, 29, 29, 0.1);
    border: 1px solid #e1e1e1 !important;*/

    .accordion-button {
      font-weight: 600;

      &.collapsed {
        &::after {
          background-image: url("../../images/plusIcon.png");
        }
      }

      &::after {
        background-image: url("../../images/minIcon.png");
      }
    }

    .accordion-body {
      padding: 0rem 1.25rem 1rem;
      //   font-weight: 500;
      text-align: left;
    }
  }
}

.accordion-button:not(.collapsed) {
  color: #212529;
  background-color: #fff;
  box-shadow: none;
}

@media (max-width: 1199px) {
  .subscribe {
    padding: 40px 0px;
    padding: 35px 0px;

    p {
      font-size: 16px;
    }
  }
}

@media (max-width: 576px) {
  .faqBanner {
    .work {
      padding: 150px 0 100px;
      h1 {
        font-size: 26px;
      }
    }
  }
  .subscribe {
    text-align: center;
    .subBtn {
      margin-top: 15px;
    }
  }
  .faq-section {
    padding: 25px 0px;
    text-align: center;
    .part-b {
      margin-bottom: 15px;
      p {
        font-size: 16px;
      }
      h3 {
        font-size: 20px;
      }
    }
  }
}
