.profilePic {
  padding: 100px 0 25px;
  display: flex;
  justify-content: center;
  background: #fff;
  min-height: 100vh;
  .otpForm {
    text-align: center;
    border: 1px solid #d6edf4;
    box-shadow: 0 0px 15px 0px rgba(133, 133, 133,0.1);
    border-radius: 10px;
    padding: 35px;
    margin-top: 15px;
    .title {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      position: relative;
      .backArrow {
        position: absolute;
        right: -20px;
        top: -30px;
        a {
          border: 0;
          outline: none;
          background: none;
          color: #000;
          font-size: 20px;
        }
      }
      .heading {
        width: 100%;
        text-align: center;
        h1 {
          font-size: 20px;
          font-weight: 600;
        }
      }
    }
    h4 {
      color: #000000;
      font-weight: 700;
      font-size: 20px;
      color: #000;
      margin-bottom: 25px;
    }
    .avatar {
      .profile-select {
        opacity: 0;
        height: 120px;
        position: absolute;
        width: 130px;
        margin: -10px 0 0 25px;
        cursor: pointer;
        z-index: 999999;
      }
      .profileImg-Img {
        text-align: center;
        position: relative;
        width: 100px;
        height: 100px;
        margin: auto;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        margin-bottom: 25px;
        line-height: 80px;
      }
      .profileImg {
        text-align: center;
        position: relative;
        width: 90px;
        height: 90px;
        margin: auto;
        border-radius: 50%;
        border: 2px solid #deedf1;
        background: #f5f9fc;
        display: flex;
        justify-content: center;
        margin-bottom: 25px;
        line-height: 0px;
        span {
          display: inline-block;
          color: #cecece;
          font-size: 35px;
        }
        .icon {
          position: absolute;
          right: -10px;
          bottom: 0px;
          .camIcon {
            border: 1px solid #898989;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #000;
            color: #44a0a8;
            span {
              color: #fff;
              display: inline-block;
              font-size: 14px;
              line-height: 0;
            }
          }
        }
      }
    }
    .form-group {
      position: relative;
      margin: 8px 0;
      .form-control {
        background-color: #f7f8fc;
        color: #474747;
        border-radius: 10px;
        border-color: #c2d4da;
        padding-right: 35px;
        min-height: 40px;
        &:focus {
          border-color: #919191;
        }
        &::placeholder{
          color: #a0a7b9;
        }
      }
      span {
        position: absolute;
        top: 40%;
        right: 15px;
        transform: translateY(-40%);
        color: #676767;
      }
    }

    .license_upload_imgBox{
        background-color: #fff;
        color: #474747;
        border-radius: 10px;
        border: 1px solid #c2d4da;
        height: 85px;
        overflow: hidden;

        img{
          // width: 100%;
          height: 100%;
          // object-fit: cover;
        }
    }

    .agreeBtn {
      text-align: center;
      button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: #000;
        color: #fff;
        border-radius: 10px;
        padding: 10px 15px;
        max-width: 185px;
        width: 100%;
        font-size: 14px;
        margin-top: 35px;
        span {
          margin-left: 5px;
          display: inline-block;
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .profilePic {
    .otpForm {
      margin: 0;
      padding: 25px 10px;
      .title{
        .backArrow{
          right: 0;
          top: -25px;
        }
      }
      .agreeBtn{
        button{
          margin-top: 25px;
        }
      }
    }
  }
}
