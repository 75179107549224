.agreePage {
  padding: 100px 0 25px;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;

  .agreeForm {
    text-align: center;
    border: 1px solid #fff;
    border-radius: 10px;
    padding: 35px;
    h4 {
      color: #000000;
      font-weight: 700;
      font-size: 20px;
      color: #000;
      margin-bottom: 25px;
    }
    .agreeBtn {
      text-align: center;
      button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(#a0a0a0, #323232);
        color: #fff;
        border-radius: 25px;
        padding: 8px 30px;
        font-weight: 500;
        max-width: 185px;
        width: 100%;
        span {
          margin-left: 5px;
        }
      }
    }
  }
  .backLogin {
    margin: 20px 0 0;
    text-align: center;
    button {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(#a0a0a0, #323232);
      color: #fff;
      border-radius: 25px;
      padding: 8px 30px;
      font-weight: 400;
      max-width: 185px;
      width: 100%;
      span {
        margin-left: 5px;
      }
    }
  }
}

@media (max-width: 576px){
  .agreePage {
    .agreeForm {
      padding: 25px 10px;
      margin: 25px 0 0;
      h4{
        font-size: 18px;
      }
    }
  }
}