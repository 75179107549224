.banner-training {
    background: url(../../images/how_work/training_bg.png) no-repeat center bottom;
    background-size: cover;
    padding: 50px 0 0;

    .workBox {
        padding: 150px 0;
        color: #fff;
        text-align: center;
        h1 {
            font-size: 38px;
            font-weight: 400;
        }
        p{
            margin-top: 10px;
            font-weight: 300;
            font-size: 20px;
        }
        .form-group {
            margin: 10px 0;
            position: relative;
            .form-control {
              border-radius: 25px;
              border-color: #111111;
              color: #a8a8a8;
              font-weight: 400;
              padding: 10px;
              &::placeholder {
                color: #a8a8a8;
              }
              &.textArea {
                border-radius: 12px;
              }
            }
          }
          .reachForm{
              text-align: center;
              background: #fff;
              padding: 20px;
              border-radius: 6px;
              max-width: 500px;
              margin: auto;
              width: 100%;
              h4{
                  font-size: 24px;
                  color: #000;
                  font-weight: 500;
                  margin-bottom: 20px;
              }
              .submitBtn{
                  a{
                    display: block;
                    background: #4bb1b4;
                    color: #fff;
                    border: 1px solid transparent;
                    border-radius: 25px;
                    margin: 25px 25px 0;
                    padding: 7px 10px;
                    font-size: 15px;
                    text-align: center;
                  }
              }
          }
    }
}
.contactForm{
    padding: 50px 0;
    .leftBox{
        h3{
            font-size: 24px;
            font-weight: 700;
            color: #000;
            margin-bottom: 10px;
        }
        p{
            font-weight: 400;
            color: #000;
        }
        h6{
            font-size: 14px;
            font-weight: 700;   
            color: #000;
            margin: 20px 0 5px;
        }
        span{
            display: inline-block;
            font-weight: 500;
            color: #000;
        }
    }
    .form-group {
        margin: 10px 0;
        position: relative;
        .form-control {
          border-radius: 25px;
          border-color: #111111;
          color: #000;
          font-weight: 400;
          padding: 5px 10px;
          &::placeholder {
            color: #a8a8a8;
          }
          &.textArea {
            border-radius: 12px;
          }
        }
      }
    .reachForm{
        text-align: center;
        background: #fff;
        box-shadow: 0px 5px 15px 0px rgba(28, 29, 29, 0.21);
        padding: 20px;
        border-radius: 6px;
        h4{
            color: #000;
            font-size: 24px;
            font-weight: 500;
            margin-bottom: 20px;
        }
        .submitBtn{
            a{
                background: #4bb1b4;
                color: #fff;
                border: 1px solid transparent;
                border-radius: 25px;
                padding: 5px 35px;
                font-size: 15px;
                display: inline-block;
                margin-top: 10px;
            }
        }
    }
}
.trainingSec {
    background: #f9f9f9;
    .workGuard {
        padding: 50px 0;

        .imgBox {
            text-align: right;
            padding-right: 50px;

            img {
                width: 600px;
                height: 400px;
                object-fit: cover;
                // border-radius: 50%;
            }
        }

        .content {
            h3 {
                font-size: 24px;
                font-weight: 700;
            }

            p {
                margin: 10px 0;
                font-weight: 400;
            }

            a {
                display: inline-block;
                background: #4bb1b4;
                color: #fff;
                border: 1px solid transparent;
                border-radius: 25px;
                padding: 7px 10px;
                font-size: 15px;
                max-width: 180px;
                width: 100%;
                text-align: center;
            }
            ul{
                li{
                    p{
                        font-weight: 500;
                        svg{
                            fill: #4bb1b4;
                        }
                    }
                }
            }
        }
    }
    &.employee{
        background: #fff;
        .workGuard{
            .content{
                text-align: right;
            }
            .imgBox{
                text-align: left;
                margin-left: 40px;
            }
        }
    }
}
.scholarship{
    background: #f9f9f9;
    padding: 50px 0;
    text-align: center;
    h3 {
        font-size: 24px;
        font-weight: 700;
    }

    p {
        margin: 20px 0;
        font-weight: 400;
    }
}

@media (max-width: 1024px){
    .banner-training {
        .work {
            h1{
                font-size: 32px;
            }
            p{
                font-size: 16px;
            }
        }
    }
}

@media (max-width: 991px){
    .secProfe {
        ul{
            margin: 15px 0 0;
            li{
                margin: 10px 5px;
            }
        }
    }
    .trainingSec{
        .workGuard{
            .imgBox{
                img{
                    height: 315px;
                }
            }
        }
    }
}
@media (max-width: 576px){
    .banner-training {
        padding: 50px 0 0;
        .workBox{
            padding: 145px 0 75px;
            text-align: center;
            h1{
                font-size: 24px;
            }
            p{
                font-size: 15px;
            }
            .reachForm{
                margin-top: 20px;
            }
        }
    }
    .contactForm{
        padding: 25px 0;
        .reachForm{
            margin-left: auto;
            margin-right: auto;
            max-width: none;
        }
    }
    .trainingSec{
        .workGuard{
            padding: 25px 0;
            text-align: center;
            .content{
                h3{
                    font-size: 20px;
                }
                ul{
                    li{
                        p{
                            margin: 10px 0 0;
                        }
                    }
                }
            }
            .imgBox{
                padding-right: 0;
                text-align: center;
                margin-bottom: 15px;
                img{
                    height: auto;
                }
            }
        }
        &.employee{
            .workGuard{
                .content{
                    text-align: center;
                }
                .imgBox{
                    text-align: center;
                    margin-left: 0;
                    margin-right: 0;
                    padding: 0;
                }
            }
        }
    }
    .scholarship{
        padding: 25px 0;
        p{
            margin: 10px 0 0;
        }
    }
}