.navbar {
  background-color: #fff;
  box-shadow: 0 0px 15px 0px rgba(133, 133, 133, 0.1);
  .container-fluid {
    margin: 0 8%;

    .navbar-brand {
      img {
        max-height: 50px;
      }
    }

    .navbar-collapse {
      .navbar-nav {
        align-items: center;
        .nav-item {
          .nav-link {
            color: #000;
            padding-right: 1rem;
            padding-left: 1rem;

            span {
              position: relative;
              top: -1px;
              margin-right: 5px;
            }
            &.hamburger {
              cursor: pointer;
              padding: 0px 35px 16px 10px;
              display: inline-block;
              position: relative;
              span {
                margin: 0;
                top: 10px;
                cursor: pointer;
                border-radius: 1px;
                height: 3px;
                width: 20px;
                background: #4e4e4e;
                position: absolute;
                display: inline-block;
                content: '';
                transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1.000);
                &:before {
                  cursor: pointer;
                  border-radius: 1px;
                  height: 2px;
                  width: 20px;
                  background: #4e4e4e;
                  position: absolute;
                  display: inline-block;
                  content: '';
                  top: -6px;
                  transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1.000);
                }
                &:after {
                  cursor: pointer;
                  border-radius: 1px;
                  height: 3px;
                  width: 20px;
                  background: #4e4e4e;
                  position: absolute;
                  display: inline-block;
                  content: '';
                  bottom: -6px;
                  transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1.000);
                }
              }
              &.active{
                span {
                  background-color: transparent;
                  &:before {
                    top: 0;
                    transform: rotate(45deg);
                    height: 3px;
                  }
                  &:after {
                    top: 0;
                    transform: translateY(-10px) rotate(-45deg);
                    top: 10px;
                  }
                }
              }
            }
            &.notifi_icon{
              position: relative;
              .alert_message{
                position: absolute;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background: #40a2a8;
                top: 5px;
                right: 10px;
                text-align: center;
                font-size: 7px;
                display: inline-block;
                color: #fff;
              }
            }

          }

          .dropdown-toggle {
            &::after {
              display: none;
            }
          }
          .dropdown-menu {
            left: auto;
            right: 0;
            min-width: 450px;
            width: 100%;
            padding: 0;
            flex-wrap: wrap;
            justify-content: space-between;
            top: 60px;
            box-shadow: 0 0px 15px 10px rgba(133, 133, 133, 0.1);
            border: 0;

            &.show {
              display: flex;
            }

            li {
              width: 33.33%;
              text-align: center;
              padding: 20px 0;

              .dropdown-item.active,
              .dropdown-item:hover,
              .dropdown-item:active,
              .dropdown-item:focus,
              .dropdown-item:hover {
                background: #fff;
              }
              .dropdown-item {
                span {
                  width: 50px;
                  height: 50px;
                  border-radius: 50%;
                  background: #01b399;
                  display: block;
                  color: #fff;
                  text-align: center;
                  line-height: 45px;
                  margin: 0 auto 5px;
                }

                font-size: 16px;
                color: #000;
                font-weight: 600;
              }
              .nav-link{
                &:hover{
                  .right_sidebar{
                    &.add_sidebar{
                      display: block;
                    }
                  }
                }
              }
            }

            .logout {
              width: 100%;
              text-align: center;
              margin-bottom: 20px;
              button {
                border: 1px solid #ff342f;
                color: #ff342f;
                font-weight: 600;
                padding: 5px 10px;
                border-radius: 30px;
                max-width: 145px;
                width: 100%;
              }
            }
          }

          .userImg {
            display: block;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            overflow: hidden;
            border: 2px solid #06868c;
            margin: 0;

            img {
              width: 25px;
              height: 25px;
              object-fit: cover;
            }
          }
        }
      }
    }
  }
  .right_sidebar{
    display: none;
    position: absolute;
    opacity: 0;
    right: 0;
    top: 77px;
    max-width: 275px;
    width: 100%;
    height: calc(100vh - 85px);
    background: #fff;
    box-shadow: 0 0px 15px 10px rgba(133, 133, 133, 0.1);
    overflow-y: auto;
    transition: all 0.1s ease-in-out;
    .sidebar_box{
      padding: 20px;
      .sidebar_menu {
        &.show {
          display: flex;
        }

        li {
          padding: 10px 0;
          background: #fff;

          .sidebar-item.active,
          .sidebar-item:hover,
          .sidebar-item:active,
          .sidebar-item:focus,
          .sidebar-item:hover {
            background: #fff;
          }
          .sidebar-item {
            span {
              width: 40px;
              height: 40px;
              border-radius: 50%;
              background: #e4f8f7;
              display: inline-block;
              color: #01b399;
              text-align: center;
              line-height: 37px;
              margin-right: 10px;
            }

            font-size: 13px;
            color: #000;
            font-weight: 600;
          }
        }

        .logout {
          width: 100%;
          margin: 15px 0 10px;
          button {
            border: 1px solid #ff342f;
            color: #ff342f;
            font-weight: 600;
            padding: 5px 8px;
            border-radius: 30px;
            max-width: 125px;
            width: 100%;
            font-size: 13px;
          }
        }
      }
    }
    &.add_sidebar{
      display: block;
      opacity: 1;
    }
  }
  // .nav-item{
  //   .nav-link{
  //     &:hover{
  //       .right_sidebar{
  //         display: block;
  //         &.add_sidebar{
  //           display: block;
  //         }
  //       }
  //     }
  //   }
  // }
}

@media (max-width: 1024px){
  .navbar{
    .container-fluid{
      .navbar-collapse{
        .navbar-nav{
          .nav-item {
            .nav-link{
              font-size: 14px;
            }
            .dropdown-menu{
              min-width: 300px;
              li{
                padding: 10px 0;
                .dropdown-item{
                  font-size: 13px;
                  span{
                    width: 40px;
                    height: 40px;
                    line-height: 36px;
                  }
                }
              }
              .logout{
                button{
                  max-width: 125px;
                  font-size: 15px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media(max-width: 991px){
  .navbar-light{
    .navbar-toggler{
      border: none;
      &:focus{
        box-shadow: none;
      }
    }
    .navbar-toggler-icon{
      width: 1em;
      height: 1em;
    }
  }
  .navbar {
    .container-fluid{
      margin: 0;

      .navbar-collapse {
        .navbar-nav {
          display: block;

          .nav-item {
            .dropdown-menu {
              box-shadow: none;

              &.show{
                  display: block !important;
              }

              li {
                width: 100%;
                text-align: left;
                padding: 0 0 10px;
                .dropdown-item{
                  font-weight: 400;
                  span{
                    display: none;
                  }
                }
              }
              .logout{
                text-align: left;
                button{
                  border: none;
                    font-weight: 400;
                    padding: 0.25rem 1rem;
                    border-radius: 30px;
                    width: 100%;
                    text-align: left;
                    color: #000;
                    font-size: 13px;
                }
              }
            }
            .nav-link{
              &.hamburger{
                padding: 0px 35px 20px 16px;
                span{
                  &::before{
                    height: 3px;
                  }
                }
              }
              &.notifi_icon{
                .alert_message{
                  left: 23px;
                }
              }
            }
          }
        }
      }
    }
  }
}
