.on_job {
  padding: 50px 0;
  background: #f5f5f5;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .content {
    border-radius: 15px;
    box-shadow: 0px 0px 8px #e5e5e5, 5px 5px 8px #e9e9e9;
    padding: 50px 45px;
    background-color: #fff;
    .title {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      position: relative;
      .heading {
        width: 100%;
        text-align: center;
        h1 {
          font-size: 20px;
          font-weight: 700;
        }
      }
    }
    .form-group {
      margin: 10px 0;
      position: relative;
      .form-select,
      .form-control {
        border-radius: 25px;
        border-color: #a8a8a8;
        color: #000;
        font-weight: 500;
        padding: 10px;
        min-height: 40px;
        option {
          color: #000;
          font-weight: 500;
        }
        &::placeholder {
          color: #000000;
        }
        span {
          color: #000;
        }
      }
      svg {
        position: absolute;
        top: 17px;
        right: 15px;
        color: #676767;
        fill: #000;
      }
      .addBtn {
        position: absolute;
        display: inline-block;
        right: -40px;
        top: 0px;
      }
      .textArea {
        border-radius: 12px;
      }
    }
    .job_box {
      .nav {
        justify-content: center;
        .nav-item {
          margin: 0 3px;
          .nav-link {
            background: #fff;
            color: #000;
            border: 1px solid #505050;
            border-radius: 25px;
            max-width: 150px;
            width: 100%;
            padding: 5px 15px;
            display: flex;
            align-items: center;
            svg {
              fill: #000;
              margin-right: 6px;
            }
            &.active {
              background: linear-gradient(#a0a0a0, #323232);
              color: #fff;
              svg {
                fill: #fff;
                margin-right: 6px;
              }
            }
          }
        }
      }
    }
    .tab-content {
      margin: 25px 0;
      h4 {
        font-size: 20px;
        font-weight: 600;
        color: #020202;
        text-align: center;
        margin-bottom: 25px;
      }
      .box {
        border: 1px solid #d7d7d7;
        padding: 8px 15px;
        border-radius: 12px;
        margin: 10px 0;
        .descBox {
          display: flex;
          flex-direction: column;
          min-height: 100px;
          h6 {
            font-weight: 700;
            color: #020202;
            font-size: 15px;
          }
          a {
            display: flex;
            align-items: center;
            color: #020202;
            margin: 15px 0 0;
            span {
              margin-left: 25px;
              display: inline-block;
              border: 2px solid #d5d5d5;
              width: 100%;
              position: relative;
              &::before {
                content: "";
                position: absolute;
                border: 1px solid #4dcac4;
                width: 15px;
                height: 15px;
                left: -10px;
                top: -7px;
                background: #fff;
                border-radius: 50%;
              }
            }
          }
          p {
            display: flex;
            align-items: center;
            margin-top: 15px;
            color: #666666;
            span {
              margin-left: 3px;
              display: inline-block;
              font-size: 14px;
              list-style: 0;
              svg {
                fill: #666666;
              }
            }
          }
        }
      }
    }
  }
}
.takeBreak {
  .modal-dialog {
    max-width: 400px;
    .modal-content {
      padding: 25px 15px;
      border-radius: 15px;
      .modal-header {
        position: relative;
        justify-content: center;
        padding: 0 0 15px;
        border-bottom: none;
        .btn-close {
          position: absolute;
          top: -8px;
          right: 5px;
          color: #000;
          font-size: 14px;
          font-weight: 900;
          padding: 0;
          &:focus {
            box-shadow: none;
          }
        }
        .modal-title {
          font-size: 20px;
          font-weight: 700;
          color: #020202;
        }
      }
      .modal-body {
        .form-group {
          .form-select {
            border-radius: 25px;
            padding: 10px 15px;
            color: #000;
            font-weight: 600;
            border-color: #000000;
            option {
              color: #000;
              font-weight: 600;
            }
          }
          .textArea {
            border-radius: 15px;
            margin-top: 25px;
            border-color: #000000;
          }
        }
      }
      .modal-footer {
        justify-content: center;
        border-top: none;
        .submitBtn {
          border-radius: 25px;
          background: linear-gradient(#a0a0a0, #323232);
          color: #fff;
          padding: 10px 15px;
          max-width: 150px;
          width: 100%;
          font-size: 15px;
          border: 0;
        }
      }
    }
  }
}
.endJob {
  .modal-dialog {
    max-width: 400px;
    .modal-content {
      padding: 25px 15px;
      border-radius: 15px;
      .modal-header {
        position: relative;
        justify-content: center;
        padding: 0 0 15px;
        border-bottom: none;
        .btn-close {
          position: absolute;
          top: -8px;
          right: 5px;
          color: #000;
          font-size: 14px;
          font-weight: 900;
          padding: 0;
          &:focus {
            box-shadow: none;
          }
        }
        .modal-title {
          font-size: 20px;
          font-weight: 700;
          color: #020202;
        }
      }
      .modal-body {
        .form-group {
          position: relative;
          margin: 15px 0;
          .form-select {
            border-radius: 25px;
            padding: 10px 15px;
            color: #000;
            font-weight: 600;
            border-color: #a8a8a8;
            option {
              color: #000;
              font-weight: 600;
            }
          }
          .rating {
            border-radius: 25px;
            padding: 10px 15px;
            color: #000;
            font-weight: 600;
            border-color: #a8a8a8;
            max-width: 285px;
            width: 100%;
            margin: auto;
          }
          .textArea {
            border-radius: 15px;
            border-color: #a8a8a8;
          }
          .starIcon {
            position: absolute;
            left: 35%;
            top: 9px;
            svg {
              fill: #b7b7b7;
              font-size: 15px;
              margin: 0 2px;
            }
          }
        }
      }
      .modal-footer {
        justify-content: center;
        border-top: none;
        .submitBtn {
          border-radius: 25px;
          background: linear-gradient(#a0a0a0, #323232);
          color: #fff;
          padding: 10px 15px;
          max-width: 150px;
          width: 100%;
          font-size: 15px;
          border: 0;
        }
      }
    }
  }
}
.sos {
  .modal-dialog {
    max-width: 400px;
    .modal-content {
      padding: 25px 15px;
      border-radius: 15px;
      .modal-header {
        position: relative;
        justify-content: center;
        padding: 0 0 15px;
        border-bottom: none;
        .btn-close {
          position: absolute;
          top: -8px;
          right: 5px;
          color: #000;
          font-size: 14px;
          font-weight: 900;
          padding: 0;
          &:focus {
            box-shadow: none;
          }
        }
        .modal-title {
          font-size: 20px;
          font-weight: 700;
          color: #020202;
        }
      }
      .modal-body {
        .notiFi {
          display: flex;
          justify-content: center;
          padding: 0.5rem 1rem;
          label {
            font-size: 16px;
            cursor: pointer;
            font-weight: 500;
          }
          .btn-color-mode-switch {
            > label.btn-color-mode-switch-inner {
              margin: 0px;
              width: 150px;
              height: 50px;
              background: #e0e0e0;
              border-radius: 26px;
              overflow: hidden;
              position: relative;
              transition: all 0.3s ease;
              box-shadow: 0px 0px 8px 0px rgba(17, 17, 17, 0.34) inset;
              display: block;
              &:before {
                content: attr(data-on);
                position: absolute;
                font-size: 12px;
                font-weight: 500;
                top: 7px;
                right: 20px;
                opacity: 0;
              }
              &:after {
                content: attr(data-off);
                width: 90px;
                height: 50px;
                line-height: 50px;
                background: transparent;
                border-radius: 26px;
                position: absolute;
                left: 0px;
                text-align: center;
                transition: all 0.3s ease;
                box-shadow: 0px 0px 6px -2px #111;
              }
            }
            input[type="checkbox"] {
              cursor: pointer;
              width: 0;
              height: 0;
              opacity: 0;
              position: absolute;
              top: 0;
              z-index: 1;
              margin: 0px;
              &:checked {
                + {
                  label.btn-color-mode-switch-inner {
                    background: #cfcfcf;
                    color: #fff;
                    &:after {
                      content: attr(data-on);
                      left: 60px;
                      background: linear-gradient(#a0a0a0, #323232);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .on_job {
    padding: 25px 0;
    .content {
      padding: 15px 10px;
      .title{
        margin-bottom: 10px;
      }
      .tab-content{
        margin: 15px 0;
        h4{
          margin-bottom: 15px;
        }
      }
      .job_box {
        .nav {
          .nav-item {
            display: block;
            width: 100%;
            .nav-link {
              max-width: none;
              margin: 5px auto;
              justify-content: center;
            }
          }
        }
      }
    }
  }
}
