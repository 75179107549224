.emp_details {
    padding: 100px 0 25px;
    background-size: cover;
  
    // min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  
    .regForm {
      text-align: center;
      margin: 0;
      background: #fff;
      border: 1px solid #fff;
      border-radius: 10px;
      padding: 0;

      .identityTitle{
        position: relative;
        margin-bottom: 20px;
        
        h5 {
          font-size: 15px;
          font-weight: 600;
          position: relative;
          text-align: left;
        }
  
        .editDetail {
          position: absolute;
          top: -3px;
          right: 0;
  
          button {
              // color: #484848;
              // display: inline-flex;
              // align-items: center;
              // padding: 0;
              // line-height: 0;
              background: #000;
              color: #fff;
              border-radius: 10px;
              font-weight: 400;
              font-size: 13px;
              margin-right: 3px;
              max-width: 150px;
              display: inline-block;
              text-align: center;
              padding-right: 20px;
              svg {
                  margin-right: 3px;
                  font-size: 14px;
              }

              &:focus{
                box-shadow: none;
              }
          }
        }

      }

      .form-group {
        margin: 8px 0;
        position: relative;
        background: #f7f8fc;
        z-index: 1;
        span {
            position: absolute;
            top: 40%;
            right: 5px;
            -webkit-transform: translateY(-40%);
            transform: translateY(-40%);
            color: #676767;
            z-index: -1;
        }
      }

      .identificationDocument {
          border-radius: 10px;
          .identiBox {
            padding: 0;
            display: flex;
            width: 100%;
            align-items: center;
            &:last-child {
              border: none;
            }
      
            .closeBtn {
              cursor: pointer !important;
              margin-left: 5px;
              line-height: 10px;
              padding: 5px;
            }
          }
          .border-1{
            border: 1px solid #d6edf4;
            border-radius: 8px;
            .table{
              text-align: left;
              margin: 0;
              thead{
                tr{
                  border-width: 0;
                }
                th{
                  font-size: 14px;
                  font-weight: 500;
                }
              }
              tbody{
                border: 1px solid #d6edf4;
                td{
                  font-size: 15px;
                }
              }
              a{
                vertical-align: -webkit-baseline-middle;
                color: #000;
              }
              .form-group{
                margin: 0;
                .form-control,.form-select{
                  border: none;
                  background: transparent;
                  border-radius: 5px;
                  padding: 0 10px;
                  margin: 0;
                  font-size: 13px;
                  min-height: 28px;
                }
                .form-select{
                  padding-right: 25px;
                }
                
              }
            }
          }
          
      }
    }

    .nextBtn {
      margin: 50px 0 0;
      text-align: center;
      a, button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: #fff !important;
        background: #000;
        border-radius: 10px;
        padding: 10px 60px;
        cursor: pointer;
        font-size: 15px;
        span {
          margin-left: 5px;
        }
      }
    }
  }
  
  @media (max-width: 576px) {
    .emp_details {
      .regForm {
        .identityTitle {
          .editDetail{
            button{
              padding: 5px 15px;
              max-width: none;
              span{
                display: none;
              }
            }
          }
        }
      }
      .nextBtn{
        margin: 25px 0 0;
      }
    }
  }

  .table-responsive.border-1{
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #f5f5f5;
    }
    
    &::-webkit-scrollbar {
      width: 8px;
      height: 6px;
      background-color: #f5f5f5;
    }
    
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #000;
    }
  }

  // .add{
  //   background: #000;
  //   color: #fff;
  //   border-radius: 10px;
  //   font-weight: 400;
  //   font-size: 13px;
  //   margin-right: 3px;
  //   max-width: 150px;
  //   display: inline-block;
  //   text-align: center;
  //   padding-right: 20px;
  // }