.aboutBanner{
    background: url(../../images/how_work/works-banner.png) no-repeat center bottom;
    background-size: cover;
    padding: 50px 0 0;

   .work{
    padding: 150px 0;
    text-align: center;
    color: #fff;
    max-width: 500px;
    margin: auto;

    h1{
        font-size: 38px;
        font-weight: 400;
       }
   }       
}
.about-section{
    padding: 50px 0px 25px;
    .part-b{
        margin-bottom: 15px;
        p{
            font-weight: 400;
            text-align: center;
            margin-bottom: 35px;
        }
        
        h3{  
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 10px;
        text-align: center;
        }
    }
}
.subscribe {
    background: #000000;
    padding: 50px 0;

    p {
        color: #fff;
        font-weight: 400;
    }

    .subBtn {
        a {
            display: inline-block;
            background: #4bb1b4;
            color: #fff;
            border: 1px solid transparent;
            border-radius: 25px;
            padding: 7px 10px;
            font-size: 15px;
            max-width: 180px;
            width: 100%;
            text-align: center;
        }
    }
}

@media (max-width: 1199px){
    .subscribe {
        padding: 40px 0px;
        padding: 35px 0px;
    }
}

@media (max-width: 1024px){
    .aboutBanner {
        .work {
            h1{
                font-size: 32px;
            }
        }
    }
}

@media (max-width: 991px){
    .aboutBanner{
        padding: 50px 0 0;
    }
}


@media (max-width: 576px){
    .aboutBanner{
        .work{
            padding: 150px 0 100px;
            h1{
                font-size: 24px;
            }
        }
    }
    .subscribe{
        text-align: center;
        .subBtn{
            margin-top: 15px;
        }
    }
    .about-section{
        padding: 25px 0px 0;
        text-align: center;
        .part-b{
            margin-bottom: 0px;
            p{
                font-size: 16px;
                text-align: center;
            }
            h3{
                font-size: 20px;
                text-align: center;
            }
        }
    }
}
.part-a h3{
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 10px !important;
    text-align: center;
}