.banner-contact {
    background: url(../../images/how_work/contact_bg.png) no-repeat center bottom;
    background-size: cover;
    padding: 50px 0 0;

    .workBox {
        color: #fff;
        text-align: center;
        padding: 150px 0;
        h1 {
            font-size: 38px;
            font-weight: 400;
        }
        p{
            font-weight: 300;
        }
        .form-group {
            margin: 10px 0;
            position: relative;
            .form-control {
              border-radius: 25px;
              border-color: #111111;
              color: #a8a8a8;
              font-weight: 400;
              padding: 5px 10px;
              &::placeholder {
                color: #a8a8a8;
              }
              &.textArea {
                border-radius: 12px;
              }
            }
          }
          .reachForm{
              text-align: center;
              background: #fff;
              padding: 20px;
              border-radius: 6px;
              max-width: 350px;
              margin: auto;
              width: 100%;
              h5{
                  color: #000;
                  font-weight: 500;
                  margin-bottom: 20px;
              }
              .submitBtn{
                  a{
                    display: block;
                    background: #4bb1b4;
                    color: #fff;
                    border: 1px solid transparent;
                    border-radius: 25px;
                    margin: 25px 25px 0;
                    padding: 7px 10px;
                    font-size: 15px;
                    text-align: center;
                  }
              }
          }
    }
}
.contactForm{
    padding: 50px 0;
    .leftBox{
        h3{
            font-size: 24px;
            font-weight: 700;
            color: #000;
            margin-bottom: 10px;
        }
        p{
            font-weight: 400;
            color: #000;
        }
        h6{
            font-size: 14px;
            font-weight: 700;
            color: #000;
            margin: 20px 0 5px;
        }
        span{
            display: inline-block;
            font-weight: 500;
            color: #000;
        }
        ul{
            display: flex;
            margin-top: 30px;
            li{
                a{
                    display: inline-block;
                    margin-right: 10px;
                }
            }
        }
    }
    .form-group {
        margin: 10px 0;
        position: relative;
        .form-control {
          border-radius: 25px;
          border-color: #111111;
          color: #000;
          font-weight: 400;
          padding: 5px 10px;
          &::placeholder {
            color: #a8a8a8;
          }
          &.textArea {
            border-radius: 12px;
          }
        }
      }
    .reachForm{
        text-align: center;
        background: #fff;
        box-shadow: 0px 5px 15px 0px rgba(28, 29, 29, 0.21);
        padding: 20px;
        border-radius: 6px;
        max-width: 350px;
        margin: auto;
        width: 100%;
        h5{
            color: #000;
            font-weight: 700;
            margin-bottom: 20px;
            font-size: 20px;
        }
        .submitBtn{
            a{
                background: #4bb1b4;
                color: #fff;
                border: 1px solid transparent;
                border-radius: 25px;
                padding: 5px 35px;
                font-size: 15px;
                display: inline-block;
                margin-top: 10px;
            }
        }
    }
}

@media (max-width: 1024px){
    .banner-contact{
        .work {
            h1{
                font-size: 32px;
            }
            p{
                font-size: 16px;
            }
        }
    }
}

@media (max-width: 991px){
    .contactForm {
        .reachForm{
            max-width: none;
            width: 100%;
            margin-left: 0;
        }
    }
}

@media (max-width: 576px){
    .banner-contact {
        padding: 50px 0 0;
        .workBox{
            padding: 150px 0 100px;
            h1{
                font-size: 24px;
            }
        }
    }
    .contactForm{
        padding: 30px 0;
        text-align: center;
        .leftBox{
            ul{
                display: flex;
                justify-content: center;
                margin-top: 30px;
                li{
                    a{
                        display: inline-block;
                        margin: 0 5px;
                    }
                }
            }
        }
        .reachForm{
            margin-top: 30px;
            max-width: none;
            margin-right: auto;
            margin-left: auto; 
        }
    }
}