.all_job {
  background: #fff;
  padding: 100px 0 25px;
  min-height: 100%;
  .detailsBox {
    background: #fff;
    padding: 0px 15px;
    border-radius: 15px;
    position: relative;
    .rate_title {
      margin-bottom: 10px;
      h4 {
        font-size: 20px;
        font-weight: 600;
        color: #000000;
      }
    }
    .border-1{
      margin-top: 15px;
      border: 1px solid #d6edf4;
      border-radius: 8px;
      .table{
        text-align: left;
        margin: 0;
        thead{
          tr{
            border-width: 0;
          }
          th{
            font-size: 14px;
            font-weight: 700;
          }
        }
        tbody{
          border: 1px solid #d6edf4;
          overflow-y: "scroll";
          height: '300px';
          backgroundcolor: "red";
          td{
            font-size: 14px;
            font-weight: 500;
          }
          .starRate{
            display: flex;
            svg{
              fill: #facf1e;
            }
          }
          .view_btn{
            display: inline-block;
            font-size: 12px;
            color: #74b6b7;
          }
        }
        a{
          vertical-align: -webkit-baseline-middle;
          color: #000;
        }
      }
    }
  }
}

@media (max-width: 576px){
  .all_job{
    .detailsBox{
      padding: 0;
      .rate_title{
        margin-bottom: 10px;
      }
    }
  }
}
