.ticket_detail {
  padding: 100px 0 25px;
  background: #fff;
  min-height: 100%;

  .content {
    border: 1px solid #d6edf4;
    border-radius: 15px;
    .contentBox{
      padding: 20px;
      .title {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        position: relative;
        .backArrow {
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          a {
            border: 0;
            outline: none;
            background: none;
            color: #000;
          }
        }
        .heading {
          width: 100%;
          text-align: center;
          h1 {
            font-size: 18px;
            font-weight: 600;
          }
          h6 {
            font-size: 16px;
            color: #4c4c4c;
            font-weight: 700;
            margin-top: 10px;
          }
        }
      }
      .form-group {
        margin: 10px 0;
        position: relative;
        .form-select,
        .form-control {
          border-radius: 10px;
          font-size: 15px;
          border-color: #a8a8a8;
          color: #000000;
          font-weight: 500;
          padding: 10px;
          min-height: 40px;
          &::placeholder {
            color: #000000;
          }
          span {
            color: #000;
          }
          &.timeInput {
            padding-left: 35px;
          }
        }
        .attachIcon {
          position: absolute;
          right: 15px;
          top: 40%;
          transform: translateY(-40%);
          color: #888888;
          cursor: pointer;
        }
        .mapIcon {
          display: inline-block;
          position: absolute;
          top: 50%;
          left: 15px;
          transform: translateY(-50%);
          color: #676767;
          svg {
            fill: #000;
          }
        }
      }
      .descBox{
        padding: 10px 0;
        h6{
          font-size: 14px;
          font-weight: 600;
        }
        .descPara{
          margin: 8px 0;
        }
        p{
          font-size: 13px;
          font-weight: 500;
          color: #3c3d3c;
          margin: 10px 0;
          img{
            width: 14px;
          }
          span{
            font-weight: 600;
          }
        }
        &.reponse{
          background: #1cbb93;
          border-radius: 15px;
          color: #fff;
          padding: 10px;
          p{
            color: #e6fcf0;
            font-weight: 400;
            font-size: 12px;
          }
          h6{
            font-weight: 500;
            color: #e6fcf0;
          }
        }
      }
    }
    .textMessage{
      margin-top: 40px;
      padding: 10px;
      .form-group{
          position: relative;
          margin: 0;
          .form-control{
              border-radius: 8px;
              background-color: #f7f8fc;
              font-size: 14px;
              border-color: #d6edf4;
              font-size: 13px;
            &:focus{
              border-color: #d6edf4;
            }
            &::placeholder{
              color: #b5b6ba;
            }
          }
          a{
              display: inline-block;
              position: absolute;
              right: 10px;
              top: 15px;
              line-height: 0;
              svg{
                  fill: #01b399;
                  font-size: 12px;
              }
          }
      }
    }
  }
}

@media (max-width: 576px) {
  .ticket_detail {
    .content {
      .contentBox{
        padding: 10px;
        .title{
          margin-bottom: 15px;
        }
        .form-group{
          margin: 6px 0;
        }
      }
      .textMessage{
        padding: 0;
        margin-top: 20px;
      }
    }
  }
}
