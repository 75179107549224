.reviewPage {
  padding: 50px 0;
  background: url(../../images/signup_bg.png) no-repeat center bottom;
  background-size: cover;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .reviewBox {
    background: #fff;
    padding: 40px 25px;
    margin-top: 50px;
    border-radius: 20px;

    .heading {
      width: 100%;
      text-align: center;
      h1 {
        font-size: 20px;
        font-weight: 700;
      }
    }
    .profileBox {
      border: 1px solid #808080;
      margin: 40px auto 0;
      // max-width: 640px;
      border-radius: 40px;
      padding: 30px;
      width: 48%;

      .flexBox {
        display: flex;
        justify-content: space-between;

        align-items: center;

        .left {
          h2 {
            font-size: 25px;
            font-weight: 700;
            margin-bottom: 20px;
          }

          p {
            margin-bottom: 10px;
            font-size: 20px;
            span {
              width: 30px;
              text-align: center;
              color: #a0a1a2;
              display: inline-block;
              margin-right: 7px;
            }
          }
        }
        .right {
          .imgBox {
            width: 140px;
            height: 140px;
            border-radius: 50%;
            border: 10px solid #e9e9e9;
            background: #fbfbfb;
            overflow: hidden;

            img {
              width: 120px;
              height: 120px;
              object-fit: cover;
              object-position: center;
            }
          }
        }
      }

      .editProfile {
        border: 1px solid #1a1a1a;
        border-radius: 30px;
        color: #545454;
        padding: 5px 15px;
        font-weight: 600;
      }
    }

    h5 {
      text-align: center;
      margin-top: 25px;
      font-weight: 600;

      &.mt {
        margin-top: 40px;
      }
    }

    .listBox {
      border: 1px solid #808080;
      margin: 25px auto 0;
      // max-width: 600px;
      border-radius: 25px;
      padding: 15px 15px 15px 25px;

      .flexBox {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .left {
          p {
            margin-bottom: 6px;
            font-size: 20px;

            &.bold {
              font-weight: 600;
            }
          }
          .editProfile {
            color: #545454;
            padding: 5px 0;
            font-weight: 600;
          }
        }
        .right {
          .imgBox {
            width: 225px;
            height: 140px;
            border-radius: 25px;
            border: 1px solid #808080;
            overflow: hidden;

            img {
              width: 219px;
              height: 140px;
              object-fit: cover;
              object-position: center;
            }
          }
        }
      }
    }

    .history {
      border: 1px solid #808080;
      margin: 25px auto 0;
      max-width: 600px;
      border-radius: 25px;
      padding: 15px 15px 15px 25px;

      .flexBox {
        display: flex;
        justify-content: start;
        align-items: center;
        flex-wrap: wrap;

        li {
          list-style-type: none;
          width: 33.33%;
          text-align: left;
          margin-bottom: 15px;

          h5 {
            font-size: 20px;
            text-align: left;
            margin: 0 0 7px;
          }
          p {
            font-size: 18px;
          }
        }
      }
    }

    .submit {
      margin-top: 40px;
      background: linear-gradient(#a0a0a0, #323232);
      color: #fff;
      border-radius: 25px;
      padding: 10px 50px;
      display: inline-block;
      margin-top: 25px;
      span {
        margin-left: 5px;
      }
    }
  }
  .backBtn {
    text-align: center;
    a {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      background: #fff;
      color: #393939;
      font-weight: 700;
      border-radius: 25px;
      padding: 8px 30px;
      span {
        margin-right: 5px;
      }
    }
  }
}

@media (max-width: 991px){
  .reviewPage{
    .reviewBox{
      .profileBox{
        width: 100%;
      }
    }
  }
}

@media (max-width: 576px){
  .reviewPage {
    padding: 25px 0;
    .reviewBox {
      padding: 15px 25px;
      margin-top: 25px;
      .profileBox{
        margin: 15px auto 0;
        padding: 15px 5px;
        max-width: none;
        width: 100%;
        border-radius: 10px;
        .flexBox{
          flex-direction: column-reverse;
          align-items: initial;
          .left{
            margin-top: 15px;
            h2{
              font-size: 18px;
              margin-bottom: 8px;
            }
            p{
              font-size: 13px;
            }
          }
          .right{
            .imgBox{
              display: flex;
              align-items: center;
              justify-content: center;
              width: 75px;
              height: 75px;
              margin: auto;
              img{
                width: 35px;
                height: 35px;
              }
            }
          }
        }
        .editProfile{
          margin-top: 15px;
        }
      }
      .listBox {
        padding: 15px 10px;
        text-align: center;
        .flexBox {
          flex-direction: column;
          align-items: initial;
          .left {
            order: 1;
            margin-top: 15px;
            p {
              font-size: 16px;
            }
          }
          .right{
            order: 0;
            .imgBox{
              width: 100%;
              height: auto;
              img{
                width: 100%;
                height: auto;
              }
            }
          }
        }
      }
      .history{
        padding: 15px 10px;
        .flexBox{
          align-items: initial;
          flex-direction: column;
          li{
            width: auto;
            h5{
              text-align: center;
              font-size: 18px;
              margin: 0;
            }
            p{
              text-align: center;
              font-size: 16px;
            }
          }
        }
      }
      h5{
        &.mt{
          margin-top: 10px;
        }
      }
    }
  }
}
