body{color: #333333;font-size: 16px;}
.btn-black {
	background: #000;
	padding: 15px 30px;
	display: inline-block;
	color: #fff;
	text-decoration: none;
	border-radius: 5px;
	font-size: 18px;
}
.btn-black:hover {color: #fff;background: #131313;}
.btn-white {
	background: #fff;
	padding: 15px 30px;
	display: inline-block;
	color: #000;
	text-decoration: none;
	border-radius: 5px;
	font-size: 18px;
}
.btn-white:hover {
	color: #fff;
	background: #43a9b1;
}
.accordion-button:not(.collapsed)::after {
	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2343a9b1'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 01.708 0L8 10.293l5.646-5.647a.5.5 0 01.708.708l-6 6a.5.5 0 01-.708 0l-6-6a.5.5 0 010-.708z'/%3E%3C/svg%3E");
	-webkit-transform: rotate(-180deg);
	transform: rotate(-180deg);
}
.py-6 {
	padding-top: 5rem;
	padding-bottom: 5rem;
}
.dropdown-item.active, .dropdown-item:active {
	background-color: #4BB1B4;
}
a, a:hover{text-decoration: none;color: #333;}
h2{font-size: 33px;margin-bottom: 20px;color: #000000;}
.sec-sub-heading{font-size: 20px;}
.gg-icon {
	position: relative;
	display: inline-block;
}
.gg-icon:before{position: absolute;content: "";width: 100%;height: 100%;top: 0;left: 0;background-image: url("../images/sprite.svg");background-repeat: no-repeat;background-position: 0;}
.gg-icon.fb-icon:before {
	background-position: -179px -82px;
}
.gg-icon.instagram-icon:before {
	background-position: -211px -82px;
}
.gg-icon.linkedin-icon:before {
	background-position: -244px -82px;
}
header .navbar {background: #000;}
header .nav-link {
	color: #fff;
	padding: 0;
	margin: .5rem 1.5rem !important;
}

.nav-link:focus, .nav-link:hover {
	color: #43a9b1;
}
.homer-banner {
	position: relative;
}
.home-banner-text {
	position: absolute;
	top: 20%;
	left: 0;
	width: 100%;
	color: #fff;
}
.home-banner-text-inner {
	width: 60%;
}
.home-banner-text-inner h1 {
	font-size: 50px;
	font-weight: 700;
	margin-bottom: 30px;
}
.home-banner-text-inner h1 span{
	font-size: 37px;
	font-weight: 400;
}
.home-banner-text-inner p {
	font-size: 21px;
	margin-bottom: 50px;
}
.home-banner-text li {
	display: inline-block;
	margin-right: 30px;
}
.home-banner-text li:last-child {
	margin-right: 0;
}
.home-solutions {
	background: #F8F8F8;
}
.home-solutions li {
	background: #ffffff;
	padding: 20px;
	box-shadow: 0 0 5px #dbdbdb;
	border-radius: 5px;
	text-align: center;
	width: calc(16.6% - 30px);
	display: inline-block;
	font-size: 17px;
	margin: 0 15px 30px 15px;
}
.home-solutions li .gg-icon {
	display: block;
	margin: 0 auto;
	margin-bottom: 15px;
	width: 76px;
	height: 76px;
}
.gg-icon.bank-icon::before {
	background-position: 3px 7px;
}
.gg-icon.car-lot-icon::before {
	background-position: -77px 7px;
}
.gg-icon.concierge-icon::before {
	background-position: -154px 7px;
}
.gg-icon.loss-prevention-icon::before {
	background-position: -224px 4px;
}
.gg-icon.protests-icon::before {
	background-position: -296px 7px;
}
.gg-icon.access-control-icon::before {
	background-position: -374px 7px;
}
.gg-icon.events-icon::before {
	background-position: -444px 2px;
}
.gg-icon.residential-icon::before {
	background-position: -529px 4px;
}
.gg-icon.construction-icon::before {
	background-position: 5px -73px;
}
.gg-icon.vip-icon::before {
	background-position: -82px -73px;
}

.home-why{background: url("../images/guard-sec-bg.jpg");background-repeat: no-repeat;background-size: cover;color: #fff;padding-bottom: 17rem;}
.home-why h2 {
	color: #fff;
	margin-bottom: 70px;
}
.home-why p {
	margin-bottom: 0;
	font-size: 18px;
}
.home-why .why-count {
	width: 52px;
	height: 52px;
	display: inline-block;
	background: #fff;
	border-radius: 50%;
	color: #46a3aa;
	text-align: center;
	line-height: 44px;
	font-size: 28px;
	font-weight: bold;
	border: 3px solid #46a3aa;
	position: relative;
	z-index: 1;
}
.home-why h3 {
	font-size: 23px;
	font-weight: 600;
	margin-bottom: 15px;
}
.home-why .count-left{margin-right: -70px;}
.home-why .count-left + .count-left{margin-bottom: -12px;}
.home-why .count-right{margin-left: -70px;}
.home-why .count-right + .count-right{margin-bottom: -40px;}

.home-app-sec{margin-top:-4rem;}
.home-app-sec .home-app-sec-inner {
	border-radius: 5px 5px 0 0;
	box-shadow: 0 0 5px #ccc;
	background: #fff;
}
.home-app-sec {
	font-size: 34px;
}
.home-app-sec .app-mobile-img {
	margin-top: -6rem;
}
.home-app-sec .home-app-sec-inner {
	border-radius: 5px 5px 0 0;
	box-shadow: 0 5px 10px rgba(119, 119, 119, 0.1);
	background: #fff;
}
.service-areas ul {
	background: #4bb1b4;
	padding: 30px 30px 15px 30px;
	color: #fff;
	border: 1px solid #fff;
	position: relative;
	border-radius: 5px;
	margin: 50px 0 0 -80px;
}
.service-areas ul::before {
	content: "";
	width: calc(100% + 20px);
	height: calc(100% + 20px);
	position: absolute;
	background: #4bb1b4;
	z-index: -1;
	left: -10px;
	top: -10px;
	border-radius: 5px;
}
.service-areas img {
	position: relative;
	z-index: -1;
}
.service-areas .sec-sub-heading {
	font-size: 24px;
	font-weight: 600;
}
.service-areas ul li {
	width: 33.3%;
	font-size: 20px;
	margin-bottom: 15px;
	position: relative;
	padding-left: 30px;
}
.service-areas ul li::before {
	content: "";
	display: inline-block;
	transform: rotate(45deg);
	height: 18px;
	width: 10px;
	border-bottom: 2px solid #fff;
	border-right: 2px solid #fff;
	position: absolute;
	left: 0;
	top: 5px;
}
.home-faqs .accordion-item {
	width: 100%;;
	display: inline-block;
	border: 0;
	border-bottom: 1px solid #D3D3D3;
}
.home-faqs .accordion-button {
	padding: 0 40px 0 0;
	font-size: 19px;
	font-weight: 600;
	color: #333;
	position:relative;
}
.home-faqs .accordion-button::after {
	position: absolute;
	right: 0;
	top: 4px;
}
.home-faqs .accordion-header {
	margin-bottom: 0;
	padding: 30px 0;
}
.home-faqs #accordionFaqs .accordion-item:last-child {
	border-bottom: 0;
}
.home-faqs .accordion-button:not(.collapsed), .accordion-button:focus {
	color: #333;
	background-color: transparent;
	box-shadow: none;
}
.home-faqs .text-decoration-none {
	font-size: 18px;
	color: #43A9B1 !important;
	border-bottom: 1px solid #43A9B1;
}
.jobs-near-sec h2{color: #fff;font-size: 38px;font-weight: 500;}
.jobs-near-sec-in {
	background: rgb(41,139,142);
	background: linear-gradient(90deg, rgba(41,139,142,1) 0%, rgba(75,177,180,1) 100%);
	color: #fff;
	border-radius: 10px;
}
.jobs-near-sec-in img {
	margin-top: -50px;
}
.jobs-near-sec-in .btn-black {
	padding: 10px 30px;
}
footer {
	background: #242424;
	color: #fff;
	padding-top: 5rem;
}
footer h4 {
	font-size: 20px;
	font-weight: 600;
	margin-bottom: 20px;
}
footer .quick-links li {
	margin-bottom: 10px;
	position: relative;
	padding-left: 20px;
}
footer .quick-links.specs li {
	width: 49%;
	display: inline-block;
	padding-right: 20px;
}
footer .quick-links li:last-child{margin-bottom: 0;}
footer .quick-links li::before {
	content: "";
	width: 4px;
	height: 4px;
	position: absolute;
	background: #fff;
	border-radius: 50%;
	top: 10px;
	left: 0;
}
footer a{color: #fff;text-decoration: none;}
footer a:hover{color: #4BB1B4;}
footer .footer-btm-left a {
	margin-left: 10px;
	border-left: 1px solid #fff;
	line-height: 1;
	display: inline-block;
	padding-left: 10px;
}
footer .footer-btm {padding: 30px 0;border-top: 1px solid #5C5C5C;margin-top: 4rem;}
footer address {
	line-height: 2;
}
.footer-btm-right li + li {
	margin-left: 30px;
}
footer .footer-social li+li{margin-left: 10px;}
.footer-social .gg-icon {
	width: 30px;
	height: 30px;
}

.welcomeHeader .dropdown-menu.show {
	padding: 0;
	border-radius: 0;
}
.welcomeHeader .dropdown-menu.show a {
	padding: 7px 15px;
}
.welcomeHeader .dropdown-menu.show li:hover a {
	background: #43a9b1;
	color: #fff;
}
/*------------------------------------innrer page----------------------------------------*/
.page-title {
	background: rgb(41,139,142);
	background: linear-gradient(90deg, rgba(41,139,142,1) 100%, rgba(75,177,180,1) 0%);
	color: #fff;
}
.breadcrumb a, .breadcrumb li {
	color: #fff !important;
	text-decoration: none;
}
.breadcrumb a {
	border-bottom: 1px solid #fff;
}
.breadcrumb-item + .breadcrumb-item:before {
	color: #fff;
}
.inner-intro-inner {
	border-bottom: 1px solid #D6D6D6;
}
.inner-services li {
	display: inline-block;
	width: calc(25% - 30px);
	border-radius: 5px;
	box-shadow: 0 0 5px #E6E6E6;
	padding: 24px;
	margin: 15px;
}
.inner-services li:hover {
	background: #4BB1B4;
	color: #fff;
}
.inner-services li .gg-icon{
	width: 70px;
	height: 70px;
	display: inline-block;
	margin: 0 auto;
	background: #ebf6f7;
	border-radius: 50%;
	margin-bottom: 10px;
}
.inner-services li:hover .gg-icon{
	background: #ffffff;
}
.inner-services li:hover a{
	color: #ffffff;
}
.inner-services li h3 {
	font-size: 20px;
	font-weight: 600;
}
.inner-services .gg-icon.bank-icon::before {
	background-position: -272px -66px;
}
.inner-services .gg-icon.car-lot-icon::before {
	background-position: -483px -66px;
}
.inner-services .gg-icon.concierge-icon::before {
	background-position: -343px -65px;
}
.inner-services .gg-icon.loss-prevention-icon::before {
	background-position: -267px -137px;
}
.inner-services .gg-icon.protests-icon::before {
	background-position: -411px -66px;
}
.inner-services .gg-icon.access-control-icon::before {
	background-position: -127px -140px;
}
.inner-services .gg-icon.events-icon::before {
	background-position: -344px -140px;
}
.inner-services .gg-icon.residential-icon::before {
	background-position: -199px -141px;
}
.inner-services .gg-icon.construction-icon::before {
	background-position: 15px -146px;
}
.inner-services .gg-icon.vip-icon::before {
	background-position: -53px -144px;
}
.inner-loc-benefit {
	padding-top: 0;
}
.inner-loc-benefit h3 {
	font-size: 22px;
	font-weight: 600;
}
.inner-loc-benefit li .gg-icon {
	width: 50px;
	height: 55px;
}
.inner-loc-benefit li + li {
	margin-top: 15px;
}
.inner-loc-benefit .gg-icon.sheild-icon::before {
	background-position: -434px -151px;
}
.inner-loc-benefit .gg-icon.mobile-icon::before {
	background-position: -139px -220px;
}
.inner-loc-benefit .gg-icon.track-icon::before {
	background-position: -484px -149px;
}
.inner-loc-benefit a {
	color: #43A9B1;
	border-bottom: 1px solid #43A9B1;
	padding-bottom: 5px;
}
.why-hire {
	background: #F8F8F8;
}
.why-hire h3 {
	text-align: left;
	font-weight: 600;
	font-size: 18px;
	line-height: 1.4;
}
.why-hire li {
	background: #ffffff;
	padding: 20px;
	box-shadow: 0 0 5px #dbdbdb;
	border-radius: 5px;
	text-align: center;
	width: calc(25% - 30px);
	display: inline-block;
	font-size: 17px;
	margin: 0 15px 30px 15px;
}
.why-hire li .gg-icon {
	width: 40px;
	height: 52px;
}
.why-hire .gg-icon.long-term-icon:before {
	background-position: 0px -217px;
}
.why-hire .gg-icon.mobile-icon:before {
	background-position: -142px -147px;
}
.why-hire .gg-icon.sheild-icon:before {
	background-position: -283px -149px;
}
.why-hire .gg-icon.sos-icon{height: 34px;}
.why-hire .gg-icon.sos-icon:before {
	background-position: -101px -223px;
}
.other-services li {
	width: 33.3%;
	display: inline-block;
	margin-bottom: 20px;
	padding: 0 20px;
	position: relative;
}
.other-services li:before {
	content: "";
	width: 6px;
	height: 6px;
	position: absolute;
	background: #333;
	border-radius: 50%;
	top: 15px;
	left: 0;
}
.other-services li a{
	font-size: 20px;
	font-weight: 600;
}
.other-services li a:hover{color:#43a9b1;}
.about-statement{background: #f8f8f8;}
.about-statement li + li {
	margin-top: 35px;
}
.about-statement h3 {
	font-size: 22px;
	font-weight: 600;
}
.taining-contact {
	background: #ffffff;
	padding: 20px;
	box-shadow: 0 0 5px #dbdbdb;
	border-radius: 5px;
	margin-top:15px;
}
.taining-contact h3, .taining-contact label{font-weight:600;width:100%;}
.taining-contact h3{font-size:20px;}
.taining-contact a:not(label a){font-size:14px;}
.gg-icon.envelope-icon::before {
	background-position: -198px -226px;
}
.gg-icon.envelope-icon {
	width:45px;height:35px;margin-top:5px;
}
.form-bg {
	background: #4BB1B4;
	padding: 30px;
	border-radius: 5px;
}
.training-form h3{color:#fff;font-size:32px;font-weight:bold;margin-bottom:15px;}
.training-form input, .training-form textarea{padding-left:40px;}
.training-form .input-icon{position:relative;}
.training-form .input-icon:before{position: absolute;content: "";top: 13px;left: 5px;background-image: url("../images/sprite.svg");background-repeat: no-repeat;width:26px;height:28px;}
.training-form .form-name:before{background-position:-436px -218px;}
.training-form .form-mail:before{background-position:-463px -217px;}
.training-form .form-tel:before{background-position:-489px -217px;}
.training-form .form-message:before{background-position:-514px -218px;}
.gg-icon.experts-icon::before {
	background-position: -262px -220px;
}
.gg-icon.license-icon::before {
	background-position: -323px -219px;
}
.gg-icon.find-icon::before {
	background-position:-383px -219px;
}
#contact-us .taining-contact {
	padding: 20px 0;
	box-shadow: none;
}
#contact-us .taining-contact li .gg-icon{width:30px;height:30px;}
.gg-icon.fb-grey-icon:before{background-position:-351px -341px;}
.gg-icon.instagram-grey-icon:before{background-position:-379px -341px;}
.gg-icon.linkedin-grey-icon:before{background-position:-409px -338px}
.gg-icon.like-icon {width: 48px;height: 48px;}
.gg-icon.like-icon:before {background-position:-298px -340px;}

.login-form{
	border: 1px solid #DBDBDB;
	padding: 40px;
	border-radius:5px;
}
.login-form h3{
	color:#333;text-align:center;margin-bottom:30px;
}
.login-form h3{
	color:#333;text-align:center;margin-bottom:30px;
}
.login-form input{
	padding-right: 40px;
	padding-left:15px;
}
.login-form .input-icon::before {
	right: 15px;
	left:auto;
}
.login-form .form-pass:before{background-position:-441px -337px;}
.login-form li a {
	padding: 15px;
	display: inline-block;
	border-radius: 5px;
	width: 100%;
	text-align:center;
	line-height:2;
}
.login-form li .gg-icon{width:20px;height:22px;top: 4px;margin-right:5px;}
.gg-icon.fb-login-icon::before {
	background-position: -493px -341px;
}
.gg-icon.google-login-icon::before {
	background-position: -474px -341px;
}
.fb-login {
	background: #0178F2;
	color: #fff;
}
.fb-login:hover {
	color: #fff;
}
.google-login {
	color: #333;
	background:none;
	border:1px solid #E1E1E1;
}
.google-login:hover {
	color: #333;
}
.login-form li {
	width: calc(50% - 15px);
}
.login-form li + li{margin-left:30px;}
.login-form label a{font-weight:600;}
.form-control, .form-select {
	padding: 1rem .75rem;
}
.login-form label strong{font-weight:600;}

.home-faqs .accordion-button {
	padding: 0 40px 0 0;
	font-size: 19px;
	font-weight: 600;
	color: #333;
	position:relative;
}
.home-faqs .accordion-button::after {
	position: absolute;
	right: 0;
	top: 4px;
}
#faq-page .accordion-item {
	width: 100%;
	border: 0;
	box-shadow: 0 0 4px #D7D7D7;
	margin-bottom: 15px;
}
#faq-page .accordion-item .accordion-button {
	padding: 1.25rem 2.25rem 1.25rem 1.25rem;
	font-size: 20px;
	font-weight: 600;
}
#faq-page .accordion-item .accordion-button:after {
	position: relative;
	right: -23px;
}
#faq-page .accordion-button:not(.collapsed) {
	background: #ebf6f7;
	color: #333;
	border: 0;
	box-shadow: none;
}
#how-works .how-graph h3 {
	font-size: 20px;
	font-weight: 600;
}
#how-works .count-left .gg-icon ,#how-works .count-right .gg-icon {
	width: 65px;
	height: 65px;
	border-radius: 50%;
	background: #EBF6F7;
	overflow:hidden;
}
.gg-icon.start-icon::before {
	background-position: -126px -342px;
}
.gg-icon.assign-icon::before {
	background-position:-56px -343px;
}
.gg-icon.job-icon::before {
	background-position: 17px -341px;
}
.gg-icon.register-icon::before {
	background-position: -309px -259px;
}
.gg-icon.post-icon::before {
	background-position: -378px -260px;
}
.gg-icon.notification-icon::before {
	background-position: -452px -255px;
}
#banks-service .page-title {
	/*background:url(../images/banks-banner.jpg) center;background-size:cover;*/
}
.inner-login-sec .login-sec-content {
	background: #F8F8F8;
}
.inner-login-sec li {
	background: #ffffff;
	padding: 20px;
	box-shadow: 0 0 5px #dbdbdb;
	border-radius: 5px;
	width: calc(35% - 30px);
	display: inline-block;
	font-size: 17px;
	margin: 0 15px;
}
.inner-login-sec h3 {
	text-align: left;
	font-weight: 600;
	font-size: 18px;
	line-height: 1.4;
}
.inner-login-sec li .gg-icon {
	width: 40px;
	height: 52px;
}
.inner-login-sec li .gg-icon {
	width: 65px;
	height: 65px;
	border-radius: 50%;
	background: #EBF6F7;
	overflow: hidden;
}
.inner-login-sec .gg-icon.apply-icon::before {
	background-position: -199px -362px;
}
.inner-login-sec .gg-icon.register-icon::before {
	background-position: -378px -260px;
}
@media(max-width:1199px) {
.inner-login-sec li {
	width: calc(50% - 30px);
}
}
@media only screen and (min-width:992px) and (max-width:1199px) {
.home-banner-text-inner {
	width: 70%;
}
.home-banner-text-inner h1 {
	font-size: 44px;
}
.home-banner-text-inner p {
	margin-bottom: 30px;
}
.home-why .count-left {
	margin-right: -28px;
}
.home-why .count-left + .count-left {
	margin-bottom: -46px;
}
.home-why .count-right {
	margin-left: -28px;
}
.home-why .count-right + .count-right {
	margin-bottom: -96px;
}
.why-hire li {
	width: calc(50% - 30px);
	margin: 10px 15px;
}
}
@media(min-width:992px) {
header li:first-child .nav-link {
	margin: .5rem 1.5rem .5rem 0 !important;
}
header li:last-child .nav-link {
	margin: .5rem 0 .5rem 1.5rem !important;
}
}
@media(max-width:991px) {
.home-why .count-right + .count-right {
	margin-bottom: 0;
}
	.home-why .count-right {
	margin-left: 0;
}
.home-why .count-right, .home-why .count-left {
	margin-bottom: 30px !important;
}
.home-why h3 {
	margin-bottom: 8px;
}
.home-why {
	padding-bottom: 5rem;
}
.home-app-sec {
	margin-top: 5rem;
}
.home-app-sec .app-mobile-img {
	margin-top: 0;
}
.home-why .count-right:last-child {
	margin-bottom: 0 !important;
}
header .navbar-toggler {
	padding: 0;
	border: none;
}
header .navbar-toggler-icon {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 1' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.home-banner-text-inner {
	width: 90%;
}
.service-areas ul {
	width: calc(100% - 20px);
	margin: 50px auto 0 auto;
	padding: 15px 20px;
}
.jobs-near-sec.py-6 {
	padding-top: 0;
}
.home-why .count-left {
	margin-right: 0;
}
#how-works .count-left,#how-works .count-right {
	margin-bottom: 20px;
}
#how-works .inner-login-sec {
	padding-top: 0;
}
.why-hire li {
	width: calc(50% - 30px);
	margin: 10px 15px;
}
header .nav-item {
	padding: 8px 0;
}
header .nav-link {
	color: #333;
	padding: .35rem 0 !important;
	margin: 0 !important;
}
header .offcanvas-header {
	border-bottom: 1px solid #e6e6e6;
}
header .offcanvas .dropdown-menu {
	border: none;
	background: #f2f2f2;
}
header .offcanvas .dropdown-toggle::after {
	content: "+";
	border: 0;
	position: absolute;
	right: 0;
	font-size: 24px;
	top: 6px;
}
	header .offcanvas .dropdown-toggle.show:after {
		content: "-";
		right: 3px;
		font-size: 30px;
		top: 0;
	}
header .offcanvas .dropdown-item {
	display: block;
	padding: .5rem 1rem;
}
}
@media only screen and (min-width:768px) and (max-width:991px) {
.home-banner-text-inner h1 {
	font-size: 36px;
}
.homer-banner img {
	min-height: 350px;
	object-fit: cover;
	object-position: right top;
}
.home-banner-text-inner p {
	margin-bottom: 30px;
}
.home-solutions li {
	width: calc(33.3% - 30px);
}
.jobs-near-sec h2 {
	font-size: 30px;
}
.inner-services li {
	width: calc(33.3% - 20px);
	margin: 10px;
}
}
@media(max-width:767px) {
.py-6 {
	padding-top: 3rem;
	padding-bottom: 3rem;
}
h2 {
	font-size: 28px;
}
	.inner-page h3{font-size: 26px;}
.sec-sub-heading {
	font-size: 18px;
}
	.homer-banner img {
	min-height: 350px;
	object-fit: cover;
	object-position: right top;
}
	.home-banner-text-inner h1 {
	font-size: 32px;
		margin-bottom: 20px;
}
.home-banner-text-inner h1 span {
	font-size: 24px;
}
	.home-banner-text-inner p {
	font-size: 18px;
	margin-bottom: 20px;
}
.btn-black, .btn-white {
	padding: 10px 20px;
	font-size: 16px;
}
.home-banner-text li {
	display: inline-block;
	margin-right: 15px;
}

.home-solutions li {
	width: calc(50% - 30px);
}
.home-why {
	padding-bottom: 3rem;
}
.home-app-sec {
	margin-top: 3rem;
}

.jobs-near-sec h2 {
	font-size: 30px;
}
footer .quick-links li {
	width: 49%;
	display: inline-block;
	padding-right: 20px;
}
.footer-btm {font-size: 14px;}
footer {
	padding-top: 3rem;
}
footer .footer-btm {
	margin-top: 2rem;
}
.home-faqs .accordion-button {
	padding: 0 40px 0 0;
	font-size: 17px;
}
.home-faqs .accordion-item {
	border-bottom: 1px solid #D3D3D3 !important;
}
.home-faqs .accordion-header {
	padding: 20px 0;
}
.home-faqs .accordion-body {
	padding: 0 0 1rem 0;
}
.home-faqs .text-decoration-none {
	font-size: 14px;
	margin-bottom: 15px;
}
.home-why p {
	font-size: 16px;
}
.home-why .count-left {
	margin-right: 0;
}
.home-why .count-left {
	margin-right: 0;
}
.service-areas ul li {
	width: 50%;
}
.inner-login-sec li {
	width: 100%;
}
.inner-login-sec li + li{
	margin-top: 15px;
}
.login-form {
	padding: 30px 15px;
}
.login-form li a {
	padding: 15px 10px;
	line-height: 1.5;
	font-size: 14px;
}
.login-form li {
	width: calc(50% - 5px);
}
.login-form li + li {
	margin-left: 10px;
}
.inner-services li {
	width: calc(50% - 20px);
	margin: 10px;
}
.other-services li {
	width: 50%;
	margin-bottom: 15px;
}
.why-hire li {
	width: 100%;
	margin: 8px 0;
	padding: 15px;
}
.inner-page h3 {
	font-size: 20px;
}
}
@media(max-width:575px) {
.home-app-sec h3 {
	font-size: 21px;
}
.home-app-sec li img {
	width: 80px;
}
.list-inline-item:not(:last-child) {
	margin-right: .25rem;
}
.service-areas ul li {
	width: 100%;
	font-size: 18px;
	margin-bottom: 10px;
}
	.service-areas ul li::before {
	height: 16px;
	width: 9px;
}
.footer-btm-left {
	margin-bottom: 15px;
}
.footer-btm-left, .footer-btm-right {
	text-align: center;
	width: 100%;
}
.login-form li {
	width: 100%;
}
.login-form li + li {
	margin-left: 0;
	margin-top: 15px;
}
.inner-services li {
	width: 100%;
	margin: 0;
}
.inner-services li + li{margin-top: 15px;}
.inner-page h3 {
	font-size: 18px;
}
.other-services li {
	width: 100%;
	margin-bottom: 15px;
	padding: 0 0 0 20px;
}
}