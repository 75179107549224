.workDistance{
    padding: 50px 0;
    background: url(../../images/signup_bg.png) no-repeat center bottom;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100%;
    .otpForm{
        text-align: center;
        margin: 50px 0 0;
        background: #fff;
        border: 1px solid #fff;
        border-radius: 10px;
        padding: 35px 65px;
        h4{
            color: #000000;
            font-weight: 700;
            font-size: 20px;
            color: #000;
        }
        p{
            margin: 10px 0 25px;
            font-weight: 600;
            font-size: 16px;
        }
        .css-1aznpnh-MuiSlider-root{
            color: #01b399;
            height: 6px;
            .css-14pt78w-MuiSlider-rail{
                background-color: #d7d7d7;
            }
            .css-1tfve6q-MuiSlider-mark{
                display: none;
            }
            .css-6cwnna-MuiSlider-mark{
                display: none;
            }
            .css-eg0mwd-MuiSlider-thumb{
                background-color: #fff;
                border: 2px solid #064f70;
            }
            .css-1eoe787-MuiSlider-markLabel,.css-yafthl-MuiSlider-markLabel{
                color: #040404;
                font-weight: 500;
                font-size: 14px;
            }
        }
        .transport{
            p{
                margin: 25px 0 15px;
            }
        }
        .form-check{
            display: flex;
            padding-left: 0;
            align-items: center;
            .form-check-input{
                border-radius: 2px;
                border: 2px solid #000000;
            }
            .form-check-label{
                position: relative;
                font-weight: 500;
                color: #000000;
                font-size: 13px;
            }
            .form-check-input{
                margin-left: 0;
                margin-right: 3px;
            }
        }
        .nextBtn{
            text-align: center;
            button{
                display: inline-flex;
                align-items: center;
                justify-content: center;
                background: linear-gradient(#a0a0a0, #323232);
                color: #fff;
                border-radius: 25px;
                padding: 10px 15px;
                max-width: 185px;
                width: 100%;
                font-size: 14px;
                margin-top: 40px;
                span{
                    margin-left: 5px;
                    display: inline-block;
                }
            }
        }
    }
    .preBtn{
        text-align: center;
        button{
            display: inline-block;
            align-items: center;
            justify-content: center;
            background: #fff;
            color: #393939;
            border-radius: 25px;
            padding: 10px 25px;
            max-width: 185px;
            width: 100%;
            font-size: 14px;
            font-weight: 700;
            span{
                margin-right: 5px;
            }
        }
    }
}

@media (max-width: 991px){
    .workDistance {
        .otpForm{
            padding: 35px 40px;
        }
    }
}

@media (max-width: 576px){
    .workDistance {
        padding: 25px 0;
        .otpForm{
            padding: 25px 40px;
            margin: 25px 0 0;
            .nextBtn{
                button{
                    margin-top: 10px;
                }
            }
        }
    }
}