.job_range {
  background: #fff;
  padding: 100px 0 25px;
  min-height: 100%;

  .fltr {
    display: flex;
    justify-content: flex-end;

    .clr_btn,
    .filter_btn {
      display: block;
      background: #000;
      color: #fff;
      max-width: 135px;
      width: 100%;
      text-align: center;
      padding: 5px 10px;
      border-radius: 5px;
      font-size: 13px;
      margin: 0 0 0 10px;
      transition: all 0.2s ease-in-out;

      &:focus {
        box-shadow: none;
      }
    }
  }

  .filter_btn,
  .apply_togglebtn {
    display: block;
    background: #000;
    color: #fff;
    max-width: 135px;
    width: 100%;
    text-align: center;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 13px;
    margin: 0 0 0 auto;
    transition: all 0.2s ease-in-out;

    &:focus {
      box-shadow: none;
    }
  }

  h4 {
    font-size: 20px;
    font-weight: 600;
    color: #000;
  }

  .Box {
    background: #fff;
    padding: 25px;
    border-radius: 15px;
    position: relative;
    border: 1px solid #d6edf4;
    height: 100%;

    // background-color: #fff;
    h6 {
      // color: #fff;
      font-weight: 600;
      font-size: 14px;
      margin: 10px 0;
    }

    .css-187mznn-MuiSlider-root {
      color: #48b7b7;
      border: 3px solid #fff;
      // box-shadow: 0px 5px 15.04px 0.96px rgba(191, 141, 136, 0.35) inset;
    }

    .form-check {
      display: flex;
      align-items: center;
      padding-left: 0;
      margin: 4px 0;

      .form-check-input {
        border-radius: 2px;
        border: 2px solid #3e3e3e;
        margin-top: 0;

        &:focus {
          box-shadow: none;
        }

        &:checked {
          background-color: #3e3e3e;
          box-shadow: none;
        }
      }

      .form-check-label {
        position: relative;
        font-weight: 500;
        color: #000000;
        font-size: 13px;
      }

      a {
        display: inline-block;
        color: #000000;
        font-size: 12px;
        margin-left: 5px;
      }

      .form-check-input {
        margin-left: 0;
        margin-right: 5px;
      }
    }

    .radioBtn {
      .form-check {
        margin: 5px 0;

        .form-check-input {
          border-radius: 50%;
        }
      }
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;

      li {
        list-style: none;
      }
    }

    .dressCode {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;

      .form-check {
        .form-check-input {
          border-radius: 50%;
        }
      }
    }

    .job_starRate_box {
      display: flex;
      align-items: center;

      svg {
        margin-right: 3px;
        fill: #ddd;
        width: 18px;
      }
    }
  }

  .searchJob_table {
    margin-top: 15px;

    .border-1 {
      margin-top: 15px;
      border: 1px solid #d6edf4;
      border-radius: 8px;

      .table {
        text-align: left;
        margin: 0;

        thead {
          tr {
            border-width: 0;
          }

          th {
            font-size: 14px;
            font-weight: 700;
          }
        }

        tbody {
          border: 1px solid #d6edf4;
          overflow-y: "scroll";
          height: '300px';
          background-color: "red";

          td {
            font-size: 14px;
            font-weight: 500;
          }

          .starRate {
            display: flex;

            span {
              line-height: initial;

              svg {
                // fill: #facf1e;
                width: 18px;
                height: auto;
              }
            }
          }

          .view_btn {
            display: inline-block;
            font-size: 12px;
            color: #74b6b7;
          }
        }

        a {
          vertical-align: -webkit-baseline-middle;
          color: #000;
        }
      }
    }
  }
}

.jobApply {
  .modal-dialog {
    max-width: 350px;

    .modal-content {
      padding: 35px 15px;
      border-radius: 15px;

      .modal-header {
        position: relative;
        justify-content: center;
        padding: 0;
        border-bottom: none;

        .btn-close {
          position: absolute;
          top: -8px;
          right: 5px;
          color: #000;
          font-size: 14px;
          font-weight: 900;
          padding: 0;

          &:focus {
            box-shadow: none;
          }
        }

        .modal-title {
          font-size: 18px;
          font-weight: 700;
          color: #020202;
          text-align: center;
        }
      }

      .modal-body {
        padding: 0;
      }

      .modal-footer {
        justify-content: center;
        border-top: none;

        .submitBtn {
          border-radius: 25px;
          // background: #525252;
          border: 0;
          background: linear-gradient(#a0a0a0, #323232);
          color: #fff;
          padding: 7px 15px;
          max-width: 150px;
          width: 100%;
          font-size: 15px;
          margin-top: 15px;

          &:focus {
            box-shadow: none;
          }
        }
      }
    }
  }
}

@media (max-width: 1199px) {
  .css-1v5z18m {
    width: auto !important;
  }
}

@media (max-width: 991px) {
  .job_range {
    .lBox {
      .lStar {
        margin: 0;

        svg {
          margin: 0 3px 0 0;
        }

        h5 {
          margin: 10px 0 0;
        }
      }
    }

    .Box {
      ul {
        justify-content: initial;

        li {
          margin-right: 15px;
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .job_range {
    .rBox {
      padding: 25px 10px;

      .applyBox {
        padding: 25px 10px;

        .rowOne {
          .calendarBox {
            p {
              justify-content: start;
            }
          }
        }

        .rowTwo {
          .jobRating {
            p {
              justify-content: start;
              margin-top: 5px;
            }
          }
        }

        .rowThree {
          .imgBox {
            img {
              width: 100%;
              margin: 10px 0;
            }
          }
        }
      }
    }

    .Box {
      height: auto;

      .dressCode {
        justify-content: flex-start;

        .form-check {
          margin: 0 15px 0 0;
        }
      }

      &.travel_box {
        padding: 15px 15px 50px 15px;
      }
    }
  }
}

.pagination {
  width: 100%;
  margin-top: 20px;
}

.pagination .page-item {
  width: 115px;
  margin: 0 10px;
}

.pagination .page-item .page-link {
  width: 100%;
  text-align: center;
  background: #000;
  color: #fff;
  border-radius: 5px;
}

.input-range__label--max {
  right: 5px;
}