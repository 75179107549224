.banner-ontario {
    background: url(../../images/how_work/ontario_bg.png) no-repeat center bottom;
    background-size: cover;

    .workBox {
        padding: 5rem 0;
        color: #fff;
        text-align: center;
        h1 {
            font-size: 38px;
            font-weight: 400;
        }
    }
}
.airpoSec {
    .workGuard {
        padding: 50px 0;
        text-align: center;

        .content {
            h3 {
                font-size: 24px;
                font-weight: 700;
            }

            p {
                margin: 20px 0;
                font-weight: 400;
                font-size: 15px;
            }

        }
    }
}
.ontarioSec {
    background: #f9f9f9;
    .workGuard {
        padding: 50px 0;

        .imgBox {
            text-align: right;
            padding-right: 50px;

            img {
                width: 600px;
                height: 400px;
                object-fit: cover;
                // border-radius: 50%;
            }
        }

        .content {
            h3 {
                font-size: 24px;
                font-weight: 700;
            }

            p {
                margin: 10px 0;
                font-weight: 400;
                font-size: 15px;
            }

            a {
                display: inline-block;
                background: #4bb1b4;
                color: #fff;
                border: 1px solid transparent;
                border-radius: 25px;
                padding: 7px 10px;
                font-size: 15px;
                max-width: 180px;
                width: 100%;
                text-align: center;
            }
            ul{
                li{
                    p{
                        font-weight: 500;
                        font-size: 16px;
                        svg{
                            fill: #4bb1b4;
                        }
                    }
                }
            }
        }
    }
}
.secProfe{
    padding: 50px 0;
    text-align: center;
    h3 {
        font-size: 24px;
        font-weight: 700;
    }
    ul{
        display: flex;
        justify-content: space-between;
        margin: 25px 100px 0;
        flex-wrap: wrap;
        li{
            img{
                width: 100px;
                height: 100px;
                border-radius: 50%;
                object-fit: cover;
                margin-bottom: 10px;
                border: 3px solid #4bb1b4;
            }
           .content{
            h5{
                font-weight: 700;
                font-size: 16px;
            }
            p{
                font-size: 15px;
                font-weight: 500;
            }
           }
        }
    }
}

@media (max-width: 1024px){
    .banner-ontario {
        .work {
            h1{
                font-size: 32px;
            }
        }
    }
}

@media (max-width: 991px){
    .banner-ontario {
        padding: 50px 0 0;
        .work {
            h1{
                font-size: 32px;
            }
        }
    }
    .secProfe {
        ul{
            margin: 15px 0 0;
            li{
                margin: 10px 5px;
            }
        }
    }
}
@media (max-width: 576px){
    .banner-ontario{
        .workBox{
            padding: 150px 0 100px;
            h1{
                font-size: 24px;
            }
        }
    }
    .secProfe {
        ul{
            li{
                margin: 10px 5px;
            }
        }
    }
    .airpoSec{
        .workGuard{
            padding: 25px 0;
            .content{
                p{
                    margin: 10px 0 0;
                }
            }
        }
    }
    .ontarioSec {
        .workGuard{
            padding: 25px 0;
            text-align: center;
            .content{
                h3{
                    margin-top: 15px;
                    font-size: 22px;
                }
            }
            .imgBox{
                text-align: center;
                padding-right: 0;
            }
            .content{
                ul {
                    li{
                        p{
                            margin: 10px 0 0;
                            text-align: left;
                        }
                    }
                }
            }
        }
    }
    .secProfe{
        padding: 25px 0;
        h3{
            font-size: 22px;
        }
    }
}