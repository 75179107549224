.otpPage {
  padding: 100px 0 25px;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  background: #fff;
  .otpForm {
    border: 1px solid #d6edf4;
    border-radius: 10px;
    padding: 20px;
    text-align: left;
    h4 {
      color: #000000;
      font-weight: 700;
      font-size: 18px;
      color: #000;
      margin-bottom: 10px;
    }
    .form-check {
      margin: 6px 0;
      .form-check-input {
        margin-top: 4px;
        border-radius: 2px;
        border: 2px solid #b7b7b7;

        &:checked {
          background-color: #646464;
          border: 2px solid #646464;
        }
      }
      .form-check-label {
        color: #040404;
        font-size: 15px;
        font-weight: 500;
      }
    }
    p {
      color: #040404;
      font-size: 15px;
      font-weight: 500;
      margin: 5px 0;
    }
    .form-group {
      position: relative;
      .form-control {
        border: none;
        border: 1px solid #d6edf4;
        background: #f7f8fc;
        border-radius: 8px;
        padding: 8px 10px;
        &::-webkit-inner-spin-button {
          appearance: none;
        }
        &::-webkit-outer-spin-button {
          appearance: none;
        }
      }
      .resendBtn {
        text-align: center;
        a {
          display: inline-block;
          margin-top: 10px;
          font-size: 15px;
          color: #000000;
          font-weight: 500;
        }
      }
    }
    .agreeBtn {
      position: absolute;
      right: 6px;
      top: 6px;
      a {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: #000;
        color: #fff;
        border-radius: 25px;
        padding: 5px 8px;
        font-size: 12px;
        margin: 0;
        cursor: pointer;
        span {
          margin-left: 5px;
        }
      }
    }
    .confirm_para{
      margin: 20px 0;
      padding-top: 20px;
      color: #353535;
      border-top: 1px dotted #d6edf4;
    }
  }
}

@media (max-width: 576px){
  .otpPage{
    padding: 100px 0 25px;
    .otpForm {
      .form-group {
        .form-control{
          padding: 8px 100px 8px 10px;
        }
        span{
          right: 5px;
        }
      }
      .agreeBtn{
        width: 85px;
        a{
          width: 100%;
          justify-content: space-between;
        }
      }
    }
  }
}
.invalid-feedback {
  text-align: left !important;
    margin: 0 !important;
}