.footer {
    background: #242424;
    padding-top: 50px;
    padding-bottom: 0px;
    text-align: left;

    h6 {
        color: #ffffff;
        font-weight: 600;
        font-size: 17px;
        position: relative;
        padding-bottom: 5px;
        margin-bottom: 25px;

        &::after {
            position: absolute;
            content: '';
            width: 50px;
            height: 3px;
            background: #e15d5d;
            bottom: 0;
            left: 0;
        }
    }

    p {
        color: #848484;
        font-weight: 500;
        font-size: 15px;

        &.quickLink {
            margin-top: 30px;
        }
    }
    .speciality-link{
        display: flex;
        .box{
            margin-left: 15px;
        }
        a{
            margin: 0 0 8px;;
        }
    }

    a {
        display: block;
        color: #ffffff;
        font-weight: 300;
        font-size: 15px;

        &.contactLink {
            margin: 25px 0;
        }

        &.twitterLink {
            margin: 8px 15px;
        }
        &:hover{
            color: #4bb1b4;
        }
    }

    .logoImg {
        padding-top: 25px;
        text-align: left;
    }
    .addressCls {
        padding-top: 5px;
        text-align: left;
        color: white;
    
        p {
            color: white !important; 
        }
    }

    .social_icons {
        display: flex;
        justify-content: start;
        align-items: center;
        text-align: center;
    }

    .bottomFooter {
        border-top: 1px solid #505050;
        text-align: center;
        padding: 25px 0;
        margin-top: 50px;

        p {
            margin: 0;
        }
    }
}

@media (max-width: 1399px){
    footer {
        h6 {
            margin-bottom: 15px;
        }

        p.quickLink {
            margin-top: 20px;
        }
    }
}

@media (max-width :1199px){
    footer {
        padding-top: 40px;

        p.quickLink {
            margin-top: 15px;
        }
    }
}

@media(max-width: 768px){
    .footer{
        .col-md-3{
            width: 50%;
        }
        .logoImg{
            text-align: left;
        }
        .social_icons{
            justify-content: start;
        }
        .contactLink{
            margin: 8px 0 !important;
        }
    }
}

@media (max-width: 576px){
    .footer{
        padding: 10px 0;
        a{
            margin: 0 0 10px;
        }
        h6 {
            font-size: 15px;
            margin: 25px 0 15px !important;
        }
        a,p{
            font-size: 14px;
        }
        .logoImg{
            img{
                width: 175px;
            }
        }
        .bottomFooter{
            display: none;
        }
        .social_icons{
            margin-top: 15px;
            a{
                margin: 0;
                &:nth-child(2){
                    margin: 0 15px;
                }
            }
        }
    }
}

@media(max-width: 375px){
    .footer{
        .col-md-3{
            width: 100%;
        }
    }
}