.task_review {
  padding: 50px 0;
  background: #f5f5f5;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .content {
    border-radius: 15px;
    box-shadow: 0px 0px 8px #e5e5e5, 5px 5px 8px #e9e9e9;
    padding: 25px;
    background-color: #fff;
    .title {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      position: relative;
      .backArrow {
        position: absolute;
        left: 0;
        top: 0;
        a {
          border: 0;
          outline: none;
          background: none;
          color: #000;
        }
      }
      .heading {
        width: 100%;
        text-align: center;
        h1 {
          font-size: 20px;
          font-weight: 700;
        }
        p{
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          font-weight: 500;
          margin: 3px 0;
          span{
            margin-right: 3px;
          }
          svg{
            font-size: 13px;
          }
        }
      }
    }
    .form-group {
      position: relative;
      margin: 15px 0;
      .form-control {
        border-radius: 25px;
        border-color: #919191;
        padding-left: 35px;
        min-height: 40px;
        color: #020202;
        font-weight: 500;
        &:focus {
          border-color: #919191;
        }
        &::placeholder {
          color: #020202;
          font-weight: 500;
        }
      }
      svg {
        fill: #020202;
        position: absolute;
        top: 40%;
        left: 15px;
        transform: translateY(-40%);
        color: #676767;
      }
    }
    .historyBox {
      .box {
        border: 1px solid #d7d7d7;
        padding: 8px 15px;
        border-radius: 12px;
        margin: 10px 0;
        .descBox {
          display: flex;
          flex-direction: column;
          min-height: 115px;
          justify-content: space-between;
          h6 {
            font-weight: 700;
            color: #020202;
            font-size: 15px;
          }
          a {
            display: flex;
            align-items: center;
            color: #020202;
            span {
              margin-left: 25px;
              display: inline-block;
              border: 2px solid #d5d5d5;
              width: 100%;
              position: relative;
              &::before {
                content: "";
                position: absolute;
                border: 1px solid #4dcac4;
                width: 15px;
                height: 15px;
                left: -10px;
                top: -7px;
                background: #fff;
                border-radius: 50%;
              }
            }
          }
          p {
            display: flex;
            align-items: center;
            span {
              margin-left: 3px;
              display: inline-block;
              font-size: 13px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .task_review {
    padding: 25px 0;
    .content {
      padding: 15px 10px;
      .title{
        margin-bottom: 10px;
      }
      .historyBox {
        .box {
          .descBox {
            padding: 15px 0;
          }
        }
      }
    }
  }
}
