.AddIdentificationPage {
  padding: 50px 0;
  background: url(../../images/signup_bg.png) no-repeat center bottom;
  background-size: cover;

  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .signupForm {
    text-align: center;
    margin: 50px 0 0;
    background: #fff;
    border: 1px solid #fff;
    border-radius: 10px;
    padding: 35px;
    h4 {
      color: #000000;
      font-weight: 700;
      font-size: 20px;
      color: #000;
      margin-bottom: 35px;
    }
    .form-group {
      position: relative;
      margin: 15px 0;
      .form-select,
      .form-control {
        border-radius: 25px;
        border-color: #a8a8a8;
        color: #a8a8a8;
        padding: 10px;
        min-height: 40px;
        -webkit-appearance: none;
        -moz-appearance: none;
        text-indent: 1px;
        text-overflow: "";

        option {
          color: #a8a8a8;
          font-weight: 400;
        }
        &::placeholder {
          color: #a8a8a8;
        }
      }
      span {
        position: absolute;
        top: 40%;
        right: 15px;
        transform: translateY(-40%);
        color: #676767;
        z-index: 9;
        cursor: pointer;
        label {
          cursor: pointer;
        }
      }

      .licenseBox {
        border-radius: 25px;
        border: 1px solid #919191;
        padding-right: 35px;
        min-height: 40px;
        color: #a8a8a8;
        text-align: left;
        padding: 0 0.75rem;
      }
    }
    .addMore {
      margin: 20px 0 0;
      text-align: center;
      button {
        display: inline-block;
        align-items: center;
        justify-content: center;
        color: #484848;
        padding: 8px 0;
        font-weight: 500;
        span {
          margin-left: 5px;
        }
      }
    }
    .nextBtn {
      margin: 20px 0 0;
      text-align: center;
      button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(#a0a0a0, #323232);
        color: #fff;
        border-radius: 25px;
        padding: 8px 30px;
        span {
          margin-left: 5px;
        }
      }
    }
  }
  .backBtn {
    text-align: center;
    button {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      background: #fff;
      color: #393939;
      font-weight: 700;
      border-radius: 25px;
      padding: 8px 30px;
      span {
        margin-right: 5px;
      }
    }
  }
}

@media (max-width: 576px) {
  .AddIdentificationPage {
    padding: 25px 0;
    .signupForm {
      margin: 25px 0 0;
      padding: 15px 10px;
      h4{
        margin-bottom: 15px;
      }
    }
  }
}
