.notification_Alert {
    padding: 100px 0 25px;
    background: #fff;
    min-height: 50%;
    display: flex;
    justify-content: center;
  
    .content {
      h4{
          font-weight: 600;
          color: #000000;
          font-size: 18px;
      }
      .notifyBox{
          border: 1px solid #d6edf4;
          border-radius: 10px;
          margin: 20px 0;
          padding: 15px; 
          h5{
              font-size: 15px;
              color: #000;
              font-weight: 600;
              margin-bottom: 10px;
          }
          p{
              font-size: 13px;
              font-weight: 500;
              color: #474747;
          }
          .time{
              text-align: right;
              span{
                  display: inline-block;
                  font-size: 14px;
                  color: #a9a9a9;
                  font-weight: 500;
              }
          }
          &.confirmJob{
              .time{
                display: block;
                font-size: 14px;
                color: #a9a9a9;
                font-weight: 500;
                text-align: right;
              }
              .confirmBtn{
                  text-align: right;
                  a{
                      color: #3d9a9e;
                      display: inline-block;
                      border-radius: 8px;
                      font-weight: 500;
                      font-size: 13px;
                      svg{
                          margin-left: 3px;
                      }
                  }
              }
              h6{
                  font-size: 14px;
                  font-weight: 600;
                  color: #000;
              }
              p{
                font-size: 13px;
                font-weight: 500;
                color: #000;
                display: flex;
                align-items: center;
                margin-top: 5px;
                svg{
                    margin-right: 3px;
                }
              }
          }
      }
    }
  }

  @media (max-width: 576px){
    .notification_Alert{
        .content{
            .notifyBox{
                padding: 10px;
                h5{
                    margin: 0;
                }
                &.confirmJob{
                    .confirmBtn{
                        text-align: left;
                        margin: 10px 0;
                    }
                }
            }
        }
    }
  }
  
  