.my_Account {
  background: #f5f5f5;
  // padding: 50px 0;
  padding: 100px 0 25px;
  min-height: 100%;
  .myBox {
    background: #fff;
    padding: 25px 35px;
    border-radius: 15px;

    .vertical_line_box{
      position: relative;

      &::before {
        content: "";
        position: absolute;
        width: 1px;
        height: 90%;
        background: #b7b7b7;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }

    h4 {
      font-size: 22px;
      font-weight: 700;
      color: #000000;
    }
    h5 {
      font-size: 18px;
      font-weight: 600;
      color: #000000;
      margin: 15px 0px 7px;
    }

    p{
      margin-top: 3px;
      font-size: 15px;
      color: #000000;
      font-weight: 600;
    }

    .form-group {
      position: relative;
      margin: 8px 0;

      
      .form-control {
        border-radius: 25px;
        border-color: #919191;
        min-height: 40px;
        &:focus {
          border-color: #919191;
        }
      }
      span {
        position: absolute;
        top: 40%;
        right: 15px;
        transform: translateY(-40%);
        color: #676767;
      }
      select {
        color: #000000;
        font-size: 15px;
        font-weight: 600;
        option {
          color: #000000;
          font-size: 15px;
          font-weight: 600;
        }
      }
    }
    .leftBox {
      padding-right: 35px;
      position: relative;
    }
    .rightBox {
      padding-left: 35px;
      p {
        font-size: 15px;
        color: #000000;
        font-weight: 600;
      }
    }
    .submitDetails {
      text-align: center;
      margin-top: 20px;
      a {
        background: #000;
        color: #fff;
        padding: 8px;
        border-radius: 10px;
        font-weight: 400;
        font-size: 13px;
        margin-right: 10px;
        max-width: 175px;
        width: 100%;
        display: inline-block;
        text-align: center;
        cursor: pointer;
      }
    }
  }
  .editDetail{
    text-align: right;

    .addBtn{
      background: #000;
      color: #fff;
      border-radius: 10px;
      font-weight: 400;
      font-size: 13px;
      margin-right: 3px;
      max-width: 150px;
      display: inline-block;
      text-align: center;
      padding-right: 20px;

      span{
        margin-right: 8px;
      }
    }
  }
}

@media (max-width: 1199px) {
  .my_Account {
    .myBox {
      h5 {
        font-size: 16px;
        margin: 5px 0;
      }
    }
  }
}

@media (max-width: 576px) {
  .my_Account {
    .myBox {
      padding: 25px 15px;

      .vertical_line_box{
        &::before {
         display: none;
        }
      }


      h5,
      h4 {
        text-align: center;
      }
      .leftBox {
        padding-right: 0;
      }
      .rightBox {
        padding-left: 0;
        margin-top: 10px;
        p {
          margin-bottom: 15px;
        }
      }
      .submitDetails {
        a {
          margin-top: 20px;
        }
      }
    }
  }
}
.invalid-feedback {
  text-align: left !important;
  // margin: 5px 0 0 10px !important;
  margin: 0!important;
}

