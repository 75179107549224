.MyProfie {
  padding: 100px 0 25px;
  min-height: 100%;

  .profile {
    border-radius: 15px;
    background-color: #fff;
    padding: 20px;
    margin-bottom: 30px;
    border: 1px solid #e1f0f4;

    h4 {
      font-weight: 500;
      font-size: 20px;
      margin-bottom: 20px;
    }
    .editProBtn {
      text-align: right;
      margin-bottom: 20px;
      button {
        display: inline-block;
        color: #4c4c4c;
        font-weight: 600;
        font-size: 13px;
        svg {
          margin-right: 3px;
          margin-top: -2px;
        }
      }
    }
    .flexBox {
      display: flex;

      .left {
        width: 55%;

        .box {
          display: flex;
          // border-right: 1px solid #b7b7b7;

          .imgBox {
            width: 55px;
            min-width: 55px;
            height: 55px;
            border-radius: 50%;
            overflow: hidden;
            box-shadow: 0px 0px 1px 3px rgba(233, 233, 233, 0.7);

            img {
              width: 55px;
              height: 55px;
              border-radius: 50%;
              object-fit: cover;
            }
          }
          .contentBox {
            width: 100%;
            padding-left: 25px;
            h6 {
              font-size: 16px;
              display: flex;
              align-items: center;
              margin-bottom: 10px;

              svg {
                font-size: 18px;
                margin-right: 10px;
              }
            }

            ul {
              display: flex;
              align-items: center;
              flex-wrap: wrap;

              li {
                font-size: 13px;
                display: flex;
                align-items: center;
                color: #666666;
                margin-right: 35px;

                svg {
                  font-size: 16px;
                  margin-right: 5px;
                }
              }
            }
          }
        }
        .btnBox {
          margin-top: 25px;

          .docBtn,
          .accBtn {
            background: #000;
            color: #fff;
            padding: 8px;
            border-radius: 10px;
            font-weight: 400;
            font-size: 13px;
            margin-right: 3px;
            max-width: 150px;
            width: 100%;
            display: inline-block;
            text-align: center;
          }
        }
      }
      .right {
        width: 45%;

        .flexInner {
          display: flex;
          padding: 0 30px;
          margin-bottom: 25px;

          .left {
            p {
              font-size: 15px;
              margin: 0;
              font-weight: 600;
            }
            span {
              font-size: 15px;
              font-weight: 500;
              display: flex;
              align-items: center;

              &.clockIcon {
                svg {
                  margin-right: 7px;
                }
              }

              &.coins {
                svg {
                  margin-right: 7px;
                  color: #ffd40c;
                }
              }
            }
          }

          .right {
            p {
              font-size: 15px;
              margin: 0;
              font-weight: 600;

              svg {
                font-size: 15px;
              }
            }
            a {
              display: block;
              color: #020202;
              font-size: 15px;
              font-weight: 500;

              svg {
                margin-right: 7px;
                font-size: 14px;
              }
            }
            span {
              font-size: 15px;
              font-weight: 500;

              &.count {
                font-size: 16px;
              }

              &.starIcon {
                svg {
                  color: #ffd40c;
                  font-size: 18px;
                }
              }
              &.coins {
                svg {
                  color: #ffd40c;
                }
              }
            }
          }
        }
      }
    }
  }

  .allJob,
  .myEarning {
    border-radius: 15px;
    border: 1px solid #d6edf4;
    background-color: #fff;
    padding: 30px;
    height: 100%;

    .topBox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 15px;

      h4 {
        font-weight: 600;
        font-size: 18px;

        span {
          font-size: 18px;
        }
      }

      a {
        font-size: 13px;
        font-weight: 600;
        color: #484848;
        display: flex;
        align-items: center;
        border: none;
        outline: none;

        svg {
          margin-left: 7px;
          font-size: 12px;
        }
      }
    }

    .box {
      background: #def6f8;
      padding: 15px 20px;
      border-radius: 10px;
      position: relative;
      overflow: hidden;
      margin-bottom: 10px;
      color: #000;
      .job_title_box{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
        h5{
          margin-bottom: 0;
        }
        span{
          line-height: 0;
        }
      }

      // &::after {
      //   content: "";
      //   width: 65px;
      //   height: 65px;
      //   border-radius: 50%;
      //   position: absolute;
      //   background-image: url("../../images/topLeftEllipse.png");
      //   left: -15px;
      //   top: -20px;
      //   background-position: center;
      //   background-size: cover;
      // }
      // &::before {
      //   content: "";
      //   width: 65px;
      //   height: 65px;
      //   border-radius: 50%;
      //   position: absolute;
      //   background-image: url("../../images/rightBottomEllipse.png");
      //   right: -15px;
      //   bottom: -20px;
      //   background-position: center;
      //   background-size: cover;
      // }

      h5 {
        font-weight: 600;
        margin-bottom: 8px;
        font-size: 16px;
        position: relative;
        z-index: 1;
        display: flex;
        justify-content: space-between;
        span{
          color: #5eb3b5;
          font-size: 16px;
        }
      }
      p {
        font-size: 14px;
        margin: 0;
        position: relative;
        z-index: 1;
        color: #97baba;

        span {
          margin-right: 15px;
        }

        svg {
          font-size: 14px;
        }
      }
    }

    .nav-tabs {
      border: 0;
      justify-content: center;
      margin: 30px 0;

      .nav-item {
        width: 33.33%;

        .nav-link {
          border: 1px solid #505050;
          border-radius: 8px;
          font-weight: 600;
          color: #000000;
          font-size: 14px;
          width: 100%;
          max-width: 125px;
          padding: 4px 10px;

          &.active {
            background: #000;
            color: #fff;
          }
        }
      }
    }
  }
}


@media (max-width: 1199px) {
  .MyProfie {
    .profile {
      padding: 15px 10px;
      .flexBox {
        .left {
          .box {
            .contentBox {
              padding-left: 20px;
              p {
                font-size: 18px;
              }
              ul {
                li {
                  font-size: 15px;
                  margin-right: 20px;
                }
              }
            }
            .imgBox {
              width: 65px;
              height: 65px;
              img {
                width: 65px;
                height: 65px;
              }
            }
          }
          .btnBox {
            .docBtn,.accBtn{
              margin-right: 3px;
              max-width: 185px;
            }
          }
        }
        .right {
          .flexInner {
            .left {
              p {
                font-size: 16px;
              }
              span {
                font-size: 13px;
              }
            }
            .right {
              p,a{
                font-size: 16px;
              }
              span {
                font-size: 13px;
                &.starIcon {
                  svg {
                    font-size: 13px;
                  }
                }
              }
            }
          }
        }
      }
    }
    .allJob,
    .myEarning {
      padding: 10px;
      .topBox {
        h4 {
          font-size: 18px;
        }
      }
      .nav-tabs{
        .nav-item{
          margin-bottom: 10px;
          max-width: 275px;
          .nav-link{
            margin: auto;
          }
        }
      }
      .box{
        h5{
          font-size: 18px;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .MyProfie {
    .profile {
      margin-bottom: 0px;
      h4{
        margin-bottom: 15px;
      }
      .flexBox {
        .left {
          .box {
            .contentBox{
              padding-left: 0;
            }
            flex-direction: column;
            .imgBox {
              margin: 0 auto 15px;
            }
          }
          .btnBox{
            margin-top: 15px;
            .docBtn,.accBtn{
              max-width: 148px;
            }
          }
        }
        .right {
          .flexInner {
            padding: 10px;
            margin-bottom: 10px;
            .left{
              margin-right: 5px;
            }
            .left,
            .right {
              p {
                font-size: 15px;
              }
            }
          }
        }
      }
    }
    .allJob,.myEarning{
      height: auto;
      margin-top: 15px;
    }
    .allJob .nav-tabs,
    .myEarning .nav-tabs {
      margin: 15px 0;
      .nav-item {
        margin-bottom: 0;
        .nav-link{
          max-width: 220px;
        }
      }
    }
  }
}
@media (max-width: 576px){
  .MyProfie{
    .profile {
      h4{
        font-size: 18px;
      }
      .flexBox {
        flex-direction: column;
        .left{
          width: 100%;
          .box{
            border-right: none;
            border-bottom: 1px solid #b7b7b7;
            padding-bottom: 15px;
          }
          .btnBox{
            text-align: center;
            margin-top: 15px;
            .docBtn,.accBtn{
              max-width: none;
              padding: 7px 10px;
            }
            .docBtn{
              margin-bottom: 10px;
            }
          }
        }
        .right{
          width: 100%;
          margin-top: 15px;
          .flexInner{
            .right{
              margin-top: 0;
            }
          }
        }
      }
    }
    .allJob  ,.myEarning  {
      height: auto;
      margin-top: 15px;
      .box{
        padding: 10px;
          h5{
            font-size: 16px;
          }
      }
      .topBox{
        a{
          font-size: 16px;
        }
      }
      .nav-tabs{
        .nav-item{
          width: 100%;
          max-width: none;
          .nav-link{
            max-width: none;
          }
          &:nth-child(2){
            margin: 10px 0;
          }
        }
      }
    }
    }
}
  
