.settingBox {
  padding: 100px 0 25px;
  background: #fff;
  min-height: 100%;

  a{
    font-size: 13px;
  }
  .alertOuter{
    border: 1px solid #d6edf4;
    text-align: center;
    border-radius: 10px;
    padding: 25px 10px;
    h6{
      font-size: 18px;
      color: #000;
      font-weight: 600;
    }
    .social_login{
      button{
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        background: #4170cf;
        max-width: 250px;
        width: 100%;
        margin: 15px auto 0;
        border-radius: 10px;
        border: 1px solid transparent;
        svg {
          margin-right: 5px;
        }
        &.googleBtn{
          background: #fff;
          color: #000;
          border: 1px solid #000;
          svg{
            fill: #fe3d40;
          }
        }
      }
    }

    .submitBtn {
      text-align: center;
      a {
        display: inline-block;
        max-width: 200px;
        width: 100%;
        padding: 10px 15px;
        color: #fff;
        background: #000;
        border-radius: 10px;
        margin-top: 25px;
      }
    }

    .alertInner {
      border-radius: 10px;
      .alertBox {
        margin: 15px 0;
        text-align: left;
        p{
          font-size: 14px;
          font-weight: 500;
        }
        h6 {
          font-size: 14px;
          font-weight: 600;
          color: #000000;
          text-align: left;
        }
        .form-check{
          .form-check-input{
            margin-top: 0;
            width: 1.5rem;
            height: 1.5rem;
            border: 1px solid #9fcddb;
            &:focus{
              box-shadow: none;
            }
            &:checked{
              background-color: #9fcddb;
            }
          }
        }
      }
    }
  }

  .notiFi {
    display: flex;
    justify-content: space-between;
    border: 1px solid #d6edf4;
    border-radius: 10px;
    padding: 1.5rem 1rem;

    h6{
      font-weight: 600;
      color: #000;
      font-size: 16px;
    }
    .toggle-button-cover {
      position: relative;
    }
    .button-cover {
      background-color: #fff;
      box-shadow: 0 10px 20px -8px #c5d6d6;
      border-radius: 4px;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
    .knobs {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 2;
    }
    .layer {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      background-color: #ebf7fc;
      transition: 0.3s ease all;
      z-index: 1;
    }
    .button {
      position: relative;
      top: 50%;
      width: 77px;
      height: 28px;
      margin: -13px auto 0px -80px;
      overflow: hidden;
    }
    .button.r {
      border-radius: 30px;
      border: 1px solid #d6edf4;
      .layer {
        border-radius: 30px;
      }
    }
    .checkbox {
      position: relative;
      width: 100%;
      height: 100%;
      padding: 0;
      margin: 0;
      opacity: 0;
      cursor: pointer;
      z-index: 3;
    }
    #button-1 {
      .knobs {
        &:before {
          content: "OFF";
          position: absolute;
          top: 0px;
          left: 0px;
          width: 44px;
          height: 26px;
          color: #fff;
          font-size: 10px;
          font-weight: bold;
          text-align: center;
          line-height: 1;
          line-height: 26px;
          background: #a8deef;
          border-radius: 30px;
          transition: 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
          transition: 0.3s ease all;
        }
        transition: 0.3s ease all;
      }
      .checkbox {
        &:checked {
          + {
            .knobs {
              &:before {
                content: "ON";
                left: 34px;
                background: #53acb4;
              }
            }
          }
          ~ {
            .layer {
              background-color: #e7e7e7;
            }
          }
        }
      }
      .layer {
        transition: 0.3s ease all;
      }
    }
  }

  .form-group {
    position: relative;
    margin: 15px 0 0;
    .form-control {
      border-radius: 10px;
      border-color: #d6edf4;
      background: #f7f8fc;
      min-height: 40px;
      font-size: 14px;
      color: #000;
      font-weight: 500;
      &:focus {
        border-color: #919191;
      }
      &::placeholder {
        font-weight: 600;
      }
    }
    span {
      position: absolute;
      top: 40%;
      right: 15px;
      transform: translateY(-40%);
      color: #676767;
    }
  }
  
}

@media (max-width: 576px){
  .settingBox {
    .notiFi{
      margin-top: 15px;
    }
    .alertOuter {
      .alertInner {
        .alertBox {
          p{
            font-size: 13px;
          }
          h6{
            font-size: 13px;
          }
        }
      }
    }
  }

  .notiFi {
    display: flex;
    justify-content: space-between;
    border: 1px solid #d6edf4;
    border-radius: 10px;
    padding: 1.5rem 1rem;

    h6{
      font-weight: 600;
      color: #000;
      font-size: 16px;
    }
    .toggle-button-cover {
      position: relative;
    }
    .button-cover {
      background-color: #fff;
      box-shadow: 0 10px 20px -8px #c5d6d6;
      border-radius: 4px;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
    .knobs {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 2;
    }
    .layer {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      background-color: #ebf7fc;
      transition: 0.3s ease all;
      z-index: 1;
    }
    .button {
      position: relative;
      top: 50%;
      width: 77px;
      height: 26px;
      margin: -13px auto 0px -80px;
      overflow: hidden;
    }
    .button.r {
      border-radius: 30px;
      border: 1px solid #d6edf4;
      .layer {
        border-radius: 30px;
      }
    }
    .checkbox {
      position: relative;
      width: 100%;
      height: 100%;
      padding: 0;
      margin: 0;
      opacity: 0;
      cursor: pointer;
      z-index: 3;
    }
    #button-1 {
      .knobs {
        &:before {
          content: "OFF";
          position: absolute;
          top: 0px;
          left: 0px;
          width: 44px;
          height: 26px;
          color: #fff;
          font-size: 10px;
          font-weight: bold;
          text-align: center;
          line-height: 1;
          line-height: 26px;
          background: #a8deef;
          border-radius: 30px;
          transition: 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
          transition: 0.3s ease all;
        }
        transition: 0.3s ease all;
      }
      .checkbox {
        &:checked {
          + {
            .knobs {
              &:before {
                content: "ON";
                left: 34px;
                background: #53acb4;
              }
            }
          }
          ~ {
            .layer {
              background-color: #e7e7e7;
            }
          }
        }
      }
      .layer {
        transition: 0.3s ease all;
      }
    }
  }

  // .form-group {
  //   position: relative;
  //   margin: 15px 0;
  //   .form-control {
  //     border-radius: 10px;
  //     border-color: #d6edf4;
  //     background: #f7f8fc;
  //     min-height: 40px;
  //     font-size: 14px;
  //     color: #000;
  //     font-weight: 500;
  //     &:focus {
  //       border-color: #919191;
  //     }
  //     &::placeholder {
  //       font-weight: 600;
  //     }
  //   }
  //   span {
  //     position: absolute;
  //     top: 40%;
  //     right: 15px;
  //     transform: translateY(-40%);
  //     color: #676767;
  //   }
  // }
  
}

.fbBtn {
  text-align: center;
  button {
    border-radius: 10px;
    color: #fff;
    display: inline-block;
    max-width: 235px;
    margin-bottom: 15px;
    width: 100%;
    background: #0178f2;
    font-size: 15px;
    padding: 9px 15px;
    position: relative;
    box-shadow: 0px 4px 7px 0px rgba(1, 120, 242, 0.36);
    border: 1px solid transparent;
    span {
      position: absolute;
      left: 10px;
    }
  }
}
.googleBtn {
  text-align: center;
  button {
    overflow: hidden;
    border-radius: 10px !important;
    max-width: 235px;
    width: 100%;
    font-size: 15px !important;
    position: relative;
    box-shadow: 0px 4px 7px 0px rgba(213, 220, 222, 0.36) !important;
    border: 1px solid #cacaca !important;
    // span {
    //   position: absolute;
    //   left: 48px;
    //   font-weight: 500 !important;
    // }
  }
  div{
    margin-right: 0 !important;
    padding: 9px !important;
  }
}
