.topHeader {
    background: #fff;
    padding: 7px 0;

    .box {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        a {
            display: inline-block;
            color: #000000;
            padding: 3px 5px;
        }

        .loginBtn {
            margin-left: 20px;

            a {
                background: #4bb1b4;
                color: #fff;
                border: 1px solid transparent;
                border-radius: 25px;
                padding: 5px 35px;
                font-size: 15px;
            }
        }
    }
}
header{
    .navbar {
        /*background-color: transparent;*/
        .navbar-brand {
            img {
                width: 150px;
            }
        }
    
        .navbar-nav {
            .nav-item {
                .nav-link {
                    color: #fff;
                    font-size: 15px;
                    padding: 5px 10px;
                    &:hover{
                        color: #4bb1b4;
                    }
                }
            }
        }
        &.scroll{
            background: rgba(0,0,0,0.9);
            transition: .2s ease-in-out;
        }
    }
}
.banner {
    background: url('../../images/welcomePage/banner.png') no-repeat center bottom;
    background-size: cover;
    padding: 35px 0;

    .outerBox {
        position: relative;
        .item {
            .mainBox {
                padding: 150px 0;
                text-align: center;
                color: #fff;
                max-width: 500px;
                margin: auto;

                h1 {
                    font-size: 38px;
                    font-weight: 400;
                }

                a {
                    display: inline-block;
                    background: #4bb1b4;
                    color: #fff;
                    border: 1px solid transparent;
                    border-radius: 25px;
                    margin-top: 25px;
                    padding: 7px 10px;
                    font-size: 15px;
                    max-width: 180px;
                    width: 100%;
                    text-align: center;
                }
            }
        }

        .owl-carousel {
            .owl-dots{
                display: none;
            }
            .owl-nav {
                display: block;

                button {
                    color: #fff;
                    font-size: 50px;
                    position: absolute;
                    transform: translateY(-50%);
                    top: 50%;

                    &.owl-prev {
                        left: 0;
                        &:hover{
                            background: none;
                        }
                    }

                    &.owl-next {
                        right: 0;
                        &:hover{
                            background: none;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1399px){
    .banner {
        .outerBox {
            .item {
                .mainBox {
                    h1 {
                        font-size: 32px;
                    }
                }
            }
        }
    }
}

@media (max-width: 991px){
    .welcomeHeader{
        .navbar {
            background-color: rgba(52, 52, 52,1);
            .navbar-nav{
                margin-top: 10px;
            }
        }
        .navbar-light {
            .navbar-toggler{
                border: none;
                &:focus{
                    box-shadow: none;
                }
                svg{
                    fill: #fff;
                }
            }
            .navbar-toggler-icon{
                display: none;
            }
        }
    }
}

@media (max-width: 576px){
    .topHeader{
        background: #f9f9f9;
        padding: 15px 0;
        .box{
            justify-content: center;
            .loginBtn{
                a{
                    padding: 3px 15px;
                    font-size: 14px;
                }
            }
        }
    }
    .banner {
        .outerBox {
            .item{
                .mainBox{
                    padding: 65px 0 0;
                    h1{
                        font-size: 26px;
                    }
                    a{
                        font-size: 14px;
                        max-width: 135px;
                    }
                }
            }
            .owl-carousel {
                .owl-nav{
                    display: none;
                }
                .owl-dots{
                    display: flex;
                    justify-content: center;
                    margin-top: 15px;
                    .owl-dot{
                        span{
                            display: inline-block;
                            width: 12px;
                            height: 12px;
                            border-radius: 50%;
                            background: transparent;
                            border: 1px solid #fff;
                            margin: 0 3px;
                        }
                        &.active{
                            span{
                                background: #fff;
                                border: 1px solid #fff;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 279px) and (max-width: 280px){
    .topHeader{
        .box {
            .loginBtn{
                margin-left: 15px;
            }
        }
    }
}