footer{
    padding: 35px 0;
    text-align: center;
    background: #1d1c1d;
    color: #fff;
    p{
        font-weight: 400;
    }
}

@media (max-width: 576px){
    footer{
        padding: 20px 0;
    }
}