.my_Rating {
  background: #f5f5f5;
  padding:  100px 0 25px;
  min-height: 100%;
  .rateBox {
    background: #fff;
    padding: 25px 35px;
    border-radius: 15px;
    position: relative;
    h5 {
      font-size: 18px;
      font-weight: 600;
      color: #000000;
      margin-bottom: 25px;
    }
    .flexBox {
      border: 1px solid #d7d7d7;
      padding: 10px 15px 30px;
      border-radius: 10px;
      margin: 10px 0;
      h6 {
        color: #020202;
        font-weight: 700;
        font-size: 17px;
        margin-bottom: 5px;
      }
      .mapText {
        color: #808080;
        font-weight: 500;
        font-size: 15px;
        display: flex;
        align-items: center;
        svg {
          fill: #4d4d4d;
        }
      }
      .starRate {
        color: #020202;
        font-weight: 700;
        font-size: 15px;
        margin: 15px 0;
        display: flex;
        align-items: center;
        svg {
          fill: #ffd40c;
        }
      }
      .date {
        display: flex;
        align-items: center;
        font-size: 15px;
        color: #020202;
      }
    }
  }
}

@media (max-width: 576px){
  .my_Rating{
    padding: 25px 0;
    .rateBox{
      padding: 25px 10px;
      h5{
        margin-bottom: 10px;
      }
    }
  }
}
