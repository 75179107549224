.userProfile {
  padding: 85px 0 25px;
  background: #fff;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .userBox {
    h4 {
      color: #000000;
      font-weight: 600;
      font-size: 20px;
      color: #000;
      text-align: center;
    }
    h5 {
      color: #000000;
      font-weight: 600;
      font-size: 17px;
      color: #000;
      text-align: center;
      margin-bottom: 15px;
    }
    p {
      margin: 10px 0 25px;
      font-weight: 600;
      font-size: 18px;
    }
    .mapBox {
      background: #fff;
      border-radius: 10px;
      padding: 20px;
      height: 100%;

      .profileBox {
        padding: 15px 0 30px;
        display: flex;
        border-bottom: 1px solid #d6edf4;
        margin: 15px 0px;
        align-items: center;
        .imgBox {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          border: 2px solid #8c8c8c;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #7d7d7d;
          span{
            line-height: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            
            img{
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
          }
        }
        .content {
          margin-left: 15px;
          h4 {
            font-size: 14px;
            font-weight: 600;
            color: #000000;
            text-align: left;
          }
          p {
            font-size: 13px;
            margin: 0;
            margin-top: 3px;
            color: #4c4c4c;
            font-weight: 600;
            display: flex;
            align-items: center;
            svg {
              margin-right: 5px;
              font-size: 14px;
            }
          }
        }
      }

      .mapNav {
        border-radius: 5px;
        border: 1px solid #333333;
        overflow: hidden;
      }

      .contentBox {
        padding: 20px 0;

        .form-group {
          position: relative;
          margin: 0 0 15px;
          .form-control,
          .location-search-input {
            border-radius: 25px;
            border-color: #919191;
            padding-right: 35px;
            min-height: 40px;
            &:focus {
              border-color: #919191;
            }
          }
          span {
            position: absolute;
            top: 40%;
            right: 15px;
            transform: translateY(-40%);
            color: #676767;
          }
        }
      }
    }
    .nextBtn {
      margin: 30px 0 0;
      text-align: center;
      a, button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: #fff !important;
        background: #000;
        border-radius: 10px;
        padding: 10px 60px;
        cursor: pointer;
        font-size: 15px;
        span {
          margin-left: 5px;
        }
      }
    }
    .otpForm {
      text-align: center;
      background: #fff;
      border: 1px solid #fff;
      border-radius: 10px;
      padding: 0px 15px;
      // height: 100%;
      h4 {
        color: #000000;
        font-weight: 700;
        font-size: 20px;
        color: #000;
      }
      p {
        margin: 0px 0 20px;
        font-weight: 600;
        font-size: 16px;
      }
      .css-1aznpnh-MuiSlider-root {
        color: #01b399;
        height: 6px;
        .css-14pt78w-MuiSlider-rail {
          background-color: #d7d7d7;
        }
        .css-1tfve6q-MuiSlider-mark {
          display: none;
        }
        .css-6cwnna-MuiSlider-mark {
          display: none;
        }
        .css-eg0mwd-MuiSlider-thumb {
          background-color: #fff;
          border: 2px solid #064f70;
        }
        .css-1eoe787-MuiSlider-markLabel,
        .css-yafthl-MuiSlider-markLabel {
          color: #040404;
          font-weight: 500;
          font-size: 13px;
        }
      }
      .transport {
        p {
          margin: 25px 0 15px;
        }
      }
      .form-check {
        display: flex;
        padding-left: 0;
        align-items: center;
        .form-check-input {
          margin-top: 0;
          border-radius: 2px;
          border: 2px solid #000000;
          &:checked {
            background-color: #000;
          }
        }
        .form-check-label {
          position: relative;
          font-weight: 500;
          color: #000000;
          font-size: 13px;
        }
        .form-check-input {
          margin-left: 0;
          margin-right: 3px;
        }
      }
    }
    .workProfileForm {
      background: #fff;
      border: 1px solid #fff;
      border-radius: 10px;
      padding: 25px 55px;
      margin-top: 20px;
      .form-check {
        display: flex;
        align-items: center;
        padding-left: 0;
        margin: 12px 0;
        .form-check-input {
          border-radius: 2px;
          border: 2px solid #000000;
        }
        .form-check-label {
          position: relative;
          font-weight: 600;
          color: #000000;
        }
        a {
          position: absolute;
          right: -20px;
          display: inline-block;
          color: #000000;
        }
        .form-check-input {
          margin-left: 0;
          margin-right: 5px;
          margin-top: 0;
          &:checked {
            background-color: #000;
          }
        }
      }
    }
  }
}

@media (max-width: 1199px) {
  .userProfile {
    .userBox {
      .mapBox {
        padding: 0px;
        .profileBox {
          margin: 15px 0px;
        }
      }
      .workProfileForm {
        .form-check {
          .form-check-label {
            font-size: 15px;
          }
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .userProfile {
    .userBox {
      .workProfileForm {
        padding: 10px 0px;
        margin-top: 0px;
      }
      p {
        margin: 10px 0;
      }
      .nextBtn {
        margin: 25px 0 0;
      }
      .mapBox {
        margin-bottom: 0px;
        height: auto;
        .profileBox {
          margin: 10px 0px;
          justify-content: center;
        }
        .contentBox{
          padding: 15px 0 0;
        }
      }
      .otpForm {
        padding: 0px 15px;
        p {
          margin: 10px 0 15px;
        }
      }
      h5{
        margin-bottom: 25px;
      }
    }
  }
}
