.banner-speciality {
    background: url(../../images/how_work/speciality_bg.png) no-repeat center bottom;
    background-size: cover;
    // margin-top: -63px;
    padding: 5rem 0;
    
    &.airport{
        background: url(../../images/Speciality/Airport.png) no-repeat center bottom;
        background-size: cover;
    }
    &.banks{
        background: url(../../images/Speciality/Bank.jpg) no-repeat center bottom;
        background-size: cover;
    }
    &.access_control{
        background: url(../../images/Speciality/Acesscontrl.png) no-repeat center bottom;
        background-size: cover;
    }
    &.carlot{
        background: url(../../images/Speciality/carlots.png) no-repeat center bottom;
        background-size: cover;
    }
    &.concierge{
        background: url(../../images/Speciality/Concierge.png) no-repeat center bottom;
        background-size: cover;
    }
    &.construction{
        background: url(../../images/Speciality/construction.png) no-repeat center bottom;
        background-size: cover;
    }
    &.events{
        background: url(../../images/Speciality/events.png) no-repeat center bottom;
        background-size: cover;
    }
    &.loss_pre{
        background: url(../../images/Speciality/loss.png) no-repeat center bottom;
        background-size: cover;
    }
    &.protests{
        background: url(../../images/Speciality/Protest.png) no-repeat center bottom;
        background-size: cover;
    }
    &.residential{
        background: url(../../images/Speciality/residential.png) no-repeat center bottom;
        background-size: cover;
    }
    &.vip{
        background: url(../../images/Speciality/VIP.png) no-repeat center bottom;
        background-size: cover;
    }

    .workBox {
        color: #fff;
        h1 {
            font-size: 38px;
            font-weight: 600;
        }
        p{
            font-size: 20px;
            font-weight: 300;
        }
        .form-group {
            margin: 10px 0;
            position: relative;
            .form-control {
              border-radius: 25px;
              border-color: #111111;
              color: #000;
              padding: 5px 10px;
              &::placeholder {
                color: #a8a8a8;
              }
              &.textArea {
                border-radius: 12px;
              }
            }
          }
          .reachForm{
              text-align: center;
              background: #fff;
              padding: 20px;
              border-radius: 6px;
              max-width: 350px;
              margin: auto;
              width: 100%;
              h5{
                  color: #000;
                  font-weight: 700;
                  margin-bottom: 20px;
              }
              .submitBtn{
                  a{
                    background: #4bb1b4;
                    color: #fff;
                    border: 1px solid transparent;
                    border-radius: 25px;
                    padding: 5px 35px;
                    font-size: 15px;
                    display: inline-block;
                    margin-top: 10px;
                    cursor: pointer;
                  }
              }
          }
    }
}
.airpoSec {
    .workGuard {
        padding: 50px 0;
        text-align: center;

        .content {
            h3 {
                font-size: 24px;
                font-weight: 700;
            }

            p {
                margin: 20px 0;
                font-weight: 400;
                font-size: 15px;
            }

        }
    }
}
.specialitySec {
    background: #f9f9f9;
    .workGuard {
        padding: 50px 0;

        .imgBox {
            text-align: right;
            padding-right: 50px;

            img {
                width: 600px;
                height: 400px;
                object-fit: cover;
                // border-radius: 50%;
            }
        }

        .content {
            h3 {
                font-size: 24px;
                font-weight: 700;
            }

            p {
                margin: 10px 0;
                font-weight: 400;
                font-size: 15px;
            }

            a {
                display: inline-block;
                background: #4bb1b4;
                color: #fff;
                border: 1px solid transparent;
                border-radius: 25px;
                padding: 7px 10px;
                font-size: 15px;
                max-width: 180px;
                width: 100%;
                text-align: center;
            }
            ul{
                li{
                    p{
                        font-weight: 500;
                        font-size: 16px;
                        svg{
                            fill: #4bb1b4;
							display: none;
                        }
                    }
                }
            }
        }
    }
}
.secProfe{
    padding: 50px 0;
    text-align: center;
    h3 {
        font-size: 24px;
        font-weight: 700;
    }
    ul{
        display: flex;
        justify-content: space-between;
        margin: 25px 100px 0;
        flex-wrap: wrap;
        li{
            img{
                width: 100px;
                height: 100px;
                border-radius: 50%;
                object-fit: cover;
                margin-bottom: 10px;
                border: 3px solid #4bb1b4;
            }
           .content{
            h5{
                font-weight: 700;
                font-size: 16px;
            }
            p{
                font-size: 15px;
                font-weight: 500;
            }
           }
        }
    }
}

@media (max-width: 1024px){
    .banner-speciality {
        .work {
            h1{
                font-size: 32px;
            }
            p{
                font-size: 16px;
            }
        }
    }
}

@media (max-width: 991px){
    .banner-speciality{
        padding: 70px 0 25px;
        .workBox{
            padding: 60px 0 0;
            .reachForm{
                padding: 15px;
            }
        }
    }
    .specialitySec {
        .workGuard {
            .imgBox{
                img{
                    height: 300px;
                }
            }
        }
    }
    .secProfe {
        ul{
            margin: 15px 0 0;
            li{
                margin: 10px 5px;
            }
        }
    }
}
@media (max-width: 576px){
    .banner-speciality {
        padding: 85px 0 25px;
        .workBox{
            text-align: center;
            h1{
                font-size: 28px;
            }
            p{
                font-size: 18px;
            }
            .reachForm{
                margin-top: 20px;
                max-width: 280px;
                padding: 15px 10px;
                .form-control{
                    max-width: 250px;
                    padding: 8px 10px;
                    margin: auto;
                }
            }
        }
        &.banks{
            background-position: right;
        }
    }
    .secProfe {
        ul{
            li{
                margin: 10px 5px;
            }
        }
    }
    .specialitySec {
        .workGuard {
            padding: 25px 0;
            text-align: center;
            .imgBox{
                text-align: center;
                padding: 0;
                padding-bottom: 10px;
                img{
                    height: auto;
                    margin-bottom: 20px;
                }
            }
            .content {
                ul {
                    li{
                        p{
                            margin: 10px 0 0;
                            text-align: left;
                        }
                    }
                }
            }
        }
    }
}