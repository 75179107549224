.guardSec {
    .workGuard {
        padding: 50px 0;

        .imgBox {
            text-align: right;
            padding-right: 50px;

            img {
                width: 600px;
                height: 400px;
                object-fit: cover;
                // border-radius: 50%;
            }
        }

        .content {
            h3 {
                font-size: 24px;
                font-weight: 700;
            }

            p {
                margin: 20px 0;
                font-weight: 400;
            }

            a {
                display: inline-block;
                background: #4bb1b4;
                color: #fff;
                border: 1px solid transparent;
                border-radius: 25px;
                padding: 7px 10px;
                font-size: 15px;
                max-width: 180px;
                width: 100%;
                text-align: center;
            }
        }
    }

    .hireBg {
        background: #f9f9f9;
        padding: 50px 0;

        .imgBox {
            padding-left: 50px;

            img {
                width: 600px;
                height: 400px;
                object-fit: cover;
                // border-radius: 50%;
            }
        }

        .content {
            text-align: right;

            h3 {
                font-size: 24px;
                font-weight: 700;
            }

            p {
                margin: 20px 0;
                font-weight: 400;
            }

            a {
                display: inline-block;
                background: #4bb1b4;
                color: #fff;
                border: 1px solid transparent;
                border-radius: 25px;
                padding: 7px 10px;
                font-size: 15px;
                max-width: 180px;
                width: 100%;
                text-align: center;
            }
        }
    }
}

.needAssistance {
    padding: 50px 0;
    background: #ededed;
    text-align: center;

    .content {
        p {
            color: #000000;
            font-weight: 400;
        }

        a {
            display: inline-block;
            background: #4bb1b4;
            color: #fff;
            border: 1px solid transparent;
            border-radius: 25px;
            padding: 7px 10px;
            font-size: 15px;
            max-width: 180px;
            width: 100%;
            text-align: center;
            margin-top: 30px;
        }
    }
}

.goGuardApp {
    padding: 50px 0 0;

    h2 {
        text-align: center;
        font-weight: 700;
        font-size: 28px;
        margin-bottom: 50px;
    }

    .box {
        .content {
            text-align: center;

            h5 {
                color: #000000;
                font-weight: 700;
                font-size: 20px;
            }

            p {
                font-weight: 400;
                margin: 10px 0;
            }

            .imgBox {
                display: flex;
                justify-content: space-between;
                margin: 25px 0 35px;

                img {
                    width: 125px;
                }
            }

            a {
                color: #4bb1b4;
                display: inline-block;
                font-weight: 400;
                font-size: 15px;
            }
        }

        .mobImg {
            text-align: center;

            img {
                width: 200px;
            }
        }
    }
}

.get_touch {
    padding: 50px 0;
    background-color: #f4f4f4;
    background-image: url(../../images/welcomePage/get_touch.jpg);
    background-position: right;
    background-size: 50%;
    background-repeat: no-repeat;

    h2 {
        color: #173839;
        font-weight: 700;
        font-size: 28px;
    }

    p {
        color: #173839;
        font-weight: 400;
        margin: 20px 0;
    }

    a {
        display: inline-block;
        background: #4bb1b4;
        color: #fff;
        border: 1px solid transparent;
        border-radius: 25px;
        padding: 7px 10px;
        font-size: 15px;
        max-width: 180px;
        width: 100%;
        text-align: center;
    }
}

.subscribe {
    background: #000000;
    padding: 50px 0;

    p {
        color: #fff;
        font-weight: 400;
    }

    .subBtn {
        a {
            display: inline-block;
            background: #4bb1b4;
            color: #fff;
            border: 1px solid transparent;
            border-radius: 25px;
            padding: 7px 10px;
            font-size: 15px;
            max-width: 180px;
            width: 100%;
            text-align: center;
        }
    }
}

.wel_banner {
    .outerBox {
        position: relative;
        .item {
            .mainBox {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                text-align: center;
                text-align: center;
                color: #fff;
                max-width: 500px;
                margin: auto;

                h1 {
                    font-size: 38px;
                    font-weight: 400;
                }

                a {
                    display: inline-block;
                    background: #4bb1b4;
                    color: #fff;
                    border: 1px solid transparent;
                    border-radius: 25px;
                    margin-top: 25px;
                    padding: 7px 10px;
                    font-size: 15px;
                    max-width: 180px;
                    width: 100%;
                    text-align: center;
                }
            }
        }

        .owl-carousel {
            .owl-dots{
                display: none;
            }
            .owl-nav {
                display: block;

                button {
                    color: #fff;
                    font-size: 50px;
                    position: absolute;
                    transform: translateY(-50%);
                    top: 50%;

                    &.owl-prev {
                        left: 25px;
                        &:hover{
                            background: none;
                        }
                    }

                    &.owl-next {
                        right: 25px;
                        &:hover{
                            background: none;
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 1399px) {
    .goGuardApp {
        .box {
            .content {
                .imgBox {
                    margin: 20px 0 30px;
                    margin: 15px 0 25px;
                }
            }
        }
    }

    .get_touch {
        background-size: 50% 310px;
    }

    .banner {
        .outerBox {
            .item {
                .mainBox {
                    h1 {
                        font-size: 32px;
                    }
                }
            }
        }
    }
}

@media (max-width: 1199px){
    .subscribe {
        padding: 40px 0px;
        padding: 35px 0px;
    }

    .get_touch {
        h2 {
            font-size: 26px;
        }
    }

    .goGuardApp {
        h1 {
            font-size: 26px;
        }
    }
}

@media (max-width: 1024px){
    .wel_banner{
        .outerBox{
            .item{
                .mainBox{
                    h1{
                        font-size: 32px;
                    }
                }
            }
        }
    }
}

@media (max-width: 991px){
    .guardSec {
        .workGuard {
            .imgBox{
                padding-right: 0;
                text-align: center;
                img{
                    height: 315px;
                }
            }
        }
        .hireBg{
            .imgBox{
                padding-left: 0;
                text-align: center;
                img{
                    height: 315px;
                }
            }
        }
    }
    .goGuardApp {
        h2{
            font-size: 24px;
        }
        .box {
            .content {
                .imgBox {
                    img {
                        width: 95px;
                    }

                    margin: 10px 0 20px;
                }

            }
        }

        padding: 40px 0px 0px 0px;
    }
    .get_touch {
        background-size: 50% 325px;
        h2 {
            font-size: 24px;
        }
        p {
            margin: 15px 0px;
        }
    }
    .wel_banner{
        padding: 100px 0 0px;
        .outerBox{
            .owl-carousel{
                .owl-nav{
                    button{
                        line-height: 0;
                        transform: translateY(-44%);
                        top: 44%;
                        span{
                            display: inline-block;
                        }
                    }
                }
            }
        }
    }
    .welcomeHeader{
        .navbar {
            background-color: rgba(52, 52, 52,1);
            .navbar-nav{
                margin-top: 10px;
            }
        }
        .navbar-light {
            .navbar-toggler{
                border: none;
                &:focus{
                    box-shadow: none;
                }
                svg{
                    fill: #fff;
                }
            }
            .navbar-toggler-icon{
                display: none;
            }
        }
    }
}

@media (max-width: 576px){

    .wel_banner{
        padding: 100px 0 0;
        .outerBox{
            .item{
                height: 225px;
                .mainBox{
                    max-width: none;
                    width: 100%;
                    h1{
                        font-size: 24px;
                    }
                    a{
                        margin-top: 10px;
                        max-width: 125px;
                        padding: 5px 10px;
                    }
                }
                img{
                    height: 225px;
                    object-fit: cover;
                }
            }
           .owl-carousel{
            .owl-nav{
                display: none;
            }
            .owl-dots{
                display: block;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                bottom: 0;
                .owl-dot{
                    &.active{
                        span{
                            background: #4bb1b4;
                        }
                    }
                }
            }
           }
        }
    }
    .guardSec {
        .workGuard,.hireBg{
            text-align: center;
            padding: 35px 0;
            .content{
                margin-top: 15px;
                text-align: center;
                p{
                    margin: 10px 0;
                }
            }
            .imgBox{
                img{
                    height: auto;
                }
            }
        }
    }
    .goGuardApp {
        padding: 35px 0px 0px;
        h1{
            font-size: 24px;
            margin-bottom: 0;
        }
        .box{
            .content{
                margin-top: 35px;
                .imgBox{
                    img{
                        width: 125px;
                    }
                }
            }
            .mobImg{
                margin-top: 35px;
            }
        }
    }
    .get_touch{
        background-image: none;
        padding: 35px 0;
        text-align: center;
    }
    .subscribe{
        text-align: center;
        .subBtn{
            margin-top: 15px;
        }
    }
    .topHeader{
        background: #f9f9f9;
        padding: 15px 0;
        .box{
            justify-content: center;
        }
    }
    .banner {
        .outerBox {
            .item{
                .mainBox{
                    padding: 65px 0 0;
                    h1{
                        font-size: 26px;
                    }
                    a{
                        font-size: 14px;
                        max-width: 135px;
                    }
                }
            }
            .owl-carousel {
                .owl-nav{
                    display: none;
                }
                .owl-dots{
                    display: flex;
                    justify-content: center;
                    margin-top: 15px;
                    .owl-dot{
                        span{
                            display: inline-block;
                            width: 12px;
                            height: 12px;
                            border-radius: 50%;
                            background: transparent;
                            border: 1px solid #fff;
                            margin: 0 3px;
                        }
                        &.active{
                            span{
                                background: #fff;
                                border: 1px solid #fff;
                            }
                        }
                    }
                }
            }
        }
    }
    .needAssistance{
        padding: 25px 0;
    }
}
