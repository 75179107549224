.homeBanner {
  background: url(../../images/home_bg.png) no-repeat center center;
  background-size: cover;
  padding: 100px 0;
  min-height: calc(100% - 0px);
  display: flex;
  justify-content: center;
  align-items: center;
  .Box {
    margin-top: 80px;
    .content {
      padding: 50px 15px;
      border-radius: 10px;
      text-align: center;
      h3 {
        font-size: 18px;
        color: #1b1b1b;
        font-weight: 700;
      }
      .proceedLeft {
        display: inline-block;
        border-radius: 8px;
        padding: 7px 35px;
        background: linear-gradient(#a0a0a0, #323232);
        color: #fff;
        font-size: 14px;
        margin: 35px auto 0;
        .arrowRight {
          margin-left: 5px;
        }
      }
      &.lightBg {
        background: #fff;
        color: #000;
        border: 1px solid #fff;
      }
      &.darkBg {
        background: transparent;
        color: #fff;
        border: 1px solid #fff;
        h3 {
          color: #fff;
        }
        .proceedLeft {
          background: #fff;
          color: #000000;
        }
      }
    }
  }
}

@media (max-width: 576px){
  .homeBanner{
    padding: 35px 0;
    .Box{
      margin-top: 50px;
      .content{
        padding: 35px 0px;
        &.lightBg{
          margin-bottom: 20px;
        }
        h3{
          font-size: 16px;
        }
        .proceedLeft{
          padding: 6px 25px;
        }
      }
    }
  }
}
