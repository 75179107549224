.resetPasswordPage {
    padding: 50px 0;
    background: url(../../images/home_bg.png) no-repeat center bottom;
    background-size: cover;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    .loginForm {
      text-align: center;
      margin: 50px 0 0;
      background: #fff;
      border: 1px solid #fff;
      border-radius: 10px;
      padding: 35px;
      h4 {
        color: #000000;
        font-weight: 700;
        font-size: 20px;
        color: #000;
        margin-bottom: 35px;
      }
      .form-group {
        position: relative;
        margin: 15px 0;
        .form-control {
          border-radius: 10px;
          border-color: #d6edf4;
          padding-right: 35px;
          min-height: 40px;
          background: #f7f8fc;
          &:focus {
            border-color: #d6edf4;
          }
        }
        span {
          position: absolute;
          top: 40%;
          right: 15px;
          transform: translateY(-40%);
          color: #676767;
        }
      }
      .loginBtn {
        text-align: center;
        margin-top: 20px;
        a {
          display: inline-block;
          margin-top: 15px;
          background: #000;
          color: #fff !important;
          font-size: 14px;
          border-radius: 10px;
          padding: 10px 15px;
          max-width: 235px;
          width: 100%;
          svg{
            margin-left: 2px;
            font-size: 13px;
          }
        }
      }
      .fbBtn {
        text-align: center;
        button {
          color: #fff;
          display: inline-block;
          max-width: 235px;
          width: 100%;
          background: #0178f2;
          font-size: 15px;
          border-radius: 25px;
          padding: 10px 15px;
          position: relative;
          margin: 25px auto 0px;
          box-shadow: 0px 4px 7px 0px rgba(1, 120, 242, 0.36);
          border: 1px solid transparent;
          span {
            position: absolute;
            left: 10px;
          }
        }
      }
      .googleBtn {
        text-align: center;
        button {
          color: #323232;
          display: inline-block;
          max-width: 235px;
          width: 100%;
          background: transparent;
          font-size: 15px;
          border-radius: 25px;
          padding: 10px 15px;
          position: relative;
          margin: 25px auto 15px;
          box-shadow: 0px 4px 7px 0px rgba(213, 220, 222, 0.36);
          border: 1px solid #cacaca;
          span {
            position: absolute;
            left: 15px;
            img {
              width: 18px;
            }
          }
        }
      }
      .notReg {
        p {
          text-align: center;
          color: #6f8b99;
          font-weight: 500;
          font-size: 14px;
          .signupBtn {
            font-size: 16px;
            display: inline-block;
            font-weight: 700;
            color: #000000;
          }
        }
      }
    }
  }
  
  @media (max-width: 991px) {
    .loginPage {
      .loginForm {
        padding: 20px;
        .notReg {
          p {
            font-size: 13px;
          }
        }
      }
    }
  }
  
  @media (max-width: 991px) {
    .loginPage {
      .loginForm {
        padding: 20px 10px;
        margin: 25px 0 0;
        .notReg {
          p {
            font-size: 13px;
          }
        }
      }
    }
  }
  
$color--error: #F2180A;
$color--success: #11AF41;

  .validation {
    // display: flex;
    flex-direction: column;
    justify-content: space-around;
    box-sizing: border-box;
    margin-bottom: 1em;
  }
  
  .validation-item {
    margin-bottom: 0.85em
  }
  
  .validation-icon {
    display: inline-block;
    margin-right: 0.5em;
    width: 1.25em;
  }
  
  .validator {
    display: flex;
  }
  
  .error {
    @extend .validation-icon;
    color: $color--error;
  }
  
  .success {
    @extend .validation-icon;
    color: $color--success;
  }