.supportNeed {
  padding: 50px 0;
  background: #f5f5f5;
  min-height: 100%;
  .sideBar {
    background: #fff;
    border-radius: 6px;
    h5 {
      text-align: center;
      color: #000000;
      font-size: 20px;
      font-weight: 700;
      padding: 15px 0;
    }
    .tabBox {
      .nav {
        flex-direction: column;
        .nav-item {
          .nav-link {
            width: 100%;
            background: #fff;
            color: #000000;
            font-weight: 500;
            font-size: 15px;
            display: flex;
            justify-content: space-between;
            border-top: 1px solid #b6b6b6;
            border-radius: 0;
            span {
              display: inline-block;
              font-size: 16px;
            }
            &.active {
              background: #f8f8f8;
            }
          }
          .ticketBtn {
            border-top: 1px solid #b6b6b6;
            padding: 25px 0;
            text-align: center;
            .nav-link {
              background: linear-gradient(#a0a0a0, #323232);
              max-width: 150px;
              width: 100%;
              padding: 5px 10px;
              margin: auto;
              border-radius: 25px;
              display: inline-block;
              color: #fff;
              font-size: 15px;
            }
          }
        }
      }
    }
  }
  .contentBox {
    background: #fff;
    border-radius: 6px;
    padding: 25px;
    // max-width: 650px;
    .tab-content {
      .termsBox {
        h6 {
          font-size: 16px;
          font-weight: 700;
          color: #020202;
          margin-bottom: 15px;
        }
        .descBox {
          margin: 10px 0;
          border: 1px solid #d7d7d7;
          padding: 10px;
          border-radius: 6px;
          p {
            font-size: 15px;
            color: #acacac;
            font-weight: 500;
            display: flex;
            align-items: center;
            margin: 8px 0;
            svg {
              fill: #4d4d4d;
            }
            span {
              font-weight: 600;
              color: #020202;
              font-size: 15px;
              margin-right: 5px;
            }
          }
          .jobRating {
            display: flex;
            align-items: center;
            svg {
              fill: #ffd40c;
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 991px){
  .supportNeed{
    .sideBar{
      h5{
        font-size: 18px;
      }
    }
    .contentBox{
      .tab-content{
        .termsBox{
          .descBox {
            p{
              align-items: baseline;
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 576px){
  .supportNeed {
    padding: 25px 0;
    .contentBox{
      padding: 25px 10px;
    }
  }
}
