.my_earning{
    background: #f5f5f5;
    padding: 50px 0;
    min-height: 100%;
    .detailsBox{
        background: #fff;
        padding: 35px 25px;
        border-radius: 15px;
        position: relative;
        .rate_title{
            margin-bottom: 20px;
            h4{
                font-size: 20px;
                font-weight: 600;
                color: #000000;
            }
            .earningBtn{
                display: flex;
                justify-content: flex-end;
                a{
                    display: inline-block;
                    border-radius: 25px;
                    border: 1px solid #505050;
                    padding: 4px 15px;
                    background: #ffffff;
                    color: #000000;
                    font-weight: 700;
                    font-size: 14px;
                }
                .allBtn{
                    background: linear-gradient(4deg, rgba(50,50,50,1) 0%, rgba(160,160,160,1) 78%);
                    color: #fff;
                    border-color: #fff;
                }
                .updomeBtn{
                    margin: 0 7px;
                }
            }
        }
        .flexBox{
            border: 1px solid #d7d7d7;
            padding: 10px 15px 30px;
            border-radius: 10px;
            margin: 10px 0;
            h6{
                color: #020202;
                font-weight: 700;
                font-size: 17px;
                margin-bottom: 10px;
            }
            .mapText{
                color: #020202;
                font-weight: 500;
                font-size: 15px;
                display: flex;
                align-items: center;
                img{
                    width: 20px;
                }
            }
            .starDate,.hourRate,.totalAmount{
                color: #020202;
                font-weight: 700;
                font-size: 15px;
                margin: 8px 0;
                display: flex;
                align-items: center;
                svg{
                    fill: #020202;
                }
                span{
                    display: inline-block;
                    font-weight: 500;
                    margin-left: 5px;
                }
            }
        }
    }
}

@media(max-width: 576px){
    .my_earning{
        padding: 25px 0;
        .detailsBox{
            padding: 25px 10px;
            .rate_title{
                h4{
                    text-align: center;
                }
                .earningBtn{
                    justify-content: center;
                    margin-top: 15px;
                    flex-direction: column;
                    a{
                        width: 100%;
                        text-align: center;
                    }
                    .updomeBtn{
                        margin: 0;
                        margin: 15px 0;
                    }
                }
            }
        }
    }
}