.job_detail2 {
  background: #fff;
  padding: 100px 0 25px;
  min-height: 100%;

  .arrow_icon{
    position: relative;
    a{
        position: absolute;
        color: #000;
        left: 0;
        top: 0;
        z-index: 99;
    }
}
  .mainBox {
    background: #fff;
    padding: 0px 25px;
    border-radius: 15px;
    position: relative;
    .title {
      display: flex;
      align-items: center;
      position: relative;
      justify-content: start;
      .heading {
        width: 100%;
        h1 {
          font-size: 20px;
          font-weight: 600;
          text-align: left;
          margin-bottom: 20px;
        }
      }
    }
    .detailBox {
      border-radius: 6px;
      h5 {
        color: #000000;
        font-weight: 600;
        font-size: 16px;
      }
      .calendar_para{
        margin: 15px 0;
        display: flex;
        align-items: center;
        font-size: 14px;
        svg{
          margin-right: 5px;
        }
      }

      .adderess {
        display: flex;
        align-items: center;
        font-size: 15px;
        color: #565656;
        font-weight: 500;
      }
      p {
        font-size: 16px;
        color: #020202;
        font-weight: 500;
        margin: 10px 0;
      }
      .starDate {
        p {
          display: flex;
          align-items: center;
          justify-content: start;
          font-size: 14px;
          margin: 0;
          svg {
            margin-right: 5px;
          }
          span {
            display: inline-block;
            font-weight: 600;
            margin-right: 5px;
          }
        }
        .star {
          svg {
            fill: #ffd40c;
            margin: 0;
          }
        }
      }
      .lBox {
        padding-right: 35px;
        .para {
          margin: 0;
          font-size: 14px;
          color: #565656;
        }
        .desc {
          display: flex;
          .flexBox {
            p {
              font-size: 14px;
              color: #565656;
              span {
                display: inline-block;
                font-weight: 600;
                color: #000;
              }
            }
            &.spaceRight {
              margin-left: 70px;
            }
          }
        }
        .earningBox {
          max-width: 400px;
          padding: 10px 0;
          p {
            margin: 0;
            font-size: 14px;
            color: #565656;
          }
          h6 {
            font-weight: 600;
            font-size: 14px;
          }
          .totEarn {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-top: 1px solid #000;
            border-bottom: 1px solid #000;
            padding: 7px 15px 7px 0;
          }
        }
      }
      .rBox {
        .imgBox {
          border-radius: 6px;
          border: 1px solid #064f70;
          overflow: hidden;
          width: 85%;
          height: 150px;
          margin: 10px 0 5px;
        }
      }
    }
  }
  .shiftBox{
    border: 1px solid #a8deef;
    padding: 20px;
    border-radius: 15px;
    .imgBtn {
      text-align: right;
      a {
        display: inline-block;
        text-align: center;
        padding: 4px 10px;
        border-radius: 5px;
        border: 1px solid #000000;
        color: #000;
        max-width: 85px;
        width: 100%;
        color: #040404;
        font-weight: 500;
        font-size: 12px;
        &.enote_btn {
          margin-right: 5px;
        }
      }
    }
  
    .borderBox {
      border: 1px solid #a8deef;
      border-radius: 8px;
      padding:20px 10px;
      margin-top: 15px;
      .dateBox,
      .startBox {
        margin: 5px 0;
        h6 {
          font-size: 13px;
          font-weight: 600;
        }
        p {
          font-weight: 400;
          font-size: 13px;
          margin: 3px 0;
          color: #000;
        }
      }
      .startBtn {
        text-align: right;
        a {
          // background: #585858;
          background: #000;
          color: #fff;
          display: inline-block;
          padding: 3px 15px;
          border-radius: 5px;
          font-size: 12px;
          border: 1px solid #fff;
          font-weight: 500;
        }
        .leaveBtn{
          background: transparent;
          color: #000;
          border: 1px solid #919191;
          margin-right: 5px;
        }
      }
    }
  }
}


@media (max-width: 1199px){
  .job_detail2 {
    .arrow_icon{
      a{
        left: -15px;
      }
    }
  }
}

@media (max-width: 991px){
  .job_detail2 {
    .mainBox{
      padding: 0px 0 0 15px;
      .detailBox {
        .lBox {
          padding-right: 0;
          .desc {
            .flexBox{
              &.spaceRight{
                margin-left: 15px;
              }
            }
          }
        }
        .borderBox{
          margin: 6px 0;
        }
      }
    }
    .shiftBox{
      padding: 10px;
      .imgBtn{
        a{
          max-width: 124px;
          &.detailBtn{
            margin-right: 5px;
          }
        }
      }
      .borderBox{
        padding: 15px 10px;
      }
    }
  }
}

@media (max-width: 576px){
  .job_detail2 {
    .shiftBox{
      .imgBtn{
        text-align: center;
      }
    }
    .mainBox{
      padding: 0px;
      .title{
        margin-bottom: 0;
        .heading{
          h1{
            text-align: center;
          }
        }
      }
      .detailBox {
        padding: 0px;
        .starDate {
          margin: 10px 0;
          p{
            justify-content: flex-start;
          }
        }
        .lBox{
          .desc{
            margin: 10px 0 0;
            .flexBox{
              &.spaceRight{
                margin-left: 15px;
              }
            }
          }
          .earningBox{
            margin-bottom: 10px;
            max-width: none;
          }
        }
        .rBox{
          .imgBox{
            width: auto;
            margin: 5px 0;
          }
        }
        .imgBtn{
          a{
            margin: 8px 0;
            &.detailBtn{
              margin: 0;
            }
          }
        }
        .calendar_para{
          margin: 10px 0 0;
        }
      }
    }
    .arrow_icon{
      a{
        left: 0;
      }
    }
  }
}

@media (max-width: 375px){
  .job_detail2{
    .mainBox {
      .detailBox {
        .lBox{
          .desc {
            .flexBox{
              p{
                margin: 8px 0;
                font-size: 13px;
              }
            }
          }
        }
      }
    }
  }
}
